import './styles.scss';
import React, { useState, useRef, useEffect } from 'react';
import SharedComponent from '../../shared';

export const BotMessage = React.memo(({ key, item, animated, scrollToBottom, handleAnimated, toggleMessageSelection, setOpenModalExport, closeModalExport, selectedMessages, clearSelectMessage }) => {
    const [showDax, setShowDax] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    const headers = item.message.value.length > 0 ? Object.keys(item.message.value[0]) : [];
    
    const modalRef = useRef();

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setShowModal(false)
          clearSelectMessage()
        //   console.log('teste', selectedMessages)
        }
      };


      useEffect(() => {
        if (showModal) {
          document.addEventListener('mousedown', handleOutsideClick);
        } else {
          document.removeEventListener('mousedown', handleOutsideClick);
        }
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, [showModal]);
    
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    // Ordena os dados
    const sortedRows = [...item.message.value].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
    });

    return (
        <main className='bot-message-ai'>
            <>
                <p>{item.message.description}</p>
                {item.message.value.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                {headers.map(header => (
                                    <th key={header} onClick={() => requestSort(header)}>
                                        {header.replace(/[\[\]]/g, '')}
                                        <span>
                                            {sortConfig.key === header ? (
                                                sortConfig.direction === 'ascending' ? 
                                                    <i className="mdi mdi-arrow-up" style={{ fontSize: '12px', padding: '1px' }} /> : 
                                                    <i className="mdi mdi-arrow-down" style={{ fontSize: '12px', padding: '1px' }} />
                                            ) : (
                                                <i className="mdi mdi-arrow-down" style={{ fontSize: '12px', padding: '1px' }} />
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortedRows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {headers.map((header, cellIndex) => (
                                        <td key={`${rowIndex}-${cellIndex}`}>{row[header]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Nenhum valor encontrado!</p>
                )}
                 {  
                    showDax &&
                    <pre>
                        {item.message.querie}                            
                    </pre>
                }
                <span className='message-tool'>
                    <div className='tool-item' onClick={() => setShowDax(!showDax)}>
                        <i className='mdi mdi-code-tags '/>
                        <p>{showDax ? 'Ocultar DAX' : 'Visualizar DAX'}</p>
                    </div>
                    <div className='tool-item'>
                        <i className='mdi mdi-clock-fast' />
                        <p>{item.message.time && (item.message.time / 1000).toFixed(2)}s</p>
                    </div>
                    {item.message.value.length > 0 && Object.keys(item.message.value[0]).length > 0 &&
                        <div className='export-item'
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                justifyItems: 'end',
                                width: '66%'
                            }}
                            onClick={() => {
                                toggleMessageSelection(item)
                                setShowModal(true)
                            }}>
                            <div className='tool-item'>
                                <i className='mdi mdi-share' />
                                <p>Exportar</p>
                            </div>
                        </div>
                    }

                    {showModal && <SharedComponent  item={selectedMessages}   onClose={() => {
                        setShowModal(false)
                    }}  ref={modalRef}  />}
                </span>
            </>
        </main>
    );
});
