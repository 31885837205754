import '../rlsUsers/style.scss';
import Select from 'react-select';
import { Option } from '../optionModern';
import React, { Component } from 'react';
import RlsItemGrups from '../rlsUsers/rlsItemGroup';
import RlsItemUser from '../rlsUsers/rlsItemUser';

class RlsUsersAcess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usuariosAgrupados: [],
    };
  }

  componentDidMount() {
    this.pegarFuncoes()
  }

  pegarFuncoes = () => {
    const response = this.pegarGrupoUsuario(this.props.valueSelect, this.props.allInfoUsers)
    this.setState({usuariosAgrupados: response})
  }

  pegarGrupoUsuario = (Users, allInfoUsers, idOnly, userGroups) => {
    if(userGroups) {
      const selectedGroup = Users.map((selectedGroups) => {
        if(Array.isArray(this.props.optionSelect)) {
          const matchingUser = this.props.optionSelect.find(
            (groupInfo) => groupInfo.value === selectedGroups 
          )
          if(matchingUser) {
            return {
              label: matchingUser.label,
              value: selectedGroups,
            }
          }
        }
        return null;
      })
      return selectedGroup
    }
    if(idOnly) {
      const selectedUserDetails = Users.map((selectedUser) => {
        if (Array.isArray(allInfoUsers)) {
          const matchingUser = allInfoUsers.find(
            (userInfo) => userInfo.id_usuario === selectedUser
          );
  
          if (matchingUser) {
            return {
              label: matchingUser.nome,
              value: selectedUser,
              gruposusuario: matchingUser.gruposusuario,
            };
          }
        }
        return null;
      });
      return selectedUserDetails
    } else {
      const selectedUserDetails = Users.map((selectedUser) => {
        if (Array.isArray(allInfoUsers)) {
          const matchingUser = allInfoUsers.find(
            (userInfo) => userInfo.id_usuario === selectedUser.value
          );
  
          if (matchingUser) {
            return {
              label: selectedUser.label,
              value: selectedUser.value,
              gruposusuario: matchingUser.gruposusuario,
            };
          }
        }
  
        return null;
      });
      return selectedUserDetails;
    }

  };

  removerUsuarioCheck = (id) => {
    const novosUsuarios = this.props.removeUniqUser(id)
    const response = this.pegarGrupoUsuario(novosUsuarios, this.props.allInfoUsers, true)
    this.setState({usuariosAgrupados: response})
  }

  onChangeFunction = (values) => {
      this.props.changeFunction(values)
      if(values) {
        const updatedUsers = this.pegarGrupoUsuario(
          values,
          this.props.allInfoUsers,
          false, 
          this.props.GroupUsers
        );
        this.setState({ usuariosAgrupados: updatedUsers });
      } else {
        this.setState({ usuariosAgrupados: [] });
      }
  }

  render() {

    // const gruposDeUsuarios = {};

    // if (this.state.usuariosAgrupados) {
    //   this.state.usuariosAgrupados.forEach((usuario) => {
    //     const grupo = usuario.gruposusuario;
    //     if (!gruposDeUsuarios[grupo]) {
    //       gruposDeUsuarios[grupo] = [];
    //     }
    //     gruposDeUsuarios[grupo].push(usuario);
    //   });
    // }

    return (
      <div className="rls-users-container">
        <header className="rls-users-container-header">
          <p>{`${this.props.t('reportsPage.edit.access.values.index')} (${this.props.valueSelect && this.props.valueSelect.length})`}</p>
          <Select
            multi
            placeholder={this.props.t('reportsPage.edit.access.values.placeholder')}
            closeMenuOnSelect={false}
            noOptionsMessage={() => "Nenhum usuário ou grupo encontrado"}
            components={{ Option }}
            options={this.props.optionSelect}
            value={this.props.valueSelect}
            isMulti
            controlShouldRenderValue={false}
            allowSelectAll={true}
            hideSelectedOptions={false}
            onChange={(values) => this.onChangeFunction(values)}
            styles={{
              option: (provided, { isFocused }) => ({
                ...provided,
                backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                color: "#333",
                display: "flex",
                alignItem: "center",
              }),
            }}
          />
        </header>
        <section className="rls-users-container-content">
          {this.props.valueSelect.filter(item => !item.group).length > 0 && <p className='label-info'>{this.props.t('users')}</p>}
          {
            this.props.valueSelect
            .filter(item => !item.group)
            .slice()
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item) => {
              return <RlsItemUser usuario={item} key={this.props.GroupUsers ? item : item.value} removerItem={(id) => this.removerUsuarioCheck(id)} />
            })
          }
          {this.props.valueSelect.filter(item => item.group).length > 0 && <p className='label-info-group'>{this.props.t('groups')}</p>}
          {
            this.props.valueSelect
            .filter(item => item.group)
            .slice()
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item) => {
              return <RlsItemUser usuario={item} key={this.props.GroupUsers ? item : item.value} removerItem={(id) => this.props.removeUniqGroup(id)} />
            })
          }
          {/* {Object.entries(gruposDeUsuarios)
            .sort(([grupoA], [grupoB]) => grupoA.localeCompare(grupoB))
            .map(([grupo, usuarios]) => (
              <RlsItemGrups grupo={grupo} usuarios={usuarios} key={grupo} removerItem={(id) => this.removerUsuarioCheck(id)} />
            ))
          } */}
          {
            this.props.valueSelect.length === 0 && this.props.t('reportsPage.edit.access.values.empty')
          }
        </section>
      </div>
    );
  }
}

export default RlsUsersAcess;
