import { useTranslation } from 'react-i18next';

const usePowerBIData3 = () => {
  const { t } = useTranslation();

  const data = [
    [
      `1-${t('sidebar.powerBI.index')}`,
      "Setting"
    ],
    [
      {
          "id": "2222002",
          "nome": `${t('sidebar.powerBI.sync')}`,
          "rota": "/pages/admin/sync",
          "icon": "mdi mdi-security"
      }
    ]
  ];

  return data;
};

export default usePowerBIData3;