import { CheckOne } from "@icon-park/react";
import React from "react";
import { components } from "react-select";

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{height: 36, display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: 10}}>
          {!props.isSelected ? 
            <div style={{width: 25, height: 25, borderRadius: 25, background: 'rgba(50,50,50,.1)', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
              <p style={{color: '#444', fontWeight: 'bold', fontSize: props.data.group && '70%', textTransform: props.data.group && 'uppercase'}}>{props.data.group ? props.label.substring(0, 2) : props.label[0]}</p>
            </div>
            :
            <CheckOne theme="filled" size="25" fill="#0196FA"/>  
          }
        </div>
        <div style={{height: 36, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
          <label style={{all: "unset", fontSize: 14, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.label}</label>
          <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>{props.area}</label>

          
          {
            props.data.email && 
            <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>{props.data.email}</label>
          }
          {
            props.data.group && 
            <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>Grupo de usuários</label>
          }
           {
            props.data.area && 
            <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>{props.data.area}</label>
          }
        </div>
      </components.Option>
    </div>
  );
};