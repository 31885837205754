import React, { useEffect, useState } from "react";
import "./styles.css";

function ToggleSwitch({checked,onClick}) {

  return (
    <div>
      <label class="switch switch-green">
        <input type="checkbox" class="switch-input" checked={checked} onClick={onClick} />
        <span class="switch-label" data-on="On" data-off="Off"></span>
        <span class="switch-handle"></span>
      </label>
    </div>
  );
}

export default ToggleSwitch;
