import fileDefault from '../../../../assets/images/dragAndDropInput/file-blank-solid-240.png';
import fileCSS from '../../../../assets/images/dragAndDropInput/file-css-solid-240.png';
import filePdf from '../../../../assets/images/dragAndDropInput/file-pdf-solid-240.png';
import filePng from '../../../../assets/images/dragAndDropInput/file-png-solid-240.png';
import filePbix from '../../../../assets/images/dragAndDropInput/power-bi-icon-file.jpg';

export const ImageConfig = {
    default: fileDefault,
    pdf: filePdf,
    png: filePng,
    css: fileCSS,
    pbix: filePbix
}