import './style.css';
import { useContext } from 'react';
import IconsNames from './icons.json';
import * as Icon from '@icon-park/react';
import React, { useEffect, useState } from 'react';
import { AuthContext } from 'contexts/appProvider';

export default function ModalIcons({onExit, onChose, iconeChose}) {

    const {colorDefault} = useContext(AuthContext);
    const [searchWord, setSearchWord] = useState(null);
    const [filteredIcons, setFilteredIcons] = useState([]);

    function pickIconsForSearch() {
        if (!searchWord) {
            setFilteredIcons(IconsNames);
          } else {
            const lowerCaseSearchWord = searchWord.toLowerCase();
            const filtered = IconsNames.filter((item) => {
              const IconComponent = Icon[item];
              return IconComponent && item.toLowerCase().includes(lowerCaseSearchWord);
            });
            setFilteredIcons(filtered);
        }
    }

    useEffect(() => {pickIconsForSearch()}, [searchWord])

    return (
        <main className="modal-icons-container">
            <div className='modal-icons-content'>
                <span className='exit-button' onClick={onExit}>
                    <Icon.CloseSmall theme="multi-color" size="16" fill={[colorDefault ,'#ceceda' ,colorDefault ,colorDefault ]}/>
                </span>
                <header className='modal-icons-header'>
                    <p className='modal-icons-header-title'>Escolha um ícone</p>
                    <div className='fake-input-search'>
                        <label htmlFor="form">
                            <Icon.Search
                                theme="multi-color"
                                size="16"
                                fill={[colorDefault ,'#ceceda' ,colorDefault ,colorDefault]} 
                            />
                        </label>
                        <input 
                            className='input-for-search-modal' 
                            placeholder='Digite nome do icone' 
                            onChange={e => setSearchWord(e.target.value)}
                            id='form'
                         />
                    </div>
                </header>
                <div className='content-modal-icons'>
                    {filteredIcons.map((item, key) => { 
                        const IconComponent = Icon[item];
                        if (IconComponent) {
                        return (
                            <div 
                                className={iconeChose === item.toString() ? 'item-modal-icons-active' : 'item-modal-icons'}
                                onClick={() => [onChose(item), onExit()]} key={key}
                            >
                                {React.createElement(IconComponent, { fill: colorDefault })}
                            </div>
                        );
                        }
                        return null;
                    })}
                    {/* Esse pseudo codigo abaixo é para não quebrar a quantidade de itens na busca (espaçamentos dos itens), no display grid */}
                    {Array.from({ length: 12 }, (_, index) => (
                        <div key={index} className='psed-item' />
                    ))}
                </div>
            </div>
        </main>
    )
}