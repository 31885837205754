import axios from 'axios';
import './styles.scss'
import { ReportsContext } from 'contexts/reportsContext';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import api from 'services/api/api';

export default function ModalSync() {

    const { requestReportsInfo, setSyncPowerBI } = useContext(ReportsContext)

    const [reportsInWorkspaces, setReportsInWorkspaces] = useState([])
    const [selectedWorkspaces, setSelectedWorkspaces] = useState([])

    const [isLoading, setIsLoading] = useState(true)

    const getAll = useCallback(async () => {
        try {
            setIsLoading(true)
            const token = await getToken();
            const config = { headers: { 'Authorization': 'Bearer ' + token } };
            const promises = [getWorkspacesPB(config), getBusinessPowerBI(), getReportsSquadBI()]
            const [workspaces, actualBusiness, actualReports] = await Promise.all(promises)
    
            const reportsByWorkspace = {};
    
            for (const workspace of workspaces) {
                const reports = await getReportsPB(workspace.id, config);
                if (reports.length > 1) {
                    const filteredReports = reports.filter(report => {
                        return !actualReports.relatorios.some(embedded => 
                            embedded.embedded_relatorio === report.id || 
                            embedded.nome === report.name) &&
                            report.reportType !== "PaginatedReport";
                    });
                    if (filteredReports.length > 0) {
                        reportsByWorkspace[workspace.name] = filteredReports;
                    }
                }
            }
    
            setIsLoading(false)
            setReportsInWorkspaces(reportsByWorkspace)
        } catch (error) {
            console.error(error.message);
        }
    }, []);
    

    const getToken = useCallback(async () => {
        try {
            const res = await requestReportsInfo('sync')
            return res.token;
        } catch (error) {
            return error
        }
    }, [])

    const getBusinessPowerBI = useCallback(async () => {
        try {
            const res = await api.post("/business", {})
            return res.data            
        } catch (error) {
            return error
        }
    }, [])

    const getReportsSquadBI = useCallback(async () => {
        try {
            const res = await api.post("/reports", {})
            return res.data            
        } catch (error) {
            return error
        }
    }, [])

    const getWorkspacesPB = useCallback(async (config) => {
        try {
            const res = await axios.get('https://api.powerbi.com/v1.0/myorg/groups', config)
            return res.data.value.map(item => ({name: item.name, id: item.id}))
        } catch (error) {
            return error
        }
    }, [])

    const getReportsPB = useCallback(async (groupId, config) => {
        try {
            const res = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports`, config)
            return res.data.value
        } catch (error) {
            return error    
        }
    }, [])

    useEffect(() => {getAll()}, [])

    const toggleWorkspaceSelection = (workspaceName, reports) => {
        const isSelected = selectedWorkspaces.find(item => item.workspaceName === workspaceName);
        if (isSelected) {
            setSelectedWorkspaces(selectedWorkspaces.filter(item => item.workspaceName !== workspaceName));
        } else {
            setSelectedWorkspaces([...selectedWorkspaces, { workspaceName, reports }]);
        }
    };

    const handleSyncSelectedItems = useCallback(() => {
        try {
            console.log(selectedWorkspaces)            
        } catch (error) {
            console.error(error.message)
        }
    }, [selectedWorkspaces, setSelectedWorkspaces])

    const handleSyncAllItems = useCallback(() => {
        try {
            console.log(reportsInWorkspaces)
        } catch (error) {
            console.error(error.message)
        }
    }, [reportsInWorkspaces, setReportsInWorkspaces])

    return (
        <main className="modal-sync-reports">
            <div className='content-sync-reports'>
                <i className='mdi mdi-close close-item' onClick={() => setSyncPowerBI('indle')} />
                <header>
                    <p className='title'>Painel de sicronização</p>
                    <p className='description'>Sincronize os seus relatórios/workspaces no Power BI Serviço com o SquadBI</p>
                </header>
                <div className='list-items-sync'>
                {
                    isLoading ?
                        <div className='sync-loading'>
                            <CircularProgress color="inherit" />
                        </div>
                    :
                        Object.entries(reportsInWorkspaces).length > 0 ? Object.entries(reportsInWorkspaces).map(([workspaceName, reports]) => (
                            <div
                                onClick={() => toggleWorkspaceSelection(workspaceName, reports)}
                                className={`workspace-sync ${
                                    selectedWorkspaces.find(item => item.workspaceName === workspaceName) ? 'isChecked' : ''
                                }`}
                                key={workspaceName}
                            >
                                <label className='sync-field-item'>Workspace</label>
                                <p className='workspace-sync-title'>{workspaceName}</p>
                                <label className='sync-field-item'>{reports.length === 1 ? 'Relatório' : 'Relatórios'}</label>
                                {reports.map(report => (
                                    <div className='report-sync-item' key={report.id}>
                                        <p className='report-sync-item-title'>{report.name}</p>
                                    </div>
                                ))}
                            </div>   
                        ))
                        :
                        <div className='sync-loading'>
                            <p>Você não possui itens para serem sincronizados.</p>
                        </div>
                }
                </div>
                <div className='list-options-sync'>
                    <p className='sync-all-text'>Escolha os itens para sincronizar ou sincronize tudo.</p>
                    <div className='click-items-sync'>
                        <div 
                            className={`sync-btn ${selectedWorkspaces.length > 0 && 'cliclabe'}`}
                            onClick={selectedWorkspaces.length > 0 ? handleSyncSelectedItems :() => null}
                        >
                                Sincronizar selecionados
                        </div>
                        <div
                            className={`sync-btn ${Object.entries(reportsInWorkspaces).length > 0 && 'cliclabe'}`}
                            onClick={Object.entries(reportsInWorkspaces).length > 0 ? handleSyncAllItems : () => null}>
                            Sincronizar tudo
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}