import React, {useContext} from 'react';
import Mobile from './mobile';
import FirstAcessAi from './firstAcessAi';
import { storage } from 'utils';

export default function ShowAlert({ showAlertsId, onCloseAlert, onDisableAlert }) {


    return (
        (showAlertsId && showAlertsId.id_alerta) 
            ? (showAlertsId.id_alerta === 1 
                ? <Mobile onRender={() => onDisableAlert(showAlertsId.id)} onClose={onCloseAlert} /> 
                : showAlertsId.id_alerta === 2 &&  storage.get('@exibirAssistente') === true && storage.get('@hasClicked') === true
                ? <FirstAcessAi  onRender={() => onDisableAlert(showAlertsId.id)} onClose={onCloseAlert} /> 
                : <></>) 
            : <></>
    );
}
