import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './styles.css';

import { ImageConfig } from './config/ImageConfig'; 
import uploadImg from '../../../assets/images/dragAndDropInput/cloud-upload.png';
import Swal from 'sweetalert2';

const InputDragAndDrop = forwardRef(({onFileChange,limitFiles = 10, acceptSameName,...rest},ref) => {

    const wrapperRef = useRef(null);

    const [fileList, setFileList] = useState([]);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    useImperativeHandle(ref, () => ({
        reset: () => reset()
      }));

    const onFileDrop = (e) => {
        const newFile = e.target.files[0];
        if(acceptSameName) {
            if(acceptSameName != newFile.name.split('.pbix')[0]) {
                Swal.fire({
                    title:`O Arquivo escolhido não é o mesmo do relatório atual.`,
                    icon:'warning',
                    timer:1500,
                    showConfirmButton:false
                })
                return
            }
        }
        if (newFile && fileList.length < limitFiles) {
            const updatedList = [...fileList, newFile];
            setFileList(updatedList);
            onFileChange(updatedList);
        }else{
            Swal.fire({
                title:`Limite de ${limitFiles} arquivo atingido`,
                icon:'warning',
                timer:1500,
                showConfirmButton:false
              })
        }
    }

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        onFileChange(updatedList);
    }

    const reset = () => {
        setFileList([]);
    }

    return (
        <>
            <div
                ref={wrapperRef}
                className="drop-file-input"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <img src={uploadImg} alt="" />
                    <p>Arraste arquivos aqui ou clique para buscar...</p>
                    <p className='limitMessage'>{fileList.length == limitFiles ? `Limite de ${limitFiles} arquivo atingido` : ''}</p>
                </div>
                <input type="file" value="" {...rest} onChange={onFileDrop}/>
            </div>
            {
                fileList.length > 0 ? (
                    <div className="drop-file-preview">
                        <p className="drop-file-preview__title">
                            Pronto para carregar
                        </p>
                        {
                            fileList.map((item, index) => (
                                <div key={index} className="drop-file-preview__item">
                                    <img src={ImageConfig[item.name.split('.')[1]] || ImageConfig['default']} alt="" />
                                    <div className="drop-file-preview__item__info">
                                        <p>{item.name}</p>
                                        <p>{item.size}B</p>
                                    </div>
                                    <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>x</span>
                                </div>
                            ))
                        }
                    </div>
                ) : null
            }
        </>
    );
})

InputDragAndDrop.propTypes = {
    onFileChange: PropTypes.func
}

export default InputDragAndDrop;
