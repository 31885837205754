import Cookie from 'js-cookie';

const storage = {};

// Safari in incognito has local storage, but size 0
// This system falls back to cookies in that situation
try {
  if (!window.sessionStorage || !window.localStorage) {
    throw Error('no storage');
  }

  function getStorage(){
    const getURL = window.location.host

    if(getURL.includes('bi-redesim')){
      return sessionStorage
    }else{
      return localStorage
    }
  }

  let WINDOW_STORAGE = getStorage();

  // Setup simple local storage wrapper
  storage.set = (key, value) => WINDOW_STORAGE.setItem(key, JSON.stringify(value));
  storage.get = (key) => {
    const item = WINDOW_STORAGE.getItem(key);
    try {
      return JSON.parse(item);
    } catch (e) {
      return null;
    }
  };
  storage.remove = key => WINDOW_STORAGE.removeItem(key);
  storage.clear = () => WINDOW_STORAGE.clear();

} catch (e) {
  storage.set = Cookie.set;
  storage.get = Cookie.getJSON;
  storage.remove = Cookie.remove;
}

export default storage;
