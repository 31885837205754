import { useTranslation } from 'react-i18next'

const useHomeData = () => {
  const { t } = useTranslation()

  const data = [[`1-${t('sidebar.home')}`, 'Home']]

  return data
}

export default useHomeData
