import React, { Component } from 'react';
import { Tooltip } from '@mui/material';

class RlsItemGrups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true,
    };
  }

  render() {
    const { isActive } = this.state;
    const { grupo, usuarios, removerItem } = this.props;

    return (
      <main className="container-rls-users" key={grupo}>
        <section
          className="container-rls-users-info"
          onClick={() => this.setState((prevState) => ({ isActive: !prevState.isActive }))}
        >
          <p>{`${grupo == 'null' ? 'Desconhecido' : grupo} (${usuarios.length})`}</p>
          <i className={isActive ? "mdi mdi-chevron-up" : "mdi mdi-chevron-down"} />
          <Tooltip title="Remover todos os usuários desse grupo" arrow placement='top'>
            <i className='mdi mdi-close' />
          </Tooltip>
        </section>
        {isActive && (
          <section className="container-rls-users-list">
            <div className='container-rls-users-list-divider'>
                <i className='container-rls-users-list-divider-line' />
                <i className='icon-circle-divider' />
            </div>
            <div className='container-rls-users-list-item-users'>
                {usuarios
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((item, key) => (
                    <div className="container-rls-users-list-item" key={key}>
                      <div className='container-rls-users-list-item-buttons'>
                        <Tooltip title="Remover usuário" arrow>
                            <input type="checkbox" checked={item.value} onChange={() => removerItem(item.value)} />
                        </Tooltip>
                      </div>
                        <p>{item.label}</p>
                    </div>
                ))}
            </div>
          </section>
        )}
      </main>
    );
  }
}

export default RlsItemGrups;
