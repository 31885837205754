import React from 'react';
import './index.css'

const LoaderButton = () => {

  return (
    <div class="loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  
  );
};

export default LoaderButton;