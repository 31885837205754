import './style.css'
import React from 'react'
import { Tooltip } from '@mui/material'
import * as Icon from '@icon-park/react';
import { useHistory } from 'react-router-dom'
import { storage } from 'utils';

export default function ListItems({ itemsFilteredForRendering, reload }) {

    const history = useHistory();

    // Setar items de pesquisa no historico
    function handleSetHistory(item) {
        let history = storage.get('@user_history') || [];
    
        const existingIndex = history.findIndex((historyItem) => historyItem.id === item.id);
    
        if (existingIndex !== -1) {
            history.splice(existingIndex, 1);
        }
    
        item.history = new Date();
    
        history.unshift(item);
    
        storage.set('@user_history', history);
    }

    return (
        <div>
            {itemsFilteredForRendering.map((value, key) => {
                const handleClick = () => {
                    if (value.rota !== null && value.rota !== '') {
                        history.push(value.rota);
                        handleSetHistory(value);
                        reload()
                    } else {
                        history.push(`/viewReport/${value.embedded_relatorio}/${value.embedded_workspace}/${value.tipo}/${value.paginado}`);
                        handleSetHistory(value);
                        reload()
                    }
                }

                const icone = verificarIcone()

                function verificarIcone() {
                    if(value.icon && value.icon.includes('mdi mdi')) {
                        return 'AllApplication'
                    } else {
                        return value.icon
                    }
                };

                return (
                    <Tooltip title={value.grupo_negocio.split("-")[1]} placement="right" arrow key={key}>
                        <div className='search-bar-result-item' onClick={handleClick}>
                            <div className='search-bar-result-item-icon'>
                                {React.createElement(Icon[icone || 'AllApplication'], { theme: "outline", size: 16, fill: "#222" })}
                            </div>
                            <div className='search-bar-result-item-name'>
                                <p>{value.nome}</p>
                                <p className='search-bar-result-item-name-group'>{value.grupo_negocio && value.grupo_negocio.split("-")[1]}</p>
                            </div>
                        </div>
                    </Tooltip>
                )
            })}
        </div>
    );
}
