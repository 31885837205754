import { storage } from 'utils';
import Connection from '../connection';
import './style.css';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ItemMinimize from '../itemMinimize';

export default function SubGroupMinimize({item, last, group}) {

    let location = useLocation()
    let isActive = (location.pathname === `/viewReport/${item.embedded_relatorio}/${item.embedded_workspace}/${item.tipo}/${item.paginado}` || location.pathname === item.rota)

    return (
        <div className="sub-group-item-minimize-container" style={{height: (item.length * 35) + 35}}>
            <div className='item-minimize-container-upper'>
                <Connection last={last} />
                <div className='item-minimize-name'>
                    <i className={`mdi mdi-folder-open`} />
                    <p className={isActive ? 'minimize-name-active' : 'minimize-name'}>
                        {item[0].nome_sub_grupo}
                    </p>
                </div>
            </div>
            <div className='sub-group-item-minimize-list' style={{borderLeft: !last ? '1px solid rgba(200, 200, 200, 0.4)' : ''}}>
                {item.length > 0 &&
                    item.map((prop, key) => {
                        if(key === item.length -1) {
                            return (
                                <div className='group-minimize-list-items-part'>
                                    <ItemMinimize item={prop} key={key} last={true} group={group} />
                                </div>
                            )
                        } else {
                            return (
                                <div className='group-minimize-list-items-part'>
                                    <ItemMinimize item={prop} key={key} last={false} group={group} />
                                </div>
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}