import Swal from 'sweetalert2';
import storage from "./storage";
import { pegarUsuario } from "services/api/autenticacao";
import api from 'services/api/api';

export async function requestSwitchPassowrd() {
    const user = await pegarUsuario();
    if(user){
        const firstAcess = storage.get('@primeiroAcesso') || false
        if(firstAcess) {
            handleWarnSwitchPassword(user)
        }
    }
}
    
function handleWarnSwitchPassword(user) {
        Swal.fire({
            html: `Olá, <b> ${user.nome}</b>, sua conta foi criada por um administrador, deseja alterar a senha?`,
            icon:'question',
            confirmButtonText:'Alterar senha',
            cancelButtonText:'Cancelar',
            showCancelButton:true,
        }).then((result) => {
            if(result.isConfirmed) {
                Swal.fire({
                    html: `Redefinir sua senha</b>`,
                    input: 'password',
                    icon:'warning',
                    confirmButtonText:'Alterar senha',
                    cancelButtonText:'Cancelar',
                    showCancelButton:true,
                    inputPlaceholder: `Insira sua nova senha, ${user.nome}...`,
                    preConfirm:async(password) => {
            
                        if(!password){
                          Swal.showValidationMessage('O campo nao pode ficar vazio!')
                          return false
                        }
            
                        Swal.showLoading()
                        const attrs = {
                          id_usuario: user.id_usuario,
                          password
                        }
                        await api.post('/resetPassword',attrs)
                        .then(({ data:{ status }}) => {
                  
                          if(status == 1){
                            Swal.fire({
                              timer: 3000, 
                              toast: true,
                              showConfirmButton: false,
                              position: "top-end",
                              title: "Senha alterada com sucesso!",
                              icon: "success"
                            })
                            storage.remove('@primeiroAcesso')
                          }else{
                            Swal.fire({
                              title:'Erro ao alterar a senha!',
                              icon:'error',
                              timer:1500
                            })
                          }
                  
                        })
                        .catch(err => {
                          Swal.fire({
                            title:`Ocorreu um erro interno :: ${err.message}`,
                            icon:'error',
                            timer:1500
                          })
                        })
                    }
                  })
            }
        })
}