import './styles.scss'
import axios from 'axios'
import api from 'services/api/api';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AreaInput from '../Editar/components/AreaInput';
import { ReportsContext } from 'contexts/reportsContext';
import Image from '../../../../../assets/images/pbi_semfundo.png'
import Loader from '../../../../../assets/images/newloader.gif'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { FileConversionOne } from '@icon-park/react';
import { AuthContext } from 'contexts/appProvider';

function PublicarRelatorio() {

    const dragRef = useRef();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const isLoadingRef = useRef(isLoading);

    const { requestReportsInfo, handleSumit, typeError, clearStates } = useContext(ReportsContext)

    const { dataAppearance } = useContext(AuthContext)

    const [isRdl, setIsRdl] = useState(false)
    const [step, setStep] = useState(1)
    const [file, setFile] = useState('')
    const [publishInWorkspace, setPublishInWorkspace] = useState(false)

    const [reports, setReports] = useState([])
    const [reportsPaginated, setReportsPaginated] = useState([])
    const reportsPaginatedRef = useRef(reportsPaginated);

    const [isSearchedPaged, setIsSearchedPaged] = useState(false)

    const [businessAreas, setBusinessAreas] = useState([])
    const [businessAreaSelected, setBusinessAreaSelected] = useState()
    const [businessAreaSelectedId, setBusinessAreaSelectedId] = useState('')

    const [workspace, setWorkspace] = useState([])
    const [selectedWorkspace, setSelectedWorkspace] = useState(null)

    const [isExistsReport, setIsExistsReport] = useState(null);
    const [isDifferentType, setIsDifferentType] = useState(false);

    const [isLoadingRefresh, setIsLoadingRefresh] = useState(false)
    const [isDatasetUpdate, setIsDatasetUpdate] = useState(false)

    function handleDragOver(e) {
        e.preventDefault()
    }

    function clearAllData() {
        setIsRdl(false)
        setStep(1)
        setFile('')
        setIsExistsReport(null)
        setIsDifferentType(false)
        setBusinessAreaSelected()
        setBusinessAreaSelectedId('')
        setIsLoadingRefresh(false)
        setIsDatasetUpdate(false)
    }

    async function handleDrop(e, sendFile = null) {

        if (e) {
            e.preventDefault();
        }
    
        let file;
        if (sendFile === null) {
            file = e.dataTransfer.files[0];
        } else {
            file = sendFile;
        }
    
        const fileName = file.name;
        const isPBIX = fileName.includes('.pbix') || fileName.includes('.PBIX');
        const isRDL = fileName.includes('.rdl') || fileName.includes('.RDL');
    
        if (isPBIX || isRDL) {
            if (isRDL) {
                setIsRdl(true);
            }
            
            setFile(file);
            const baseName = fileName.split('.').slice(0, -1).join('.');


            const reportExists = isRDL ? await selectExistsPaginated(baseName) : await selectExistsReport(baseName);
            
            const existsEmbedded = isRDL ? reportExists.exists_embedded_rdl : reportExists.exists_embedded;
            const existsWorkspace = isRDL ? reportExists.exists_workspace_rdl : reportExists.exists_workspace;
            
            if (existsEmbedded) {
                if (isRDL) {
                    setIsExistsReport({file: file, businessArea: null,publishInWorkspace: existsWorkspace, isRDL: true, isRDLUpdate: true})
                    // handleSumit(file, null, existsWorkspace, true, true);
                } else {
                    const status = await getLastRefresh(existsEmbedded, existsWorkspace);
                    if (status) {
                        setIsDatasetUpdate(true);
                    } else {
                        setIsExistsReport({file: file, businessArea: null, publishInWorkspace: existsWorkspace, isRDL: false, isRDLUpdate: false})
                        // handleSumit(file, null, existsWorkspace);
                    }
                }
            } else {
                if (isRDL) {
                    const hasWorkspace = businessAreas.some(item => item.cod_workspace);
                    if (hasWorkspace) {
                        setStep(2);
                    } else {
                        publishPaginatedReport();
                    }
                } else {
                    setStep(2);
                }
            }
        } else {
            setIsDifferentType(true);
        }
    }    

    async function sendFile(file) {
        handleDrop(null, file)
    }

    function waitForLoading() {
        return new Promise(resolve => {
            const checkLoading = setInterval(() => {
                if (!isLoadingRef.current) {
                    clearInterval(checkLoading);
                    resolve();
                }
            }, 1000);
        });
    }

    async function selectExistsReport(fileName) {
        setIsSearchedPaged(true)
        await new Promise(resolve => setTimeout(resolve, 5000));
        const value = reports.find(item => item.nome === fileName);
        setIsSearchedPaged(false)
        if (value && value !== undefined) {
            return { exists_embedded: value.embedded_relatorio, exists_workspace: value.embedded_workspace };
        } else {
            return false;
        }
    }

    async function selectExistsPaginated(fileName) {
        if(fileName.includes('.pbix')) {
            return false;
        } else {
            setIsSearchedPaged(true)
            await waitForLoading()
            await new Promise(resolve => setTimeout(resolve, 3000));
            setIsSearchedPaged(false)
            const value = reportsPaginatedRef.current.find(item => item.name === fileName)
            if(value && value != undefined) {
                return {exists_workspace_rdl: value.webUrl.split('/')[4], exists_embedded_rdl: value.webUrl.split('/')[6]}
            } else {
                return false
            }
        }
    }

    function publishPaginatedReport() {
        try {
            handleSumit(file, businessAreaSelectedId, selectedWorkspace ? selectedWorkspace.value : '', true)
        } catch (error) {
            return error;
        }
    }

    const getAll = useCallback(async () => {
        try {
            setIsLoading(true)
            const token = await getToken();
            const config = { headers: { 'Authorization': 'Bearer ' + token } };
            const promises = [getWorkspacesPB(config), getBusinessPowerBI(), handleRequestAllReports()]
            const [workspaces, actualBusiness] = await Promise.all(promises)
    
            const reportsByWorkspace = [];
    
            for (const workspace of workspaces) {
                const reports = await getReportsPB(workspace.id, config);
                if(reports.length > 0) {
                    reportsByWorkspace.push(...reports.filter(report => report.reportType === 'PaginatedReport'));
                }
            }
    
            setIsLoading(false)
            setReportsPaginated(reportsByWorkspace)
        } catch (error) {
            console.error(error.message);
        }
    }, []);

    const getToken = useCallback(async () => {
        try {
            const res = await requestReportsInfo('sync')
            return res.token;
        } catch (error) {
            return error
        }
    }, [])

    const getReportsPB = useCallback(async (groupId, config) => {
        try {
            const res = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports`, config)
            return res.data.value
        } catch (error) {
            return error    
        }
    }, [])

    async function handleRequestAllReports() {
        try {
            const res = await api.post("/reports")
            const reports = res.data.relatorios
            if(reports) {
                setReports(reports)
            } else {
                setReports([])
            }
        } catch (error) {
            return error.mensagem
        }
    }

    async function getWorkspacesPB(config) {
        try {
          const response = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups`, config)
          const transformedData = response.data.value.map(workspace => ({
            label: workspace.name,
            value: workspace.id
          }));
          setWorkspace(transformedData)
          return response.data.value.map(item => ({name: item.name, id: item.id}))
        } catch (error) {
          return error;
        }
    }

    async function getBusinessPowerBI() {
        try {
            const res = await api.post("/business");
            const areasNegocios = res.data.business.filter(item => item.status == 1).map((areaNegocio) => ({
                value: areaNegocio.id_area_negocio,
                label: areaNegocio.negocio,
                icon: areaNegocio.icon,
                cod_workspace: areaNegocio.cod_workspace
            }));
            setBusinessAreas(areasNegocios);
        } catch (error) {
            return error.message
        }
    }

    async function getLastRefresh(embedded_report, embedded_workspace) {
        try {
          setIsLoadingRefresh(true)
          const info = await api.post('/reportsInfo-v2', {"nome": 'Create Workspace'})
          const config = {
              headers: { 'Authorization': 'Bearer ' + info.data.token},
          };
          const first = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${embedded_workspace}/reports/${embedded_report}`, config)
          const second = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${embedded_workspace}/datasets/${first.data.datasetId}/refreshes?$top=1`, config)
          setIsLoadingRefresh(false)
          if(second.data.value.length > 0) {
              if(second.data.value[0].startTime && second.data.value[0].endTime) {
                return false
              } else {
                return true
              }
          } else {
            return false;
          }
        } catch (error) {
          return false
        }
    }

    useEffect(() => {
        getAll() 
        clearAllData()
    },[])

    useEffect(() => { isLoadingRef.current = isLoading; }, [isLoading]);

    useEffect(() => { reportsPaginatedRef.current = reportsPaginated; }, [reportsPaginated]);

    if(isExistsReport) {
        return (
            <main className='warn-update-report'>
                <div className='warn-update-content'>
                    <header className='warn-update-header'>
                        <FileConversionOne theme="filled" size="24" fill="#777"/>
                        <h1>{(t('publish.warnUpdate.index'))}</h1>
                    </header>
                    <div className='warn-update-item'>
                        <p className='words-warn'>{(t('publish.warnUpdate.words'))}</p>
                    </div>
                    <div className='warn-btns-item'>
                        <div 
                            className='btn-warn accept' 
                            onClick={
                                () => handleSumit(
                                                    isExistsReport.file,
                                                    isExistsReport.businessArea, 
                                                    isExistsReport.publishInWorkspace, 
                                                    isExistsReport.isRDL, 
                                                    isExistsReport.isRDLUpdate
                                                )
                                }
                            >
                                {(t('publish.warnUpdate.acceptBtn'))}
                            </div>
                        <div className='btn-warn' onClick={() => clearStates()}>{(t('publish.warnUpdate.notAcceptBtn'))}</div>
                    </div>
                </div>
            </main>  
        )
    }

   //console.log('minha console',  dataAppearance.estrutura_workspaces)

    return (
        <main className='publish-report-modal' onDragOver={handleDragOver} onDrop={handleDrop}>
            <div className={`publish-report-content ${step === 2 ? 'step-two' : ''}`}>
                <span className='mdi mdi-close' onClick={() => clearStates()} />
                    <div className='publish-report-steps'>
                        <div className="bullet-line-mini" />
                        <div className={step === 2 ? 'bullet-point-ok' : 'bullet-point'}>
                            <div className='ring' />
                            <i className={step === 1 ? 'mdi mdi-file-chart' : 'mdi mdi-check'} />
                        </div>
                        <div className={`bullet-line ${step === 2 ? 'ok' : ''}`}>
                            <div className='in-line' />
                        </div>
                        <div className='bullet-point'>
                            {step === 2 && <div className='ring' />}
                        </div>
                        <div className="bullet-line-mini off" />
                    </div>
                <form 
                    action=''
                    className={`publish-report-content-container ${step === 2 ? 'step-two' : ''}`}
                >
                    {step === 1 
                    ? 
                        <>
                            <input 
                                ref={dragRef}
                                hidden
                                type='file' 
                                accept=".pbix, .rdl"
                                className='input-drag-drop-field'
                                onChange={e => sendFile(e.target.files[0])}
                            />
                            <img src={Image} />
                            <div className='informations'>
                                <h1 className='title'>{(t('publish.optionNew.title'))}</h1>
                                <p>{(t('publish.optionNew.description'))}</p>
                            </div>
                            {!isSearchedPaged && 
                                <div className='btn-search-file' onClick={() => document.querySelector(".input-drag-drop-field").click()}>
                                <p>{(t('publish.optionNew.warn'))}</p>
                                <i className='mdi mdi-chevron-down' />
                                </div>
                            }
                            {
                                isExistsReport &&
                                <div className='preview-send-file'>
                                    <i className='mdi mdi-file-multiple' style={{fontSize: 14}} />
                                    <p>{(t('publish.optionNew.existsReport'))}</p>
                                </div>
                            }
                            {(typeError || isDifferentType)  && 
                                <div className='preview-send-file'>
                                    <p>{(t('publish.optionNew.formatInsupport'))}</p>
                                </div>
                            }
                            {
                                isSearchedPaged  && <img src={Loader} style={{height: 35, width: 35}} />
                            }
                            {isLoadingRefresh &&
                                <div className='send-file-loading'>
                                    <img src={Loader} />
                                </div>
                            }
                            {
                                isDatasetUpdate &&
                                <div className='preview-send-file'>
                                    <i className='mdi mdi-database' style={{fontSize: 24}} />
                                    <p>{t('publish.optionRefresh.attExec')}</p>
                                </div>
                            }
                        </> 
                    :
                        <div className='publish-report-form-content'>
                            {!isRdl &&
                                <AreaInput
                                    label={t('form.workspace')} 
                                    type='select'
                                    inputProps={{
                                        placeholder: "",
                                        noOptionsMessage: () => t('form.notFound'),
                                        options: businessAreas,
                                        value: businessAreaSelected,
                                        onChange: (item) => {
                                            setBusinessAreaSelected(item)
                                            setBusinessAreaSelectedId(item.value)
                                        },
                                    }}
                                />
                            }
                            {
                                isRdl && 
                                <AreaInput
                                    label={t('form.workspace')} 
                                    type='select'
                                    inputProps={{
                                        placeholder: "",
                                        noOptionsMessage: () => t('form.notFound'),
                                        options: workspace,
                                        value: selectedWorkspace,
                                        onChange: (item) => {
                                            setSelectedWorkspace(item)
                                        },
                                    }}
                                />
                            }
                            {businessAreaSelected && businessAreaSelected.cod_workspace != '' && businessAreaSelected.cod_workspace != null &&  dataAppearance.estrutura_workspaces !== 2 &&
                                <AreaInput
                                    label={t('publish.optionNew.workspacePBI')} 
                                    type='switch'
                                    inputProps={{
                                        checked: publishInWorkspace, 
                                        onClick: () => {
                                            setPublishInWorkspace(!publishInWorkspace)}
                                    }}
                                />
                            } 
                            {!isRdl &&
                                <button 
                                    className='btn-send-report'
                                    onClick={() => handleSumit(file, businessAreaSelectedId, dataAppearance.estrutura_workspaces === 2 ? businessAreaSelected.cod_workspace : publishInWorkspace ? businessAreaSelected.cod_workspace : '')} 
                                    disabled={!businessAreaSelected}
                                >
                                    {t('form.publish')}
                                </button>
                            }
                            {isRdl &&
                                <button 
                                    className='btn-send-report'
                                    onClick={() => publishPaginatedReport()} 
                                    disabled={!selectedWorkspace}
                                >
                                    {t('form.publish')}
                                </button>
                            }
                        </div>
                    }
                </form>
            </div>
        </main>
    )
}

export default withRouter(PublicarRelatorio)