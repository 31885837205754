import './styles.scss'
import Select from 'react-select'
import api from 'services/api/api';
import { IOSSwitch } from '../iosSwitch';
import { Option } from "../optionBookmark";
import { Menu, Paper, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'contexts/appProvider';
import { storage } from 'utils';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

function FilterControl({currentReport, others}) {

    const { handleRemoveFilters, hasFilter } = useContext(AuthContext)

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const [isActive, setIsActive] = useState(true);
    const [expiresIn, setExpiresIn] = useState(0); // [0: "nunca",  1: "24 horas", 2: "76 horas", 3: "Ao atualizar"]

    const [isCreateBookmark, setIsCreateBookmark] = useState(false)
    const [newBookmarkName, setNewBookmarkName] = useState('');
    const [bookmarkApplied, setBookmarkApplied] = useState(null)
    const [bookmarkActive, setBookmarkActive] = useState(null);
    const [dataBookmark, setDataBookmark] = useState([]);

    const [initialValues, setInitialValues] = useState({ isActive: true, expiresIn: 0 });
    const [hasChanges, setHasChanges] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    async function requestAll() {
        try {
            const promises = [requestUserBookmarks(), requestFilterConfig()];
            await Promise.all(promises);
            setIsLoading(false);
        } catch (error) {
            return console.error(error)
        }finally{
            setIsInitialLoad(false);
            setHasChanges(false); 
        }
    }

    async function requestFilterConfig() {
        try {
            const response = await api.post('/filterConfig', {id_relatorio: currentReport.id})
            if(response.data.status) {
                if(Array.isArray(response.data.result)) {
                    const values = response.data.result[0];
                    setIsActive(values.filtros_ativos === 1 ? true : false)
                    setExpiresIn(values.tempo_duracao)
                } else {
                    setExpiresIn(0);
                    const defaultFalse = window.location.host.includes('monaco')
                    setIsActive(defaultFalse ? false : true)
                }
            }
        } catch (error) {
            return error;
        }
    }

    async function requestUserBookmarks() {
        try {
            const response = await api.post('/bookmarks', {id_relatorio: currentReport.embedded_relatorio})
            if(response.data && response.data.bookmarks != null && response.data.bookmarks.length > 0) {
                setDataBookmark(response.data.bookmarks)
            } else {
                setDataBookmark([])
            }
        } catch (error) {
            return error;
        }
    }

    useEffect(() => {requestAll()}, [currentReport.embedded_relatorio])

    useEffect(() => {
        setHasChanges(isActive !== initialValues.isActive || expiresIn !== initialValues.expiresIn);
    }, [isActive, expiresIn, initialValues]);

    async function applyBookmark(state) {
        try {
            await window.report.bookmarksManager.applyState(state.value);
            setBookmarkActive(state.value);
            setBookmarkApplied(null)
        }
        catch (error) {
            console.log(error);
        }
    }

    async function updateBookmark(bookmark) {
        try {
            var bookmarkState = await window.report.bookmarksManager.capture()
            var bookmarkState = bookmarkState.state; 
            const response = await api.post('/bookmarksUpdate', {id_personal_bookmark: bookmark.id, state: bookmarkState})
            if(response.data.status) {
                setBookmarkApplied(null)
                setIsCreateBookmark(false);
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "Indicador atualizado com sucesso!",
                    showConfirmButton: false,
                    timer: 7500
                });
                return requestUserBookmarks();
            } else {
                console.log(response.data.erro)
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "error",
                    title: "Houve algum problema ao atualizar!",
                    showConfirmButton: false,
                    timer: 7500
                });    
            }
        } catch (error) {
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Houve algum problema ao atualizar!",
                showConfirmButton: false,
                timer: 7500
            });
            return console.error(error)
        }
    }

    async function createBookmark() {
        try {
            var bookmarkState = await window.report.bookmarksManager.capture()
            var bookmarkState = bookmarkState.state; 
            const response = await api.post('/bookmarksCreate', {id_relatorio: currentReport.embedded_relatorio, nome: newBookmarkName, state: bookmarkState})
            if(response.data.status) {
                requestUserBookmarks();
                setNewBookmarkName('');
                setIsCreateBookmark(false);
                Swal.fire({
                    toast: true,
                    position: "top-end",
                    icon: "success",
                    title: "Indicador criado com sucesso!",
                    showConfirmButton: false,
                    timer: 5000
                });
            }
        } catch (error) {
            return error;
        }
    }

    async function deleteBookmark(value){
        try {
            const response = await api.post('/bookmarksDelete', {id_personal_bookmark: value})
            if(response.data.status) {
                if (dataBookmark.length > 0) {
                    const indexToDelete = dataBookmark.findIndex(item => item.id === value);
                    if (indexToDelete !== -1) {
                        const newData = [...dataBookmark]
                        newData.splice(indexToDelete, 1)
                        setDataBookmark(newData)
                        setBookmarkApplied(null)
                    }
                } else {
                    setDataBookmark([]);
                }
            }
        } catch (error) {
            return error;
        }
    }

    async function removeBookmarkInCache() {
        return new Promise(resolve => {
            if(true) {
                let history = storage.get('@user_history') || [];
                
                const existingIndex = history.findIndex((historyItem) => historyItem.embedded_relatorio === currentReport.embedded_relatorio);
                  
                if (existingIndex !== -1) {
                    const item = history[existingIndex];
                    // delete item.last_bookmark_state;
                    item.last_bookmark_state = undefined;
                    item.history = new Date();
                    history[existingIndex] = item;
                    storage.set('@user_history', history);
                }
            }
    
            resolve();
        });
    }

    async function updateFiltersConfig() {
        try {
            const response = await api.post('/updateFiltersConfig', {id_relatorio: currentReport.id, active_filters: isActive ? 1 : 0, expiresIn: expiresIn})
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Definições de filtros atualizadas.",
                showConfirmButton: false,
                timer: 5000
            });
            setInitialValues({ isActive, expiresIn }); 
            setHasChanges(false); 
        } catch (error) {
            return console.error(error);
        }
    }


    return (
        <Menu
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
            }}
            transformOrigin={{
                horizontal: 'right',
            }}
            className='menu-filter-control-container'
            {...others}
        >
        <Paper 
            sx={{ width: 480, maxHeight: 850 }}
            style={{boxShadow: 'none', paddingTop: 0}}>
                <div className='content-filter-control'>
                    <header className='filter-control-header'>
                        <p className='title-control'>{t('filterControl.index')}</p>
                        <p className='report-control'>{(currentReport && currentReport.nome) ? (currentReport.nome_exibicao && currentReport.nome_exibicao.length > 0 && currentReport.nome_exibicao != 'null') ? currentReport.nome_exibicao : currentReport.nome : ''}</p>
                    </header>
                    <section className='control-content'>
                        <div className='item-option'>
                            <label className='filter-control-label'>
                                Geral
                                <Tooltip 
                                    title='Esta configuração é válida para sua sessão atual. Para salvar e usar a qualquer momento, selecione "Guardar novo indicador".'
                                    followCursor={true}
                                    arrow
                                >
                                    <i className='mdi mdi-information-outline' />
                                </Tooltip>
                            </label>
                            <div className='control-option'>
                                <IOSSwitch checked={isActive} onClick={() => setIsActive(!isActive)} />
                                <p className='option-description'>Salvar filtros nesse relatório</p>
                            </div>
                        </div>
                        <div className='item-option'>
                            <label className='filter-control-label'>Expiração dos filtros</label>
                            <div className='radio-list'>
                                <div
                                    onClick={() => setExpiresIn(0)} 
                                    className='radio-item'
                                >
                                    <i className={expiresIn === 0 ? 'mdi mdi-radiobox-marked' : 'mdi mdi-radiobox-blank'} />
                                    <p className='radio-description'>Nunca</p>
                                </div>
                                <div
                                    onClick={() => setExpiresIn(1)}  
                                    className='radio-item'
                                >
                                    <i className={expiresIn === 1 ? 'mdi mdi-radiobox-marked' : 'mdi mdi-radiobox-blank'} />
                                    <p className='radio-description'>24 horas</p>
                                </div>
                                <div
                                    onClick={() => setExpiresIn(2)}   
                                    className='radio-item'
                                >
                                    <i className={expiresIn === 2 ? 'mdi mdi-radiobox-marked' : 'mdi mdi-radiobox-blank'} />
                                    <p className='radio-description'>76 horas</p>
                                </div>
                                {/* <div
                                    onClick={() => setExpiresIn(3)}  
                                    className='radio-item'
                                >
                                    <i className={expiresIn === 3 ? 'mdi mdi-radiobox-marked' : 'mdi mdi-radiobox-blank'} />
                                    <p className='radio-description'>Ao atualizar</p>
                                </div> */}
                            </div>
                        </div>
                        <div className='item-option'>
                            <label className='filter-control-label'>
                                Meus indicadores
                                <Tooltip 
                                    title='Indicadores pessoais são filtros salvos para um relatório específico que podem ser usados a qualquer momento, independentemente da sessão.'
                                    followCursor={true}
                                    arrow
                                >
                                    <i className='mdi mdi-information-outline' />
                                </Tooltip>
                            </label>
                            <Select
                                components={{ Option }}
                                closeMenuOnSelect={true}
                                controlShouldRenderValue={true}
                                placeholder="Selecione seu filtro"
                                noOptionsMessage={() => "Nenhum filtro salvo"}
                                options={dataBookmark.map((item) => ({label: item.nome, value: item.state, id: item.id}))} 
                                value={[...dataBookmark.map((item) => ({label: item.nome, value: item.state, id: item.id}))].find(item => item.value === bookmarkActive)}
                                menuPosition='fixed'
                                onChange={(item) => {
                                    if(item) {
                                        setBookmarkApplied(item)
                                    }
                                }}
                                styles={{
                                    option: (provided, { isFocused }) => ({
                                      ...provided,
                                      backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                                      color: "#333",
                                      display: "flex",
                                      alignItems: "center",
                                    }),
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        height: 32,
                                        fontSize: 12,
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        height: 32,
                                        minHeight: 32,
                                    }),
                                    indicatorsContainer: (provided) => ({
                                        ...provided,
                                        height: 32,
                                    }),
                                }}
                            />
                            {
                                bookmarkApplied &&
                                    <div className='bookmark-active-options'>
                                        <div className='bookmark-active-name'>
                                            <p className='bookmark-active-label'>{`Indicador: ${bookmarkApplied.label}`}</p>
                                        </div>
                                        <div className='bookmark-active-actions'>
                                            <div className='bookmark-actions-left'>
                                                <Tooltip title="Aplicar esse Indicador pessoal">
                                                    <i className='mdi mdi-check' onClick={() => applyBookmark(bookmarkApplied)} />
                                                </Tooltip>
                                                <Tooltip title="Atribuir filtros atuais a este Indicador">
                                                    <i className='mdi mdi-upload' onClick={() => updateBookmark(bookmarkApplied)} />
                                                </Tooltip>
                                                <Tooltip title="Não aplicar esse Indicador pessoal">
                                                    <i className='mdi mdi-close' onClick={() => setBookmarkApplied(null)} />
                                                </Tooltip>
                                            </div>
                                            <div className='bookmark-actions-right'>
                                                <Tooltip title="Excluir esse Indicador pessoal" onClick={() => deleteBookmark(bookmarkApplied.id)}>
                                                    <i className='mdi mdi-delete' />
                                                </Tooltip>    
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                        {
                            isCreateBookmark &&
                                <div className='item-option'>
                                    <label className='filter-control-label'>Salvar novo indicardor</label>
                                    <div className='create-bookmark-option'>
                                        <input onChange={e => setNewBookmarkName(e.target.value)} value={newBookmarkName} />
                                        <div className='btn-mini clear'>
                                            <i className='mdi mdi-close' onClick={() => setIsCreateBookmark(false)} />
                                        </div>
                                        <div className={`btn-mini ${newBookmarkName.length > 0 ? 'accept' : 'not-accept'}`} onClick={ newBookmarkName.length > 0 ? () => createBookmark() : () => {}}>
                                            <i className='mdi mdi-check' />
                                        </div>
                                    </div>
                                </div>
                        }
                        <div className='btns-list'>
                            <div className='stater-locale'> 
                                <div 
                                    className={`btn-item ${hasFilter ? 'clear' : 'not-clean'}`}
                                    onClick={async () => {
                                        await removeBookmarkInCache();
                                        setTimeout(() => {
                                            handleRemoveFilters();
                                        }, 500);
                                    }}    
                                >
                                    <i className='mdi mdi-filter-remove' />
                                    <p className='btn-word'>Limpar filtros</p>
                                </div>
                            </div>
                            <div className='end-locale'>
                                <div className='btn-item accept' onClick={() => setIsCreateBookmark(true)}>
                                    <i className='mdi mdi-bookmark' />
                                    <p className='btn-word'>Guarda novo indicardor</p>
                                </div>
                                <button className='btn-item accept' 
                                disabled={isInitialLoad || !hasChanges}
                                onClick={() => updateFiltersConfig()}>
                                    <i className='mdi mdi-check' />
                                    <p className='btn-word'>Salvar alterações</p>
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
        </Paper>
      </Menu>
    )
}

export default FilterControl;