import './style.scss'
import React, { useCallback, useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import NotFound from './not-found'
import SignatureForm from './form'
import api from 'services/api/api'
import Swal from "sweetalert2"
import { useTranslation } from 'react-i18next';

export default function ModalSignatureReport({onClose, idReport}) {

    const [allUsers, setAllUsers] = useState([])
    const [signatures, setSignatures] = useState([])
    const [signatureExpanded, setSignatureExpanded] = useState('')
    const [isLockOtherEmails, setIsLockOthersEmails] = useState(true);
    const [reportHasRLS, setReportHasRLS] = useState(true);
    const [rlsRules, setRlsRules] = useState([]);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true)

    async function PromisseAllRequests() {
        try {
            const promises = [handleRequestAllUsers(), handleRequestSignatures(), handleRequestRls(idReport)]
            await Promise.all(promises)
            setIsLoading(false)
        } catch (error) {
            console.log('Erro ao buscar dados', error)
            setIsLoading(false)
        }
    }


    async function handleRequestSignatures() {
        const { data } = await api.post("/schedules", {
            "id_relatorio": idReport
        });
    
        if (data.envio_emails_externo) {
            setIsLockOthersEmails(data.envio_emails_externo === 1 ? false : true);
        }
    
        if (!data.schedules) {
            return;
        }
    
        const signaturesWithId = Object.keys(data.schedules).map((id, index) => {
            const signature = data.schedules[id];
    
            // Verificar se othersEmails existe e é um array antes de mapeá-lo
            const updatedOthersEmails = signature.othersEmails && Array.isArray(signature.othersEmails)
                ? signature.othersEmails.map(othersEmailItem => {
                    const rlsRoleArray = typeof othersEmailItem.rls_role === 'string' 
                        ? othersEmailItem.rls_role.split(',').map(role => Number(role.trim()))
                        : (Array.isArray(othersEmailItem.rls_role) ? othersEmailItem.rls_role.map(role => Number(role)) : []);
                    
                    return {
                        ...othersEmailItem,
                        rls: rlsRoleArray
                    };
                })
                : [];
    
            return {
                ...signature,
                id_agendamento: Number(id),
                othersEmails: updatedOthersEmails
            };
        });
    
        setSignatures(signaturesWithId);
    }         

    const handleRequestAllUsers = useCallback(async () => {
        const { data } = await api.post('/usersSelectOptions');
        setAllUsers(data.usuarios);
    }, []);

    const handleRequestRls = useCallback(async (id_relatorio) => {
        try {
            const res = await api.post("/rls", {"id_relatorio": id_relatorio});   
            setReportHasRLS(res.data.regras_rls ? (res.data.regras_rls.regras_rls && res.data.regras_rls.regras_rls.length > 0) ? true : false : false)    
            if(res.data.regras_rls.regras_rls && res.data.regras_rls.regras_rls.length > 0) {
                const freeRLS = {id: 0, nome: 'Liberado de RLS', usuarios: res.data.regras_rls.usuariosLiberadosRLS || [], grupos: res.data.regras_rls.gruposLiberadosRLS}
                setRlsRules([freeRLS, ...res.data.regras_rls.regras_rls])
            }
        } catch (error) {
            return error.message
        }
      }, [])

    const handleCreateNewSignature = useCallback(() => {
        
        let allSignatures = [...signatures];     
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        const todayFormatted = today.toISOString().split('T')[0];
        const tomorrowFormatted = tomorrow.toISOString().split('T')[0];

        const newSignature = {
            descricao: "Nova assinatura",
            assunto_email: "",
            mensagem_email: '',
            repetir: 'Diariamente',
            dia_semana: '',
            dia_mes: '',
            hora_agendada: "10:00:00",
            data_inicio: todayFormatted,
            data_final: tomorrowFormatted,
            usuarios: [],
            othersEmails: [],
            tipo: 'PDF',
            rlsActive: 0,
            nome_pagina: '',
            nome_preview: '',
            reportPreview: 0,
            usersFilters: 0,
            codeBookmarkFilters: '',
        };

        setSignatureExpanded(newSignature)
        allSignatures.push(newSignature);    
        setSignatures(allSignatures);

    }, [signatures, setSignatures]);
      
    const handleRemoveSignature = useCallback(async (key) => {
        let allSignatures = [...signatures];
        const removedSignature = allSignatures.splice(key, 1)[0];
        setSignatures(allSignatures);
      
        if (removedSignature.id_agendamento !== undefined) {
          try {
            await api.post('/schedulesDisable', {
                "id_agendamento": removedSignature.id_agendamento, "status":2
            }) 
          } catch (error) {
            console.log('Erro ao excluir item', error)
          }
        }

    }, [signatures, setSignatures]);
      
    const handleUpdateSignature = useCallback((key, field, newValue) => {

        setSignatures((prevState) => {
            const updatedSignatures = [...prevState];
            updatedSignatures[key][field] = newValue;
            return updatedSignatures;
        });

    }, [setSignatures]);

    async function handleSendInstantSignature(id_signature) {
        try {
            await api.post('/schedulesStart', {
                "id_agendamento": id_signature
            })
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Sucesso, sua assinatura será enviada logo menos!",
                showConfirmButton: false,
                timer: 5000
                });
        } catch (error) {
            Swal.fire(
                "Oops, erro!",
                "Não foi possível, tente novamente!",
                "error"
            );
        }
    }

    async function handleSubmitSignatures() {
        if (signatures.length === 0) {
          Swal.fire(
            "Oops, erro!",
            "Não foi possível, tente novamente!",
            "error"
          );
          return;
        }
      
        try {
            const requests = signatures.map(async (signature) => {
                const updatedSignature = {
                    ...signature,
                    id_relatorio: Number(idReport),
                    tipo: (signature && signature.tipo !== undefined) ? signature.tipo : 'PDF',
                    assunto_email: (signature && signature.assunto_email) ? signature.assunto_email : '',
                    mensagem_email: (signature && signature.mensagem_email) ? signature.mensagem_email : ''
                };

                if (updatedSignature.id_agendamento !== undefined) {
                    return await api.post('/schedulesUpdate', updatedSignature);
                } else {
                    return await api.post('/schedulesCreate', updatedSignature);
                }
                
              })
      
          await Promise.all(requests);
          setSignatures([])
          PromisseAllRequests()
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Alterações salvas com sucesso!",
                showConfirmButton: false,
                timer: 5000
            });
        } catch (error) {
            Swal.fire(
                "Oops, erro!",
                "Não foi possível, tente novamente!",
                "error"
            );
        }finally{
            setHasUnsavedChanges(false);
        }
    }      

    function handleExpandedSignature(item) {
        if(signatureExpanded === item) {
            setSignatureExpanded('')
        } else {
            setSignatureExpanded(item)
        }
    }

    useEffect(() => { PromisseAllRequests() }, [])

    return (
        <AnimatePresence>
            <motion.div 
                className='modal-signature-report-container'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: .5, ease: 'easeOut' } }}
                exit={{ opacity: 0 }}
            >
                <div className='modal-signature-report-content'>
                    <div className='modal-signature-report-content-close-area'>
                        <i className='mdi mdi-window-close' onClick={onClose} />
                    </div>
                    <div className='modal-signature-report-content-header'>
                        <p className='signature-header-title'>{t('signature.title')}</p>
                        <p className='signature-header-subtitle'>{t('signature.description')}</p>
                    </div>
                        <div className='modal-signature-report-content-items'>
                            {
                            allUsers.length > 0 && signatures && signatures.length > 0
                            ? 
                                signatures.map((item, key) => {
                                    return <SignatureForm
                                                item={item} 
                                                key={key} 
                                                allUsers={allUsers}
                                                expandend={signatureExpanded === item}
                                                setExpandend={(item) => handleExpandedSignature(item)}
                                                handleUpdateSignature={(field, newValue) => handleUpdateSignature(key, field, newValue)}
                                                handleRemoveSignature={() => handleRemoveSignature(key)}
                                                handleSendInstantSignature={(id_signature) => handleSendInstantSignature(id_signature)}
                                                
                                                reportHasRLS={reportHasRLS}
                                                rlsRules={rlsRules}
                                                setHasUnsavedChanges={setHasUnsavedChanges}
                                                isLockOtherEmails={isLockOtherEmails}
                                            />
                                })
                            : 
                                <NotFound createNew={handleCreateNewSignature} isLoading={isLoading} t={t} />     
                        }
                    </div>
                    {allUsers.length > 0 && signatures && signatures.length > 0 &&
                        <div className='modal-signature-footer'>
                            <button className={`signature-field-btn-sucess ${!hasUnsavedChanges ? 'disabled' : ''}`} onClick={handleSubmitSignatures} disabled={!hasUnsavedChanges}>
                                {t('signature.buttons.save')}
                            </button>
                            <button className='signature-field-btn-default' onClick={handleCreateNewSignature}>
                                <i className='mdi mdi-plus' />
                                {t('signature.buttons.new')}
                            </button>
                        </div>
                    }
                </div>
            </motion.div>
        </AnimatePresence>
    )
}