import './style.css';
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SelectUser from './selectUser';
import RenderReport from './renderReport';

export default function ModalTestingRls({ close, regras, data, embeddedData, users }) {

  const [user, setUser] = useState('Squad BI');
  const [isUserIdPositive, setIsUserIdPositive] = useState(true);

  useEffect(() => {
    setIsUserIdPositive(user !== '');
  }, [user]);

  return (
    <AnimatePresence>
      <motion.main
        className='main-modal-testing-rls'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: .5, ease: 'easeOut' } }}
        exit={{ opacity: 0 }}
      >
        <div
          className={`content-modal-testing-rls ${isUserIdPositive ? 'expanded' : ''}`}
        >
          {/* {user ? (
            <SelectUser 
              regras={regras}
              data={data} 
              close={close} 
              clickUser={(item) => setUser(item)} 
              embeddedData={embeddedData}
              users={users}
            />
          ) : ( */}
            <RenderReport
              embeddedData={embeddedData}   
              rls_function={data.nome}
              userEmail={user}
              close={close} 
            />
          {/* )} */}
        </div>
      </motion.main>
    </AnimatePresence>
  );
}
