import CryptoJS from 'crypto-js';

const APP_KEY = "$QU@4D-B!"

function EncryptData(value) {
    var hash = CryptoJS.AES.encrypt(value, APP_KEY).toString();
    return hash.toString(CryptoJS.enc.Base64)
}

function DecryptData(hash) {
    var bytes = CryptoJS.AES.decrypt(hash, APP_KEY)
    return bytes.toString(CryptoJS.enc.Utf8)
}

export {EncryptData, DecryptData};