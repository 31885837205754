import './styles.scss'
import React from 'react'
import SquadIaDfeault from '../squadIADefault'

export default function ModalSquadAI() {
    return (
        <main className="modal-squadia">
            <div className='modal-squadia-content'>
                <SquadIaDfeault modal />
            </div>
        </main>
    )
}