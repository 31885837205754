import './style.css';
import React, { useState } from 'react';
import * as Icon from '@icon-park/react';
import miniLogo from '../../../assets/images/mini_icon.png';
import { useContext } from 'react';
import { AuthContext } from 'contexts/appProvider';

export default function BusinessAreaPreview({item}) {

    const {colorDefault, dataAppearance} = useContext(AuthContext);
    const [activeItem, setActiveItem] = useState(true);
    const [isMinimize, setIsMinimize] = useState(false);
    const switchSize = () => setIsMinimize(!isMinimize);

    return (
        <main className='business-area-preview-container'>
            <div className={isMinimize ? 'content-preview-area-minimize' : 'content-preview-area'} style={{background: colorDefault}}>

                <div className={isMinimize ? 'preview-logo-minimize' :'preview-logo'}>
                    <img 
                        id={isMinimize ? '' : 'logoNavbar'}
                        style={{width: isMinimize ? '35px' : '140px', height: isMinimize ? '35px' : '35px', objectFit: "scale-down"}}
                        src={isMinimize ? miniLogo : dataAppearance.logo} 
                        alt="logo" 
                    />
                    <i className={`mdi mdi-menu-${isMinimize ? 'right' : 'left'} group-icon`} style={{cursor: 'pointer'}} onClick={switchSize} />
                </div>

                {isMinimize 
                    ? <MinimizePreview activeItem={activeItem} setActiveItem={setActiveItem} item={item} /> 
                    : <MaximizePreview activeItem={activeItem} setActiveItem={setActiveItem} item={item} />
                }
            </div>
        </main>
    )
}

const MaximizePreview = ({activeItem, setActiveItem, item}) => {
    return (
        <div>
            {/*   ~~>  Item Dashboard <~~   */}
            <span className='item-container-preview'>
                    <div className='item-container-preview-top'>
                        <span className='item-connection-content'>
                            <div className='connection-content-top' />
                            <div className='connection-content-bottom'><i className='divider-preview' /></div>
                        </span>
                        <div className='item-container-preview-icon'>
                            {React.createElement(Icon.CubeFour, { theme: "outline", size: 14, fill: "#eee" })}
                        </div>
                        <div className='item-container-preview-top-info'>
                            <p className='item-name'>Página inicial</p>
                        </div>
                    </div>
                </span>

                {/*   ~~>  Item Dinamic <~~   */}
                <span className='item-container-preview'>
                    <div className='item-container-preview-top' onClick={() => setActiveItem(!activeItem)}>
                        <span className='item-connection-content'>
                            <div className='connection-content-top' ><i className='divider-preview' /></div>
                            <div className='connection-content-bottom'><i className='divider-preview' /></div>
                        </span>
                        <div className='item-container-preview-icon'>
                            {item.icon
                                ? React.createElement(Icon[item.icon], { theme: "outline", size: 14, fill: "#eee" }) 
                                : <Icon.AddFour theme="multi-color" size="14" fill="#eee" />
                            }
                        </div>
                        <div className='item-container-preview-top-info'>
                            <p className='item-name'>{item.name || 'Insira um nome'}</p>
                        </div>
                        <div className='item-container-preview-top-down'>
                            {activeItem 
                                ? <i className="mdi mdi-menu-up group-icon" />
                                : <i className="mdi mdi-menu-down group-icon" />
                            }
                        </div>
                    </div>
                    {activeItem &&
                    <>
                        <div className='item-container-preview-bottom'>
                            <span className='item-connection-content'>
                                <div className='connection-content-top' ><i className='divider-preview' /></div>
                                <div className='connection-content-bottom'><i className='divider-preview' /></div>
                            </span>
                            <div className='item-preview-bottom-info'>
                                <i className='mdi mdi-access-point' style={{color: '#bbb', fontSize: '12px'}} />
                                <p className='item-name'>Relátorio 1</p>
                            </div>
                        </div>

                        <div className='item-container-preview-bottom'>
                            <span className='item-connection-content'>
                                <div className='connection-content-top' ><i className='divider-preview' /></div>
                                <div className='connection-content-bottom'><i className='divider-preview' /></div>
                            </span>
                            <div className='item-preview-bottom-info'>
                                <i className='mdi mdi-access-point' style={{color: '#bbb', fontSize: '12px'}} />
                                <p className='item-name'>Relátorio 2</p>
                            </div>
                        </div>

                        <div className='item-container-preview-bottom'>
                            <span className='item-connection-content'>
                                <div className='connection-content-top' ><i className='divider-preview' /></div>
                                <div className='connection-content-bottom'/>
                            </span>
                            <div className='item-preview-bottom-info'>
                                <i className='mdi mdi-access-point' style={{color: '#bbb', fontSize: '12px'}} />
                                <p className='item-name'>Relátorio 3</p>
                            </div>
                        </div>
                        
                    </>
                    }
                </span>

                {/*   ~~>  Item Random <~~   */}
                <span className='item-container-preview'>
                    <div className='item-container-preview-top'>
                        <span className='item-connection-content'>
                            <div className='connection-content-top'><i className='divider-preview' /></div>
                            <div className='connection-content-bottom' />
                        </span>
                        <div className='item-container-preview-icon'>
                            {React.createElement(Icon.AreaMap, { theme: "outline", size: 14, fill: "#eee" })}
                        </div>
                        <div className='item-container-preview-top-info'>
                            <p className='item-name'>Clientes</p>
                        </div>
                        <div className='item-container-preview-top-down'>
                            <i className="mdi mdi-menu-down group-icon" />
                        </div>
                    </div>
                </span>
        </div>
    )
}

const MinimizePreview = ({activeItem, setActiveItem, item}) => {
    return (
        <div>
            <section className='item-container-preview-minimize'>
                {React.createElement(Icon.CubeFour, { theme: "outline", size: 18, fill: "#eee" })}
            </section>
            <section className='item-container-preview-minimize'>
                {item.icon
                    ? React.createElement(Icon[item.icon], { theme: "outline", size: 18, fill: "#eee" }) 
                    : <Icon.AddFour theme="multi-color" size="18" fill="#eee" />
                }
            </section>
            <section className='item-container-preview-minimize'>
                {React.createElement(Icon.AreaMap, { theme: "outline", size: 18, fill: "#eee" })}
            </section>
        </div>
    )
}