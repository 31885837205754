import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import ptLanguage from './locales/pt.json'
import enLanguage from './locales/en.json'
import esLanguage from './locales/es.json'

import { storage } from "utils";

const resources = {
    pt: {
        translation: ptLanguage
    },
    en: {
      translation: enLanguage
    },
    es: {
      translation: esLanguage
    }
};

const initLanguagem = storage.get('@language')

i18n
    .use(initReactI18next)
    .init({
        lng: initLanguagem ? initLanguagem : "pt", // Liguagem inicial do SquadBI
        fallbackLng: "en",
        resources,
        interpolation: {
            escapeValue: false
        }
    })

export default i18n