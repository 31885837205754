import './styles.scss'
import React, { useEffect, useState } from 'react'
import Image from '../../../../assets/images/mobile.jpeg'
import { QRCodeSVG } from 'qrcode.react';

export default function Mobile({onRender, onClose}) {

    const [tab, setTab] = useState(0) // 0 = default, 1 = android, 2 = ios

    const playStoreUrl = "https://play.google.com/store/apps/details?id=com.squadbi";
    const appStoreUrl = "https://apps.apple.com/br/app/squadbi/id6636467946"

    useEffect(() => {
        if (onRender) {
            onRender();
        }
    }, [onRender]);

    return (
        <div className='mobile-alert-container'>
            <div className='mobile-alert-content'>
                <div className='left-part'>
                    {tab === 0 ? 
                        <>
                            <h1>SquadBI Mobile</h1>
                            <p>
                                O aplicativo oficial da nossa plataforma já está disponível para download na App Store e Google Play Store!                        
                            </p>
                            <p>
                                Agora você pode acessar seus relatórios de maneira mais prática e eficiente diretamente do seu dispositivo móvel. Com o nosso aplicativo, você terá acesso rápido a todas as funcionalidades essenciais.
                            </p>
                            <div className='store-buttons-area'>
                                <div className='store-button' onClick={() => setTab(1)}>
                                    <i className='mdi mdi-google-play' />
                                    <p>Google Play</p>
                                </div>
                                <div className='store-button' onClick={() => setTab(2)}>
                                    <i className='mdi mdi-apple' />
                                    <p>App Store</p>
                                </div>
                            </div>
                            <div className='store-buttons-area'>
                                <div className='store-button full' onClick={() => onClose()}>
                                    <i className='mdi mdi-laptop-chromebook' />
                                    <p>Continuar navegando</p>
                                </div>
                            </div>
                        </>
                        : tab === 1 ? 
                        <>
                            <div className='qr-code-area'>
                                <QRCodeSVG 
                                    value={playStoreUrl}
                                    style={{width: '100%', height: '100%', marginBottom: 30}}
                                />
                                <div className='store-buttons-area'>
                                    <div className='store-button full' onClick={() => setTab(0)}>
                                        <i className='mdi mdi-subdirectory-arrow-left' />
                                        <p>Voltar</p>
                                    </div>
                                </div>
                            </div>
                        </>
                        : 
                        <>
                            <div className='qr-code-area'>
                                <QRCodeSVG 
                                    value={appStoreUrl}
                                    style={{width: '100%', height: '100%', marginBottom: 30}}
                                />
                                <div className='store-buttons-area'>
                                    <div className='store-button full' onClick={() => setTab(0)}>
                                        <i className='mdi mdi-subdirectory-arrow-left' />
                                        <p>Voltar</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className='right-part'>
                    <img src={Image} />
                </div>
            </div>
        </div>
    )
}