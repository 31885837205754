import Cookies from "js-cookie";
import { AllCookies, storage } from "utils";

export const CHAVE_TOKEN = "@tijuca-token";
export const CHAVE_USUARIO = "@tijuca-usuario";
export const CHAVE_COLOR_FONT_SIDE_BAR = "colorFontSidebarDefault";
export const CHAVE_COLOR__SIDE_BAR = "colorDefault";
export const DATA_APPEARANCE = "dataAppearance";
export const REPORTS_ACCESS = "@reportsAccess";
export const MENU_REPORT_SELECTED = "@menuSelectedRelatorio";
export const PATH_ATUAL = "@pathAtual";
export const OPEN_MENU = "@openMenu";
export const WEB_VIEWER_CHAVE = "hash_web_viewer_key"
export const USER_HISTORY = "@user_history"
export const CHAVE_LOGGEDIN = "@logged_in"


// Verifica se o usuario está logado
export const estaAutenticado = () => {

  const token = storage.get(CHAVE_TOKEN);

  if (token === null) {
      deslogar();
      return false;
  }

  const loginKeycloak = storage.get('@loginKeycloak');
  if (loginKeycloak && loginKeycloak == '1') {
      return true;
  }
  
  const loggedInDate = new Date(storage.get(CHAVE_LOGGEDIN));
  const now = new Date();
  const diffInMilliseconds = now - loggedInDate;
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  
  if (diffInHours >= 48) {
      deslogar();
      return false;
  }

  return true;
};

storage.set('@loginKeycloak',1)

// Verifica se o usuário que visualizar apenas no web viewer
export const aceitaWebViewer = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith(WEB_VIEWER_CHAVE)) {
      return true;
    }
  }

  return false;
};

// Verifica se o usuario está logado
export const tipoUsuario = () =>
  storage.get(CHAVE_USUARIO).tipo;

export const primeiroAcesso = () => false 
  // storage.get(CHAVE_USUARIO).primeiro_acesso;
// Pega o Token do usuario
export const pegarToken = () => storage.get(CHAVE_TOKEN);

// Pega os dados do usuario
export const pegarUsuario = () =>
  storage.get(CHAVE_USUARIO);

// Salva os dados iniciais do usuario
export const logar = (token, usuario) => {
  storage.set(CHAVE_LOGGEDIN, new Date());
  storage.set(CHAVE_TOKEN, token);
  storage.set(CHAVE_USUARIO, usuario);
};

// Desloga o usuario
export const deslogar = () => {
  storage.remove(CHAVE_LOGGEDIN);
  storage.remove(CHAVE_TOKEN);
  storage.remove(CHAVE_USUARIO);
  // storage.remove(CHAVE_COLOR__SIDE_BAR);
  // storage.remove(CHAVE_COLOR_FONT_SIDE_BAR);
  storage.remove(DATA_APPEARANCE);
  storage.remove(REPORTS_ACCESS);
  storage.remove(PATH_ATUAL);
  storage.remove(MENU_REPORT_SELECTED);
  storage.remove(OPEN_MENU);
  storage.remove(USER_HISTORY);
  storage.remove('@primeiroAcesso')
};
