import storage from './storage';    
import { maskTelefone } from './mask';
import { EncryptData, DecryptData } from './encrypt';
import AllCookies from './allCookies'

export {
    storage,
    maskTelefone,
    EncryptData,
    DecryptData,
    AllCookies
}