import { useTranslation } from 'react-i18next';

const useGrupoData = () => {
  const { t } = useTranslation();

  const data = [
    [
      `1-${t('sidebar.groups.index')}`,
      "Peoples"
    ],
    [
      {
          "id": "1112",
          "nome": `${t('sidebar.groups.userGroup')}`,
          "rota": "/pages/admin/grupoUsuarios",
          "icon": "mdi mdi-account-multiple-plus"
      },
      {
          "id": "122222002",
          "nome": `${t('sidebar.groups.businessGroup')}`,
          "rota": "/pages/admin/areasDeNegocios",
          "icon": "mdi mdi-chart-line"
      },
      {
          "id": "123222200202020",
          "nome": `${t('sidebar.groups.subGroup')}`,
          "rota": "/pages/admin/subAreasNegocio",
          "icon": "mdi mdi-chart-line"
      }
    ]
  ];

  return data;
};

export default useGrupoData;