import React from "react";
import './style.css';

export const styles = {
  headCells: {
      style: {
          fontWeight:'bold',
          fontSize:'13px',
      },
  },
  cells: {
    style: {
      fontSize: '12px'
    }
  }

};

export const NoData = () => {
  return (
    <div className="containerLoaderDataTable">
    <span id="empty-list-text">{'Nenhum resultado encontrado, adicione um novo registro.'}</span>
  </div>
  )
}
