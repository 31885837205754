import React from "react";
import { components } from "react-select";
import { BookmarkOne, Delete } from "@icon-park/react";
import { Tooltip } from "@mui/material";

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div style={{height: 16, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{height: 16, display: 'flex', alignItems: 'center', gap: 10}}>
            <div style={{height: 16, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              {!props.isSelected ? 
                <BookmarkOne theme="outline" size="12" fill="#333"/>
                :
                <BookmarkOne theme="filled" size="12" fill="#333"/>  
              }
            </div>
            <div style={{height: 16, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <label style={{all: "unset", fontSize: 14, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.label}</label>
              <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>{props.area}</label>

              
              {
                props.data.email && 
                <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>{props.data.email}</label>
              }
              {
                props.data.group && 
                <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>Grupo de usuários</label>
              }
              {
                props.data.area && 
                <label style={{all: "unset", fontSize: 12, marginLeft: 5, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', color: '#444'}}>{props.data.area}</label>
              }
            </div>
          </div>
        </div>
      </components.Option>
    </div>
  );
};