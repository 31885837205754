import React, { useState,useEffect,useContext, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import miniLogo from '../../assets/images/mini_icon.png';
import tijucaLogo from '../../assets/images/logo-branca.png';
import { pegarUsuario, deslogar } from '../../services/api/autenticacao';
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import api from 'services/api/api';
import { useHistory } from 'react-router-dom';
import { AuthContext } from 'contexts/appProvider';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import RenderConditional from 'app/components/renderConditional';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ModalExportPowerPoint, SearchBar, Spinner } from 'app/components';
import powerpointImage from '../../assets/images/powerpoint.png'
import pdfImage from '../../assets/images/pdf-image.png'
import webImage from '../../assets/images/web.png'
import ModalSignatureReport from 'app/components/modalSignatureReport';
import { ReportsContext } from 'contexts/reportsContext';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Android, BackgroundColor, EditName, Modify, Power, Robot, Shield, Tool } from '@icon-park/react';
import ModalDownloadApp from 'app/components/modalDonwloadApp';
import { MenuList, Paper } from '@mui/material';
import { storage } from 'utils';
import axios from 'axios';
import Select from 'react-select';
import Fabric from '../../assets/images/Microsoft_Fabric_2023.svg'
import { version } from '../../../package.json'
import FilterControl from 'app/components/filterControl';
import  './styles.scss'
import { useTranslation } from 'react-i18next';
import gpt from '../../assets/images/squadia.png'
import { PlayCycle } from '@icon-park/react';
import Mobile from 'app/components/alerts/mobile';
import ContractExpired from 'app/components/alerts/contractExpired';
import ModalSignatureScorecard from 'app/components/modalSignatureScorecard';
import { ModalDatasetError } from 'app/components/modalDatasetError';
import AlertAi from 'app/components/alerts/AlertAi';

export default function Navbar() {
const {t} = useTranslation();
const {userData,colorDefault,openMenu,onToggleMenu,generatePdfEmbed,fullScreenEmbed,reloadEmbed,msalInstance,captureBookmarksEmbed,apllybookmarkState,idCurrentReport,downloadPBIX,handleRemoveFilters, reloadRequestEmbedded, onRefreshUserPermissionsAccess, hasFilter, findFilters, applyVerifyRls, setActiveIA,  setOpenMenu, showAlertsId, reloadRefreshDate, expiredContract, setExpiredContract} = useContext(AuthContext);
const { handlePrepareExportFile, exportingReport, handleExportReport, downloandReport, sendUpdateReport, updatingReport } = useContext(ReportsContext)
const [usuario,setUsuario] = useState('Aguarde...');
const [nome,setNome] = useState('Aguarde...');
const [currentReport, setCurrentReport] = useState({});
const [fullscreen,setFullscreen] = useState(false);
const [openSettings,setOpenSettings] = useState(true);
const [reload, setReload] = useState(true)
const [lastRefresh, setLastRefresh] = useState(null)
const [lastRefreshError, setLastRefreshError] = useState(null)
const [updatingDataset, setUpdatingDataset] = useState(false)
const [isLoadingRefresh, setIsLoadingRefresh] = useState(true)
const [showDatasetError, setShowDatasetError] = useState(false)
const path = window.location.href

useEffect(() => {
  pegarDados();
}, [])

  async function pegarDados() {
    const user = await pegarUsuario();

    if (user){
      setUsuario(user);
      setNome(user.nome);
    } 
    
  }
 function toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  function toggleFullScreen() {

    var tela = document.documentElement;

    if ((document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (tela.requestFullScreen) {
        tela.requestFullScreen();
        setFullscreen(true);
      } else if (tela.mozRequestFullScreen) {
        tela.mozRequestFullScreen();
        setFullscreen(true);
      } else if (tela.webkitRequestFullScreen) {
        tela.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        setFullscreen(true);
      }
    } else {
      if (document.cancelFullScreen) {
          document.cancelFullScreen();
          setFullscreen(true);
      } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
          setFullscreen(true);
      } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
          setFullscreen(true);
      }
    }
  }

  function formatarData(data) {
    const partesData = data.split(' ');
    const dataPartes = partesData[0].split('/');
    const horaPartes = partesData[1].split(':');

    const dia = dataPartes[0];
    const mes = dataPartes[1];
    const ano = dataPartes[2];
    const hora = horaPartes[0];
    const minutos = horaPartes[1];


    return `${t('recentsCard.updated')} <strong>${dia}/${mes}/${ano} ${t('recentsCard.at')} ${hora}:${minutos}</strong>`;
  }

  function formatarData2(data) {
    const partesData = data.split(' ');
    const dataPartes = partesData[0].split('/');
    const horaPartes = partesData[1].split(':');

    const dia = dataPartes[0];
    const mes = dataPartes[1];
    const ano = dataPartes[2];
    const hora = horaPartes[0];
    const minutos = horaPartes[1];


    return `${t('recentsCard.published')} em <strong>${dia}/${mes}/${ano} ${t('recentsCard.at')} ${hora}:${minutos}</strong>`;
  }

  useEffect(() => {
    findCurrentReport()
  },[idCurrentReport, reload, path, reloadRefreshDate])

  function findCurrentReport(){
    let reports = []
    if(userData){
      const telas = Object.values(userData.telas)
      const relatorios = Object.values(userData.relatorios)
  
      if(Array.isArray(telas)){
        if(telas.length){
          for (const tela of telas[0]) {
            reports.push(tela)
          }
        }
      }
  
      if(Array.isArray(relatorios)){
        if(relatorios.length){
          for (const relatorio of relatorios) {
              if(Array.isArray(relatorio)){
                if(relatorio.length){
                  reports.push(...relatorio)
                }
              }
            }
          }
        }

        const item = reports.find(report => report.embedded_relatorio == idCurrentReport)

        setCurrentReport(item)

        if(item) {
          getLastRefresh(item.embedded_relatorio, item.embedded_workspace)
        }
    }

  }

  async function getLastRefresh(embedded_report, embedded_workspace) {
    try {
      const idReportActual = window.location.hash.split('/')[2];

      if(idReportActual && idReportActual.trim() !== embedded_report.trim()) {
        setLastRefreshError(null)
        return;  
      }

      setLastRefreshError(null)
      setIsLoadingRefresh(true)
      const info = await api.post('/reportsInfo-v2', {"nome": 'Create Workspace'})
      const config = {
          headers: { 'Authorization': 'Bearer ' + info.data.token},
      };
      const first = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${embedded_workspace}/reports/${embedded_report}`, config)
      const second = await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${embedded_workspace}/datasets/${first.data.datasetId}/refreshes?$top=2`, config)
      setIsLoadingRefresh(false)
      if(second.data.value[0].endTime) {
        let item = second.data.value[0];
        setLastRefresh(item.endTime)
        if(item.status === "Failed") {
          setLastRefreshError(item.serviceExceptionJson)
        }
        setUpdatingDataset(false)
      } else if(second.data.value[1].endTime) {
        let item = second.data.value[1];
        setLastRefresh(item.endTime)
        if(item.status === "Failed") {
          setLastRefreshError(item.serviceExceptionJson)
        }
        setUpdatingDataset(false)
      } 
      else if(second.data.value[0].startTime) {
        setUpdatingDataset(true)
      } else {
        setUpdatingDataset(false)
      }
    } catch (error) {
      return error
    }
  }

  function formatServiceDate(dataHoraString) {
    const dataHora = new Date(dataHoraString);
    const dia = ("0" + dataHora.getDate()).slice(-2);
    const mes = ("0" + (dataHora.getMonth() + 1)).slice(-2);
    const ano = dataHora.getFullYear();
    const horas = ("0" + dataHora.getHours()).slice(-2);
    const minutos = ("0" + dataHora.getMinutes()).slice(-2);
  
    return `${t('recentsCard.updated')} <strong>${dia}/${mes}/${ano} ${t('recentsCard.at')} ${horas}:${minutos}</strong>`;
  }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
    {showDatasetError && <ModalDatasetError onClose={() => setShowDatasetError(false)} message={lastRefreshError !== null ? lastRefreshError : ''} />}
    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center" id='navbar' style={{backgroundColor:colorDefault}}>
      <div className="navbar-brand brand-logo logo" to="/home" >
        <img id='logoNavbar' src={tijucaLogo} alt="logo" style={{ objectFit: "scale-down", width:'75%', marginTop:'2.5rem' }} />
      </div>
      
      <Link className="navbar-brand brand-logo-mini" to="/home"><img id='miniLogoNavbar' src={miniLogo} alt="logo" style={{objectFit: 'scale-down'}}/>
        </Link>
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-stretch" style={{display:'flex',justifyContent:'space-between'}}>
      <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => onToggleMenu(!openMenu)}>
        <span className="mdi mdi-menu"></span>
      </button>
      <div className='h-100 d-flex align-items-center' style={{width: '50%', gap: '20px'}}>
        <RenderConditional isTrue={path.includes('viewReport')}>
          <div style={{display: 'flex', gap: 10}}>
            {currentReport ? currentReport.mostrar_atualizacao != 0 && currentReport.mostrar_atualizacao == 1 && !isLoadingRefresh ? 
              lastRefreshError !== null ? 
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => setShowDatasetError(true)}>
                  <Tooltip arrow title="Ocorreu um erro ao atualizar o conjunto de dados desse relatório. Selecione o ícone para detalhes.">
                    <i style={{color: '#d83b01', fontSize: 12, cursor: 'pointer'}} className='mdi mdi-alert-outline'/>
                  </Tooltip>
                </div>
              :
                ''
              : 
                '' 
              : 
                ''
            }              
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <p style={{color: '#444', fontWeight: 'lighter', fontSize: '80%', lineHeight: 'normal'}}>
                  {currentReport ? (currentReport && currentReport.nome_exibicao && currentReport.nome_exibicao != 'null') ? currentReport.nome_exibicao : currentReport.nome : ''}
                </p>
                {currentReport ? currentReport.mostrar_atualizacao != 0 && currentReport.mostrar_atualizacao == 1 ? isLoadingRefresh ? 
                  <div className='shimmer-item-effect' />
                  :
                  <p 
                    style={{color: '#777', fontWeight: 'bold', fontSize: '70%', lineHeight: 'normal'}} 
                    dangerouslySetInnerHTML={
                      { __html: lastRefresh ? formatServiceDate(lastRefresh) : currentReport ? currentReport.atualizado ? formatarData(currentReport.atualizado) : '' : '' }
                    } 
                  />
                  : '' : ''
                }
                {currentReport ? currentReport.mostrar_atualizacao != 0 && currentReport.mostrar_atualizacao == 2 &&
                  <p 
                    style={{color: '#777', fontWeight: 'bold', fontSize: '70%', lineHeight: 'normal'}} 
                    dangerouslySetInnerHTML={
                      { __html: currentReport ? currentReport.atualizado ? formatarData2(currentReport.atualizado) : '' : '' }
                    } 
                  />
                  : ''
                }
              </div>
          </div>
        </RenderConditional>
        <RenderConditional isTrue={!path.includes('/editarRelatorio') && !path.includes('viewReport')}>
          <SearchBar placeholder={t('search.index')} data={userData} thisIsGlobalSearch />
        </RenderConditional>
      </div>
      <ul className="navbar-nav navbar-nav-right" id="menuLabelUser">
        <li className="nav-item nav-profile nav-language">
          <Dropdown alignRight>
              <MenuOptions
                usuario={nome}
                userData={userData}
                generatePdfEmbed={generatePdfEmbed}
                fullScreenEmbed={fullScreenEmbed}
                fullScreen={toggleFullScreen}
                reloadEmbed={reloadEmbed}
                msalInstance={msalInstance}
                captureBookmarksEmbed={captureBookmarksEmbed}
                apllybookmarkState={apllybookmarkState}
                idCurrentReport={idCurrentReport}
                downloadPBIX={downloadPBIX}
                handleRemoveFilters={handleRemoveFilters}
                reloadAndRefreshEmbed={reloadRequestEmbedded}
                handlePrepareExportFile={handlePrepareExportFile}
                exportingReport={exportingReport}
                handleExportReport={handleExportReport}
                downloandReport={downloandReport}
                onRefreshUserPermissionsAccess={onRefreshUserPermissionsAccess}
                onReload={() => setReload(!reload)}
                sendUpdateReport={sendUpdateReport}
                updatingReport={updatingReport}
                hasFilter={hasFilter}
                findFilters={findFilters}
                applyVerifyRls={applyVerifyRls}
                useTranslation={useTranslation}
                setActiveIA={setActiveIA}
                showAlertsId={showAlertsId}
                onToggleMenu={onToggleMenu}
                expiredContract={expiredContract}
                setExpiredContract={setExpiredContract}
               />
          </Dropdown>
        </li>
      </ul>
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={() => toggleOffcanvas()}>
        <span className="mdi mdi-menu"></span>
      </button>
    </div >
  </nav >
  )
}

const MenuOptions = ({usuario,userData,generatePdfEmbed,fullScreenEmbed,fullScreen,reloadEmbed,msalInstance,captureBookmarksEmbed,apllybookmarkState,idCurrentReport,downloadPBIX, handleRemoveFilters, reloadAndRefreshEmbed, handlePrepareExportFile, exportingReport, handleExportReport, downloandReport, onRefreshUserPermissionsAccess, onReload, sendUpdateReport, updatingReport, hasFilter, findFilters, applyVerifyRls, useTranslation, setActiveIA, onToggleMenu, showAlertsId, expiredContract, setExpiredContract}) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const [userMenu, setUserMenu] = useState(null);
  const [idiomaMenu, setIdiomaMenu] = useState(null)
  const [notificationMenu, setNotificationMenu] = useState(null)
  const [exportMenu, setExportMenu] = useState(null)
  const [settingsMenu, setSettingsMenu] = useState(null);
  const [filterControlMenu, setFilterControlMenu] = useState(null);
  const [filterMenu, setFilterMenu] = useState(null);
  const [isActivePowerPoint, setIsActivePowerPoint] = useState(false)

  const [isActiveSignature, setIsActiveSignature] = useState(false);
  const [isActiveSignatureScorecard, setIsActiveSignatureScorecard] = useState(false);

  const [lockState, setLockState] = useState(false)
  const [dataBookmarks, setDataBookmarks] = useState([]);
  const [currentReport, setCurrentReport] = useState({});
  const [isLoadingCreateNewBookmark, setIsLoadingCreateNewBookmark] = useState(false);
  const [isLoadingIsUpdateReport, setIsLoadingIsUpdateReport] = useState(false);
  const [isLoadingListNewBookmark, setIsLoadingListNewBookmark] = useState(true);
  const [isLoadingDeleteBookmark, setIsLoadingDeleteBookmark] = useState(false);
  const [isLoadingDownloadPBIX, setIsLoadingDownloadPBIX] = useState(false);
  const [newBookmarkActivity, setNewBookmarkActivity] = useState(false);
  const [newBookmarkValue, setNewBookmarkValue] = useState("");
  const [newBookmarkSelected, setNewBookmarkSelected] = useState(null);
  const [filterOptionsMenu, setFilterOptionsMenu] = useState(null);
  const [reportHasRLS, setReportHasRLS] = useState(true);
  const [isExportFilters, setIsExportFilters] = useState('') 
  const [exportAllPages, setExportAllPages] = useState(true)
  const [isExportHiddenPages, setIsExportHiddenPages] = useState(false)
  const [isActiveModalDonwloadApp, setIsActiveModalDonwloadApp] = useState(false)
  const [RLSMenu, setRLSMenu] = useState(false)
  const [regrasRLS, setRegrasRLS] = useState([])
  const [activesRLS, setActivesRLS] = useState([])
  const [modifyRLS, setModifyRLS] = useState(false)
  const [isUserRls, setIsUserRls] = useState(false)
  const [users, setUsers] = useState([])
  const [selectUserRls, setSelectUserRls] = useState(null)
  const [statusPoc, setPocStatus] = useState()
  const [diasRestantes, setDiasRestantes] = useState()
  const [assistants, setAssistants] = useState([]);

  const [openModalAi, setOpenModalAI] = useState(false)

  const [alertAiModal, setAlertAiModal] = useState(true)


  const path = window.location.href 
  const getURL = window.location.host

  useEffect(() => {
    setIsUserRls(false)
    requestAssistants()
    setSelectUserRls(null)
    requestDaysContract()
    listBookmarks()
    findCurrentReport()
    expiredAvalition()
    setAlertAiModal(true)
    setActiveIA(false)
  },[idCurrentReport, path, diasRestantes, statusPoc])

  function findCurrentReport(){
    let reports = []
    if(userData){
      const telas = Object.values(userData.telas)
      const relatorios = Object.values(userData.relatorios)
  
      if(Array.isArray(telas)){
        if(telas.length){
          for (const tela of telas[0]) {
            reports.push(tela)
          }
        }
      }
  
      if(Array.isArray(relatorios)){
        if(relatorios.length){
          for (const relatorio of relatorios) {
              if(Array.isArray(relatorio)){
                if(relatorio.length){
                  reports.push(...relatorio)
                }
              }
            }
          }
        }

        const item = reports.find(report => report.embedded_relatorio == idCurrentReport)

        setCurrentReport(item)

        const hasReportID = (idCurrentReport && reports.find(report => report.embedded_relatorio == idCurrentReport) && reports.find(report => report.embedded_relatorio == idCurrentReport).id) ? true : false

        if(hasReportID) {
          handleRequestRls(idCurrentReport && reports.find(report => report.embedded_relatorio == idCurrentReport).id)
          handleRequestUsers()
        }
    }

  }

  const handleRequestRls = useCallback(async (id_relatorio) => {
    try {
        const res = await api.post("/rls", {"id_relatorio": id_relatorio});   
        setReportHasRLS(res.data.regras_rls ? (res.data.regras_rls.regras_rls && res.data.regras_rls.regras_rls.length > 0) ? true : false : false)    
        if(res.data.regras_rls.regras_rls && res.data.regras_rls.regras_rls.length > 0) {
          setRegrasRLS(res.data.regras_rls.regras_rls)
          const filterMyRLS = res.data.regras_rls.regras_rls.filter(item => item.usuarios.includes(Number(userData.id_usuario)))
          setActivesRLS(filterMyRLS)
        }
    } catch (error) {
        return error.message
    }
  }, [])

  const handleRequestUsers = useCallback(async () => {
    try {
        const res = await api.post('/users')
        const allUsers = res.data.usuarios.filter(item => item.status === 1).map(item => ({label: item.nome, email: item.email, value: item.id_usuario, grupo_usuario: item.id_grupo_usuario}))
        setUsers(allUsers)
    } catch (error) {
          return error.message
    }
  }, [])

  //Menu Export
  const openMenuExport = Boolean(exportMenu);
  const handleClickExport = (event) => {
    setExportMenu(event.currentTarget);
  };
  const handleCloseExport = () => {
    setExportMenu(null);
  };

  //Menu Settings
  const openMenuSettings = Boolean(settingsMenu);
  const handleClickSettings = (event) => {
    setSettingsMenu(event.currentTarget);
  };
  const handleCloseSetting = () => {
    setSettingsMenu(null);
  };


  // Menu Filter Control
  const openMenuFilterControl = Boolean(filterControlMenu);
  const handleClickFilterControl = (event) => {
    setFilterControlMenu(event.currentTarget);
  };
  const handleCloseFilterControl = () => {
    setFilterControlMenu(null);
  };

    //Menu Filter
  const openMenuFilter = Boolean(filterMenu);
  const handleClickFilter = (event) => {
    setFilterMenu(event.currentTarget);
  };
  const handleCloseFilter = () => {
    setFilterMenu(null);
  };

  //Menu Filter options
  const openMenuFilterOptions = Boolean(filterOptionsMenu);
  const handleClickFilterOptions = (event) => {
    setFilterOptionsMenu(event.currentTarget);
  };
  const handleCloseFilterOptions = () => {
    setFilterOptionsMenu(null);
  };

  // Menu RLS
  const openMenuRLS = Boolean(RLSMenu);
  const handleClickRLS = (event) => {
    setRLSMenu(event.currentTarget)
  }

  const handleCloseRLS = () => {
    setRLSMenu(null)
  }

  // Menu Idioma
  const openMenuIdioma = Boolean(idiomaMenu);
  const handleClickIdioma = (event) => {
    setIdiomaMenu(event.currentTarget)
  }

  const handleCloseIdioma = () => {
    setIdiomaMenu(null)
  }

  // Menu notification
  const openMenuNotification = Boolean(notificationMenu);
  const handleClickNotification = (event) => {
    setNotificationMenu(event.currentTarget)
  }

  const handleCloseNotification = () => {
    setNotificationMenu(null)
  }

  //Menu User
  const openMenuUser = Boolean(userMenu);
  const handleClickUser = (event) => {
    setUserMenu(event.currentTarget);
  };

  const handleCloseUser = () => {
    setUserMenu(null);
  };
  
  const stylePaperMenu = {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0
      }
    }
  }

  async function addBookmarkInCache() {
    if(!lockState) {
      let history = storage.get('@user_history') || [];
      
      const existingIndex = history.findIndex((historyItem) => historyItem.embedded_relatorio === idCurrentReport);
        
      if (existingIndex !== -1) {
        const item = history[existingIndex];
          // if(token) {
          //   item.date_token = new Date();
          //   item.acess_token = token;
          //   item.tokenPowerBiApi = tokenPowerBiApi;
          // } else {
          //   item.history = new Date();
          // }
        const bookmark = await window.report.bookmarksManager.capture({allPages: true, personalizeVisuals: true});
        item.last_bookmark_state = bookmark.state;
        // item.history = new Date();
        history[existingIndex] = item;
        
        storage.set('@user_history', history);    
      }
    }
  }

  async function requestAssistants() {
      try {
          const res = await api.post('/assistants')
          if(res.data && res.data.status && res.data.assistants && res.data.assistants.length > 0) {
              
              const allAssistants = res.data.assistants
          
              const assistantsFiltred = allAssistants.filter(assistant => {

                  let userAccess = false;
                  let isAdmin = false;

                  if (assistant.usuarios) {
                      assistant.usuarios.forEach(usuario => {
                          if (usuario.id == userData.id_usuario) {
                              userAccess = true;
                              if (usuario.admin == "1") {
                                  isAdmin = true;
                              }
                          }
                      });
                  }

                  if (!userAccess && assistant.grupos) {
                      assistant.grupos.forEach(grupo => {
                          if (grupo.id == userData.grupoUsuario) {
                              userAccess = true;
                          }
                      });
                  }

                  if (userAccess) {
                      if (isAdmin) {
                          assistant.isAdmin = true;
                      }
                      return true;
                  }

                  return false;
              });

              setAssistants(assistantsFiltred);

          }
      } catch (error) {
          return error.message
      }
  }

  async function removeBookmarkInCache() {
    return new Promise(resolve => {
        if(true) {
            let history = storage.get('@user_history') || [];
            
            const existingIndex = history.findIndex((historyItem) => historyItem.embedded_relatorio === idCurrentReport);
              
            if (existingIndex !== -1) {
                const item = history[existingIndex];
                // delete item.last_bookmark_state;
                item.last_bookmark_state = undefined;
                item.history = new Date();
                history[existingIndex] = item;
                storage.set('@user_history', history);
            }
        }

        resolve();
    });
  }


  async function createNewBookMark(){
    if(!newBookmarkValue) return
    setIsLoadingCreateNewBookmark(true)
    const hashState = await captureBookmarksEmbed();

    const attrs = {
        id_relatorio: idCurrentReport,
        nome: newBookmarkValue,
        state:hashState
      }

      const id = Array.isArray(dataBookmarks) ? dataBookmarks[dataBookmarks.length - 1].id + 1 : 0

      await api.post('/bookmarksCreate',attrs)
      .then(res => {
        if(Array.isArray(dataBookmarks) ? dataBookmarks.length : false){
          setDataBookmarks(oldData => [...oldData,{id,nome:newBookmarkValue,state:hashState}])
        }else{
          setDataBookmarks([{id,nome:newBookmarkValue,state:hashState}])
        }
        listBookmarks()
        setNewBookmarkSelected(id)
      })
      .catch(err => console.log(err.message))
      .finally(() => {
        setIsLoadingCreateNewBookmark(false)
        setNewBookmarkActivity(false)
        setNewBookmarkValue("")
      })

  }

  async function listBookmarks(){
    setIsLoadingListNewBookmark(true)
    const attrs = {
      id_relatorio: idCurrentReport
     }

    await api.post('/bookmarks',attrs)
    .then(({data:{ bookmarks }}) => {
      setDataBookmarks(bookmarks)
    })
    .catch(err => console.log(err.message))
    .finally(() => setIsLoadingListNewBookmark(false))
  }

  async function updateReport(){
    addBookmarkInCache()
    setIsLoadingIsUpdateReport(true)
    const response = await sendUpdateReport(idCurrentReport)
    setIsLoadingIsUpdateReport(false)
    await onRefreshUserPermissionsAccess()
    onReload()
    if(response) {
      reloadAndRefreshEmbed();
    }
  }

  async function refreshDashboard() {
    try {
      await window.report.refresh();
      await window.report.reload();
    }
    catch (errors) {
      console.log(errors);
    }
  }

  async function deleteBookmark(){
  setIsLoadingDeleteBookmark(true)
  const attrs = {
    id_personal_bookmark: newBookmarkSelected
  }

  await api.post('/bookmarksDelete',attrs)
  .then(() => {
    if(Array.isArray(dataBookmarks) ? dataBookmarks.length : false){
      setDataBookmarks(oldData => oldData.filter(bookmark => bookmark.id != newBookmarkSelected))
    }else{
      setDataBookmarks([])
    }
    listBookmarks()
  })
  .catch(err => console.log(err.message))
  .finally(() => {
    setFilterOptionsMenu(null)
    setIsLoadingDeleteBookmark(false)
  })
}

function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}

const requestDaysContract = async () => {
  try {
    const formData = new FormData();
    formData.append("id_empresa", userData.id_empresa);
    await api
      .post("contract", formData)
      .then((response) => {
        const { contractActive, contract } = response.data;
        const empresa = contractActive[0];
        const contractEnterprise = contract.find(
          (item) => item.id_contrato == empresa.id_contrato

        );

        const dataAtual = new Date();


        const dataFinalContrato = new Date(contractEnterprise.data_final);
        const milisegundosDiferenca = dataFinalContrato.getTime() - dataAtual.getTime() + (1000 * 60 * 60 * 24);
        const diasDiferenca = milisegundosDiferenca / (1000 * 60 * 60 * 24);
        const diasRestantes = Math.floor(diasDiferenca);
        setDiasRestantes(diasRestantes)
        setPocStatus(contractEnterprise.poc)
      })
      .catch((err) => console.error(err.message));
  } catch (error) {
    console.error(error);
  } 
};

const hasClickedAi = () => {
  const isAssistantVisible = storage.get('@exibirAssistente') === true;

  if (isAssistantVisible && showAlertsId.id_alerta === 2) {
      storage.set('@hasClicked', true);
      onRefreshUserPermissionsAccess()
  } else {
      setActiveIA(true);
      onToggleMenu(true)
      storage.remove('@hasClicked');
  }
};


const expiredAvalition = () => {
  if(diasRestantes <= 0 && statusPoc === 1) {
     setExpiredContract(true)
  }else if(statusPoc !== 1){
    setExpiredContract(false)

  } else{
    setExpiredContract(false)
  }
}


return (
    <React.Fragment>
      {isActivePowerPoint && 
        <ModalExportPowerPoint 
          onClose={() => setIsActivePowerPoint(false)} 
          id_relatorio={currentReport.embedded_relatorio} 
          id_workspace={currentReport.embedded_workspace}
        />
      }
      {isActiveSignature &&
        <ModalSignatureReport
          onClose={() => setIsActiveSignature(false)}
          idReport={currentReport.id}
        />
      }
      {
        isActiveSignatureScorecard && 
        <ModalSignatureScorecard 
          onClose={() => setIsActiveSignatureScorecard(false)}
        />
      }
      {
        isActiveModalDonwloadApp &&
        <ModalDownloadApp 
          onClose={() => setIsActiveModalDonwloadApp(false)}
        />
      }
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <RenderConditional isTrue={storage.get('@acesso-ia') === true && userData.adm === 1}>
          <Tooltip title="Squad AI">
            <div
              className='navbar-item-icon-only'
              onClick={hasClickedAi}
            >
              <img src={gpt} style={{height: 15}} /> 
            </div>
          </Tooltip>
        {window.location.href.includes('viewReport') && storage.get('@exibirAssistente') == false ? 
          <AlertAi alertAiModal={alertAiModal} setAlertAiModal={setAlertAiModal} currentReport={currentReport}/> 
      : null}
        </RenderConditional>
        <RenderConditional isTrue={path.includes('viewReport')}>
            <RenderConditional isTrue={!path.includes('cerbras')}>
                <div 
                    onClick={() => setIsActiveSignature(true)}              
                    className='option-embedded-btn-signature'  
                >
                  <div className='option-embedded-btn-info'>
                  <svg fill="currentColor" class="___12fm75w f1w7gpdv fez10in fg4l7m0" aria-hidden="true" width="22" height="22" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.5A2.5 2.5 0 0 1 4.5 2h7A2.5 2.5 0 0 1 14 4.5v1.76a5.5 5.5 0 0 0-1-.66V4.5c0-.83-.67-1.5-1.5-1.5h-7C3.67 3 3 3.67 3 4.5v7c0 .83.67 1.5 1.5 1.5h1.1c.18.36.4.7.66 1H4.5A2.5 2.5 0 0 1 2 11.5v-7Zm13 6a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-4-2a.5.5 0 0 0-1 0V10H8.5a.5.5 0 0 0 0 1H10v1.5a.5.5 0 0 0 1 0V11h1.5a.5.5 0 0 0 0-1H11V8.5Z" fill="currentColor"></path></svg>
                    <p>{t('signature.index')}</p>                  
                  </div>
                </div>
            </RenderConditional>
            <RenderConditional isTrue={exportingReport === 'indle'}>
              <Tooltip title="Exportar relatório">
                <div 
                  onClick={handleClickExport}
                  className='option-embedded-btn'
                  aria-controls={openMenuExport ? "settings-control" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenuExport ? "true" : undefined}
                  disableRipple  
                >
                  <div className='option-embedded-btn-info'>
                    <svg height="15px" width="15px" focusable="false" viewBox="0 0 16 16">
                      <path key="0" class="OfficeIconColors_HighContrast" d="M15.953,8.5l-3.602,3.602l-0.703-0.703l2.406-2.414H2v-1h12.031l-2.383-2.383l0.703-0.703L15.953,8.5z M0,12	h1V5H0V12z">
                      </path><path key="1" class="OfficeIconColors_m24" d="M11.648,11.398l2.406-2.414L2,8.984v-1l12.031,0l-2.383-2.383l0.703-0.703L15.953,8.5l-3.602,3.602	L11.648,11.398z"></path><path key="2" class="OfficeIconColors_m22" d="M0,12h1V5H0V12z"></path></svg>
                    <p>{getURL.includes('golden') ? t('export.indexGolden') : t('export.index')}</p>
                  </div>
                  <div className='option-embedded-btn-down'>
                    <i className='mdi mdi-chevron-down' style={{fontSize: 12, color: '#888'}} /> 
                  </div>
                </div>
              </Tooltip>
            </RenderConditional>
            {/* Permissão apenas adminis */}
            <RenderConditional isTrue={(userData && userData.adm != 0) && reportHasRLS}>
              <Tooltip title={`Exibir como: ${activesRLS.length > 0 ? 
                  activesRLS.map((item) => item.nome).reduce((accumulator, currentValue, currentIndex, array) => {
                      if (currentIndex === array.length - 1 && array.length > 1) {
                          accumulator += ' e ' + currentValue;
                      } else if (currentIndex === array.length - 2 && array.length > 1) {
                          accumulator += currentValue;
                      } else if (currentIndex === 0 && array.length === 1) {
                          accumulator += currentValue;
                      } else {
                          accumulator += currentValue + ', ';
                      }
                      return accumulator;
                  }, '') 
                  : 'Nenhuma função RLS'
                }`}
              >
                <div
                  onClick={handleClickRLS}
                  className='navbar-item-icon-only'
                  aria-controls={openMenuRLS ? "user-control" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenuRLS ? "true" : undefined}
                  disableRipple
                >
                    <Shield theme="filled" size="16" fill="#888"/>
                </div>
              </Tooltip>
            </RenderConditional>
          <Tooltip title="Expandir relatório">
            <div
              className='navbar-item-icon-only'
              onClick={() => fullScreenEmbed()}
            >
                <i className="mdi mdi-arrow-expand" style={{fontSize:"18px" }}/>
            </div>
          </Tooltip>
          <RenderConditional isTrue={isObject(currentReport) ? (currentReport.liberado_atualizar == 1 && updatingReport === 'indle') : false}>
            <Tooltip title="Atualizar relatório">
              <div
                className='navbar-item-icon-only'
                onClick={() => updateReport()}
              >
                  {isLoadingIsUpdateReport ? <Spinner/> : <i className="mdi mdi-refresh" style={{fontSize:"18px" }}/>}
              </div>
            </Tooltip>
          </RenderConditional>

          <RenderConditional isTrue={window.location.host.includes('credforyou') || window.location.host.includes('monaco')  ? true : false}>
            <Tooltip title="Atualizar os Visuais">
              <div
                className='navbar-item-icon-only'
                onClick={() => refreshDashboard()}
              >
                  {isLoadingIsUpdateReport ? <Spinner/> : <PlayCycle theme="outline" fill="#777"  style={{fontSize: "17.39px", margin:"0", padding: "0", boxSizing: "border-box" }}/>}
              </div>
            </Tooltip>
          </RenderConditional>

          <RenderConditional isTrue={isObject(currentReport) ?  (currentReport.liberado_exportar == 1 && downloandReport === 'indle') : false}>
            <Tooltip title="Fazer download do relatório (pbix)">
              <div
                className='navbar-item-icon-only'
                onClick={() => handleExportReport(currentReport.embedded_workspace, currentReport.embedded_relatorio, currentReport.nome)}
              >
                  {isLoadingDownloadPBIX ? <Spinner/> : <i className="mdi mdi-download" style={{fontSize:"18px" }}/>}
              </div>
            </Tooltip>
          </RenderConditional>
          {/* <Tooltip title="Filtros">
            <div
              onClick={handleClickFilter}
              className='navbar-item-icon-only'
              aria-controls={openMenuFilter ? "filter-control" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenuFilter ? "true" : undefined}
              disableRipple
              >
                <span className="mdi mdi-bookmark" style={{fontSize:"18px" }}/>
            </div>
          </Tooltip> */}
        </RenderConditional>
        <RenderConditional isTrue={path.includes('viewReport')}>
          <Tooltip title="Controle de filtros">
              <div 
                className='navbar-item-icon-only'
                onClick={handleClickFilterControl}
              >
                <i 
                  className="mdi mdi-filter"
                  style={{fontSize: 18, color: '#888', position: 'relative'}}
                  aria-controls={openMenuFilterControl ? "user-control" : undefined} 
                  aria-haspopup="true" 
                  aria-expanded={openMenuFilterControl ? "true" : undefined} 
                  disableRipple
                />
              </div>
          </Tooltip>
        </RenderConditional>
        <RenderConditional isTrue={0}>
          <Tooltip title={hasFilter ? "Limpar filtros aplicados" :"Limpar os filtros"}>
            <div
              className='navbar-item-icon-only'
              onClick={async () => {
                setLockState(true)
                await removeBookmarkInCache();
                setTimeout(() => {
                    handleRemoveFilters();
                }, 500);
                setLockState(false)
              }}
            >
              
              <i className={hasFilter ? 'mdi mdi-filter-remove' : 'mdi mdi-filter'} style={{fontSize: 18, color: '#888', position: 'relative'}}>
                {/* {hasFilter && <i style={{width: 8, height: 8, borderRadius: 4, background: 'rgba(18, 35, 158, .8)', position: 'absolute', top: 6, right: 0}} />} */}
                {
                hasFilter &&
                <div className='nav-bullet-point'>
                  <div className='nav-ring' />
                </div>
              }
              </i>
            </div>
          </Tooltip>
        </RenderConditional>

        <RenderConditional isTrue={false}>
          <RenderConditional isTrue={1}>
                <div 
                    onClick={() => setIsActiveSignatureScorecard(true)}              
                    className='option-embedded-btn-signature-metric'  
                >
                  <div className='option-embedded-btn-info'>
                  <svg fill="currentColor" class="___12fm75w f1w7gpdv fez10in fg4l7m0" aria-hidden="true" width="22" height="22" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.5A2.5 2.5 0 0 1 4.5 2h7A2.5 2.5 0 0 1 14 4.5v1.76a5.5 5.5 0 0 0-1-.66V4.5c0-.83-.67-1.5-1.5-1.5h-7C3.67 3 3 3.67 3 4.5v7c0 .83.67 1.5 1.5 1.5h1.1c.18.36.4.7.66 1H4.5A2.5 2.5 0 0 1 2 11.5v-7Zm13 6a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm-4-2a.5.5 0 0 0-1 0V10H8.5a.5.5 0 0 0 0 1H10v1.5a.5.5 0 0 0 1 0V11h1.5a.5.5 0 0 0 0-1H11V8.5Z" fill="currentColor"></path></svg>
                    <p>Assinar Métrica</p>                  
                  </div>
                </div>
            </RenderConditional>
        </RenderConditional>

        <RenderConditional isTrue={statusPoc === 1 && userData.adm == 1}>
            <div className='limite-licencas'>
             <p>Avaliação</p>
             <p>{diasRestantes < 0 ? 'Avaliação finalizada' : `${diasRestantes} dias restantes`}</p>
            </div>
        </RenderConditional>

    
        <RenderConditional isTrue={true}>
          <Tooltip title={i18n.language == 'pt' ? 'Português' : i18n.language == 'en' ? 'English' : "Español"}>
            <div 
              onClick={handleClickIdioma}
              className='navbar-item-icon-only'
              aria-controls={openMenuIdioma ? "user-control" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenuIdioma ? "true" : undefined}
              disableRipple  
            >
              <i 
                className={
                  i18n.language == 'pt' 
                    ? 'flag-icon flag-icon-br' 
                    : i18n.language == 'en' 
                      ? 'flag-icon flag-icon-gb' 
                      : 'flag-icon flag-icon-es'
                } 
                style={
                  {
                    fontSize: 14
                  }
                }
              />
            </div>
          </Tooltip>
        {
          (!window.location.host.includes('credforyou') || !window.location.host.includes('monaco')) && (
            <Tooltip title="Novidades">
            <div 
              onClick={handleClickNotification}
              className='navbar-item-icon-only'
              aria-controls={openMenuNotification ? "user-control" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenuNotification ? "true" : undefined}
              disableRipple  
            >
              <i className='mdi mdi-bell-ring' style={{fontSize: 18}} />
            </div>
          </Tooltip>
          )
        }
          <Tooltip title="Conta">
            <div 
              onClick={handleClickUser}
              className='navbar-item-icon-only'
              aria-controls={openMenuUser ? "user-control" : undefined}
              aria-haspopup="true"
              aria-expanded={openMenuUser ? "true" : undefined}
              disableRipple  
            >
              <i className='mdi mdi-account-circle' style={{fontSize: 25}} />
            </div>
          </Tooltip>
        </RenderConditional>
      </Box>
          
      {/* DropDonws */}

      {/* DropDown Filter Control */}
      {
        (currentReport && Object.keys(currentReport).length > 0) &&
        <FilterControl
          currentReport={Object.keys(currentReport).length > 0 && currentReport}
          others={{
            open: openMenuFilterControl,
            anchorEl: filterControlMenu,
            onClose: handleCloseFilterControl,
          }}
        />
      }
      


      {/* DropDown Menu - RLS */}
      <Menu
        anchorEl={RLSMenu}
        id="rls-control"
        open={openMenuRLS}
        onClose={handleCloseRLS}
        PaperProps={stylePaperMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{paddingTop: 0}}
      >
        <Paper sx={{ width: 260 }} style={{boxShadow: 'none', padding: '10px 20px', height: '380px', overflowY: 'auto'}} >
          <p style={{color: "#444", fontWeight: 'bold'}}>Exibir como:</p>
          <p style={{color: "#444", fontSize: '85%', marginBottom: '10px'}}>
          {activesRLS.length > 0 ? 
            activesRLS.map((item) => item.nome).reduce((accumulator, currentValue, currentIndex, array) => {
                if (currentIndex === array.length - 1 && array.length > 1) {
                    accumulator += ' e ' + currentValue;
                } else if (currentIndex === array.length - 2 && array.length > 1) {
                    accumulator += currentValue;
                } else if (currentIndex === 0 && array.length === 1) {
                    accumulator += currentValue;
                } else {
                    accumulator += currentValue + ', ';
                }
                return accumulator;
            }, '') 
            : 'Nenhuma função RLS'
          }
          </p>
          <Divider />
          <div 
            style={{
              margin: '10px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              className='hover-it-item'
              style={{
                width: '50%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: !isUserRls ? '1px solid #888' : '1px solid transparent',
                padding: '3px 0'
              }}
              onClick={() => setIsUserRls(false)}
            >
              <p style={{color: "#444", fontSize: '85%', fontWeight: 'bold'}}>Função</p>
            </div>
            <div
              className='hover-it-item'
              style={{
                width: '50%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: isUserRls ? '1px solid #888' : '1px solid transparent',
                padding: '3px 0'
              }}
              onClick={() => setIsUserRls(true)}
            >
              <p style={{color: "#444", fontSize: '85%'}}>Usuário</p>
            </div>
          </div>
          <Divider />
            {!isUserRls ? 
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {reportHasRLS && regrasRLS.map((item) => {

                  const active = activesRLS.find(rls => rls.nome === item.nome)

                  function alterActiveItems() {

                    const actualActiveItems = activesRLS;

                    if(activesRLS.includes(item)) {

                      setSelectUserRls(null)
                      const newActiveItems = actualActiveItems.filter(i => i !== item);
                      setActivesRLS(newActiveItems);
                      setModifyRLS(true)

                    } else {

                      setSelectUserRls(null)
                      const newActiveItems = [...actualActiveItems, item];
                      setActivesRLS(newActiveItems)
                      setModifyRLS(true)

                    }
                  }

                  return (
                    <div 
                      key={item.nome}
                      className={active ? 'letter-item-active' : 'hover-letter-item'}
                      style={{
                        padding: '4px 0',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={() => alterActiveItems()}
                    >
                      {active && <i className='mdi mdi-check' style={{marginRight: 5}} />}
                      <p 
                        style={{
                          color: "#444", 
                          fontSize: '90%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {item.nome}
                      </p>
                    </div>
                    )
                  }) 
                }
              </div>
            :
              <div
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Select
                  closeMenuOnSelect
                  placeholder="Selecione o usuário"
                  noOptionsMessage={() => "Nenhum usuário encontrado"}
                  options={[...new Set(regrasRLS.reduce((acc, curr) => acc.concat(users.filter(user => curr.usuarios.includes(user.value) || curr.grupos.includes(user.grupo_usuario))), []))]}
                  value={selectUserRls}
                  onChange={(item) => {
                    if (item) {
                      setSelectUserRls(item)
    
                      const regras = () => {
                          const nomesRegras = [];
                          
                          regrasRLS.forEach(regra => {
                              if (regra.usuarios.includes(item.value)) {
                                  nomesRegras.push({...regra, email: item.email});
                              }
                          });

                          regrasRLS.forEach(regra => {
                            if (regra.grupos.includes(item.grupo_usuario)) {
                                nomesRegras.push({...regra, email: item.email});
                            }
                          });
                          
                          return nomesRegras;
                      }

                      setActivesRLS(regras())
                      setModifyRLS(true)
                    } else {
                      setActivesRLS([])
                      setModifyRLS(true)
                    }
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: '1px solid #aaa',
                    }),
                    option: (provided, { isFocused }) => ({
                      ...provided,
                      backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                      color: "#333",
                      display: "flex",
                      alignItem: "center",
                    }),
                  }}
                />
                {
                  selectUserRls &&
                    <div style={{marginTop: 10, display: 'flex', flexDirection: 'column'}}>
                      <Divider />
                      <p 
                        style={{
                          marginTop: 15,
                          color: "#444", 
                          fontSize: '90%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold'
                        }}
                      >
                        Nome do usuário
                      </p>
                      <p
                        style={{
                          color: "#444", 
                          fontSize: '90%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {selectUserRls.label}
                      </p>
                      <p 
                        style={{
                          marginTop: 10,
                          color: "#444", 
                          fontSize: '90%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          fontWeight: 'bold'
                        }}
                      >
                        Funções atribuídas
                      </p>
                      <p
                        style={{
                          color: "#444", 
                          fontSize: '90%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {activesRLS.length > 0 ? 
                          activesRLS.map((item) => item.nome).reduce((accumulator, currentValue, currentIndex, array) => {
                              if (currentIndex === array.length - 1 && array.length > 1) {
                                  accumulator += ' e ' + currentValue;
                              } else if (currentIndex === array.length - 2 && array.length > 1) {
                                  accumulator += currentValue;
                              } else if (currentIndex === 0 && array.length === 1) {
                                  accumulator += currentValue;
                              } else {
                                  accumulator += currentValue + ', ';
                              }
                              return accumulator;
                          }, '') 
                          : 'Nenhuma função RLS'
                        }
                      </p>
                    </div>
                }
              </div>
            }
            {modifyRLS &&
              <div style={{marginTop: 10, height: 40, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <Divider />
                  <div 
                    className='btn-rls-apply'
                    onClick={() => 
                      {
                        applyVerifyRls(activesRLS, currentReport.embedded_relatorio)
                        setModifyRLS(false)
                      }
                    }
                  >
                    <p>Aplicar</p>
                  </div>
              </div>
            }
        </Paper>
      </Menu>

      <Menu
        anchorEl={idiomaMenu}
        id="idioma-control"
        open={openMenuIdioma}
        onClose={handleCloseIdioma}
        PaperProps={stylePaperMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{paddingTop: 0}}
      >
        <Paper sx={{ width: 200 }} style={{boxShadow: 'none', padding: '6px 16px'}}>
        <p>{t('idioma')}:</p>
        <Select
              isDisabled={path.includes('editarRelatorio') || path.includes('editarUsuario') || path.includes('addUsuario')}
              value={
                [
                  {
                    label: "Português",
                    value: 'pt'
                  }, 
                  {
                    label: "English",
                    value: 'en'
                  },
                  {
                    label: "Español",
                    value: "es"
                  }
                ].find(item => item.value === i18n.language)
              }
              formatOptionLabel={item => (
                <div className="country-option">
                  <i className={item.value === 'pt' ? 'flag-icon flag-icon-br' : item.value === 'en' ? 'flag-icon flag-icon-gb' : 'flag-icon flag-icon-es'} width={25} />
                  <span style={{marginLeft: 10}}>
                  {item.label}
                  </span>
                </div>
              )}
              options={
                [
                  {
                    label: "Português",
                    value: 'pt'
                  }, 
                  {
                    label: "English",
                    value: 'en'
                  },
                  {
                    label: "Español",
                    value: "es"
                  }
                ].filter(item => item.value !== i18n.language)
              }
              maxHeight={38}
              onChange={item => [i18n.changeLanguage(item.value), storage.set('@language', item.value)]}
              styles={{
                control: (provided) => ({
                  ...provided,
                  margin: '4px 0px 0px 0px',
                  height: 25
                }),
                option: (provided, { isFocused }) => ({
                  ...provided,
                  backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                  color: "#333",
                  display: "flex",
                  alignItems: "center",
                  // height: 25
                }),
              }}
            />
        </Paper>
      </Menu>

      <Menu
        anchorEl={notificationMenu}
        id="notification-control"
        open={openMenuNotification}
        onClose={handleCloseNotification}
        onClick={handleCloseNotification}
        PaperProps={stylePaperMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{paddingTop: 0}}
      >
        <Paper sx={{ width: 260 }} style={{boxShadow: 'none', padding: '10px 20px'}}>
            <p style={{color: "#444", fontWeight: 'bold'}}>Notificações e alertas</p>
            <p style={{color: "#444", fontSize: '85%', marginBottom: '10px'}}>Fique por dentro das novidades.</p>
            <Divider />
            <div style={{minHeight: '80px', background: 'rgba(18, 35, 158, .1)',border: '1px solid rgba(18, 35, 158, .5)', marginTop: '10px', borderRadius: 6, display: 'flex', flexDirection: 'column', padding: '8px 10px', gap: '10px'}}>
              <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <Tool theme="filled" size="14" fill="#444"/>
                <p style={{fontSize: '90%', color: '#444', fontWeight: 'bold'}}>Organização</p>
              </div>
              <p style={{fontSize: '85%'}}>Modifique agora a aparência de forma mais simples e rapido</p>
              <p style={{fontSize: '85%', color: '#555'}}>Usuários administradores.</p>
            </div>
        </Paper>
      </Menu>

      <Menu
        anchorEl={exportMenu}
        id="export-control"
        open={openMenuExport}
        onClose={handleCloseExport}
        PaperProps={stylePaperMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      > 
        <MenuItem style={{fontSize: '70%'}}>{t('export.pages.index')}</MenuItem>
        <MenuItem style={{fontSize: '90%'}} onClick={() => setExportAllPages(true)}>
          <ListItemIcon>
            {exportAllPages ? <CheckBox fontSize='small' /> : <CheckBoxOutlineBlank fontSize='small' />}
          </ListItemIcon>
          {t('export.pages.all')}
        </MenuItem>
        <MenuItem style={{fontSize: '90%'}} onClick={() => setExportAllPages(false)}>
          <ListItemIcon>
            {!exportAllPages ? <CheckBox fontSize='small' /> : <CheckBoxOutlineBlank fontSize='small' />}
          </ListItemIcon>
          {t('export.pages.actual')}
        </MenuItem>
        <Divider />
        <MenuItem style={{fontSize: '70%'}}>{t('export.option.index')}</MenuItem>
        <MenuItem style={{fontSize: '90%'}} onClick={() => setIsExportFilters(isExportFilters === 'current' ? '' : 'current')}>
          <ListItemIcon>
            {isExportFilters === 'current' ? <CheckBox fontSize='small' /> : <CheckBoxOutlineBlank fontSize='small' />}
          </ListItemIcon>
          {t('export.option.filter')}
        </MenuItem>
        {/* {!!dataBookmarks  && 
          dataBookmarks.map(itemFilter => {
            return(
              <MenuItem onClick={() => setIsExportFilters(isExportFilters === itemFilter.state ? '' : itemFilter.state)}>
                  <ListItemIcon>
                    {isExportFilters === itemFilter.state ? <CheckBox fontSize='small' /> : <CheckBoxOutlineBlank fontSize='small' />}
                  </ListItemIcon>
                  {itemFilter.nome}
              </MenuItem>
            )
          })
        } */}
        <MenuItem style={{fontSize: '90%'}} onClick={() => setIsExportHiddenPages(!isExportHiddenPages)}>
          <ListItemIcon>
            {isExportHiddenPages ? <CheckBox fontSize='small' /> : <CheckBoxOutlineBlank fontSize='small' />}
          </ListItemIcon>
          {t('export.option.ocult')}
        </MenuItem>
        <Divider />
        <MenuItem style={{fontSize: '70%'}}>{t('export.format')}</MenuItem>
        <MenuItem onClick={() => [handlePrepareExportFile(currentReport.embedded_workspace, currentReport.embedded_relatorio, currentReport.nome, "PDF", isExportFilters, isExportHiddenPages, exportAllPages), handleCloseExport()]}>
          <ListItemIcon>
            <img src={pdfImage} style={{height: 20}} />
          </ListItemIcon>
          PDF
        </MenuItem>
        <MenuItem onClick={() => [setIsActivePowerPoint(true), handleCloseExport()]}>
          <ListItemIcon>
              <img src={webImage} style={{height: 16}} />
          </ListItemIcon>
          Web Viewer
        </MenuItem>
          <MenuItem onClick={() => [handlePrepareExportFile(currentReport.embedded_workspace, currentReport.embedded_relatorio, currentReport.nome, "PPTX", isExportFilters, isExportHiddenPages, exportAllPages), handleCloseExport()]}>
            <ListItemIcon>
              <img src={powerpointImage} style={{height: 20}} />
            </ListItemIcon>
            Power Point
          </MenuItem>
      </Menu>

      <Menu
        anchorEl={userMenu}
        id="user-control"
        open={openMenuUser}
        onClose={handleCloseUser}
        PaperProps={stylePaperMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        style={{paddingTop: 0}}
      >
        <Paper sx={{ width: 260 }} style={{boxShadow: 'none', paddingTop: 0}}>
          <div style={{margin: '10px 0', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex'}}>
              <img src={Fabric} style={{height: 15, width: 15, marginRight: 5}} />
              <p style={{fontSize: 12, color: "#444"}}><strong>Microsoft Fabric</strong></p>
            </div>
          </div>
          <div style={{background: "rgb(238, 238, 238)", height: '160px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{height: 75, width: 75, borderRadius: '100%', background: '#112461', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px'}}>
              <p style={{color: '#fff', fontSize: 42, fontFamily: 'Arial'}}>{userData && userData.nome[0]}</p>
            </div>
            <p 
              style={{
                color: '#222',
                width: '80%',
                fontWeight: 'bold', 
                textAlign: 'center',
                fontSize: '105%', 
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
              }}>
                {userData && userData.nome}</p>
            <p 
              style={{
                color: '#444',
                fontSize: '85%',
                width: '80%',
                fontWeight: 'bold', 
                textAlign: 'center',
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
              }}>
                {userData && userData.email}
              </p>
            <p 
              style={{
                fontSize: 10,
                width: '80%',
                color: "#888",
                textAlign: 'center',
                fontWeight: 'bold',
                whiteSpace: 'nowrap', 
                overflow: 'hidden', 
                textOverflow: 'ellipsis',
              }}>
                {version}
            </p>
          </div>
            <MenuList dense>
              <Divider />
              <MenuItem style={{marginTop: 8}} onClick={() => {
                history.push("/profile");
              }}>
              <ListItemIcon>
                <EditName theme="filled" size="16" fill="#333"/>
              </ListItemIcon>
              {t('account.editUser')}
            </MenuItem>
            <RenderConditional isTrue={userData && userData.adm != 0 && userData.adm != 2 }>
              <MenuItem onClick={() => {
                    history.push("/customization");
              }}>
                <ListItemIcon>
                  <BackgroundColor theme="filled" size="16" fill="#333"/>
                </ListItemIcon>
                {t('account.org')}
              </MenuItem>
            </RenderConditional>
              <MenuItem onClick={() => {
                  setIsActiveModalDonwloadApp(true)
                }}>
                <ListItemIcon>
                  <Android theme="filled" size="16" />
                </ListItemIcon>
                {t('account.app')}
              </MenuItem>
              <RenderConditional isTrue={(userData && userData.adm != 0) && (userData && (userData.id_empresa == '8' || userData.id_empresa == '20'))}>
              <MenuItem onClick={() => {
                  history.push("/company/requests");
                }}>
                <ListItemIcon>
                  <Tool theme="filled" size="16" fill="#333"/>
                </ListItemIcon>
                {t('account.custom')}
              </MenuItem>
            </RenderConditional>
            
            <Divider />
            <MenuItem style={{marginTop: 8}} onClick={() => {
                deslogar()
                history.push("/login");
              !!msalInstance && msalInstance.logout()
            }}>
              <ListItemIcon>
                <Power theme="filled" size="16" fill="#333"/>
              </ListItemIcon>
              {t('account.exit')}
            </MenuItem>
          </MenuList>
        </Paper>
      </Menu>

      <Menu
        anchorEl={filterMenu}
        id="filter-control"
        open={openMenuFilter}
        onClose={handleCloseFilter}
        PaperProps={stylePaperMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        
      >
      <MenuItem disabled={newBookmarkActivity}>
      <RenderConditional isTrue={!isLoadingListNewBookmark}>
        Indicadores Pessoais
      </RenderConditional>
      <RenderConditional isTrue={isLoadingListNewBookmark}>
        ATUALIZANDO 
        <Spinner/>
      </RenderConditional>
      </MenuItem>

        {!!dataBookmarks  && 
          dataBookmarks.map(itemFilter => {
            return(
              <MenuItem 
                onClick={() => {
                  apllybookmarkState(itemFilter.state)
                  setNewBookmarkSelected(itemFilter.id)
                }}
                disabled={newBookmarkActivity}
                style={{display:'flex',justifyContent:'space-between',alignItems:'center',color:newBookmarkSelected === itemFilter.id ? 'green' : ''}}
                >
                  {itemFilter.nome}
                    <ListItemIcon>
                      <IconButton
                        onClick={handleClickFilterOptions}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={openMenuFilterOptions ? "filter-control-options" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenuFilterOptions ? "true" : undefined}
                        disableRipple
                        disabled={isLoadingListNewBookmark}
                      >
                        <MoreVertIcon fontSize="small"/>
                      </IconButton>
                  </ListItemIcon>
                </MenuItem>
            )
          })
          }

      <RenderConditional isTrue={Array.isArray(dataBookmarks) ? dataBookmarks.length : false}>
          <Divider />
      </RenderConditional>

      <MenuItem style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'flex-start'}}>
          <div onClick={() => setNewBookmarkActivity(!newBookmarkActivity)}>
            <ListItemIcon>
              <BookmarkAddIcon fontSize="small" />
            </ListItemIcon>
            Novo Indicador
          </div>

          <RenderConditional isTrue={newBookmarkActivity}>
            <div style={{display:'flex',flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
              <TextField id="standard-basic" label="Novo Indicador" variant="standard" onChange={e => setNewBookmarkValue(e.target.value)}/>
              <IconButton
                onClick={createNewBookMark}
                size="small"
                style={{cursor:'pointer'}}
              >
                <RenderConditional isTrue={isLoadingCreateNewBookmark}>
                    <Spinner/>
                </RenderConditional>
                <RenderConditional isTrue={!isLoadingCreateNewBookmark}>
                  <div className="nav-profile-text">
                        <span className="mdi mdi-check" style={{fontSize:"20px" }}/>
                  </div>
              </RenderConditional>
          </IconButton>
            </div>
          </RenderConditional>
      </MenuItem>

      </Menu>

      <Menu
      anchorEl={filterOptionsMenu}
      id="filter-control-options"
      open={openMenuFilterOptions}
      onClose={handleCloseFilterOptions}
      PaperProps={stylePaperMenu}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuItem onClick={deleteBookmark}>
        <ListItemIcon>
          <RenderConditional isTrue={!isLoadingDeleteBookmark}>  
              <DeleteForeverIcon fontSize="small" />
          </RenderConditional>

          <RenderConditional isTrue={isLoadingDeleteBookmark}>  
            <Spinner/>
          </RenderConditional>
        </ListItemIcon>
        Apagar
      </MenuItem>
    </Menu>
    </React.Fragment>
  );
}
