import './style.scss'
import React from 'react'

export function Limiter() {
   return (
    <div className="sidebar-minimize-limiter">
        <i />
    </div>
   ) 
}
