import './styles.scss';
import React from 'react';
import loader from '../../../assets/images/newloader.gif'
import { CheckOne } from '@icon-park/react';

export default function ShippingExportCard({status, downloadFile, pbix}) {
    return (
        <main className={`await-report-container ${(status === "waiting for user") ? "await-user" : ""}`}>
            <div className='await-report-content'>
                <div className='loader-section'>
                    {status === 'awaiting server' ? <img src={loader} /> : <CheckOne theme="two-tone" size="24" fill={['#7ed321' ,'#ffffff']}/>}
                </div>
                <div className='informations'>
                    <p className='title'>
                        {pbix 
                            ? status === 'awaiting server' ? "Seu relatório está sendo exportado em .PBIX" : "Seu relatório .PBIX foi exportado" 
                            : status === 'awaiting server' ? "Seu relatório está sendo exportado" : "Seu relatório foi exportado"
                        }
                    </p>
                    <p className='warn'>
                        {pbix 
                            ?   status === 'awaiting server' ? "O relatório .PBIX está sendo exportado, o que pode levar alguns minutos..." : "Exportado com êxito, faça donwload do seu relatorio .PBIX"
                            : status === 'awaiting server' ? "O relatório está sendo exportado, o que pode levar alguns minutos..." : "Exportado com êxito, faça donwload do seu relatorio."
                        }
                    </p>
                </div>
            </div>
            <div className='action-report-content'>
                {status === 'waiting for user' &&
                    <div className='success-all-btns'>
                        <div className='success-btn' style={{width: '100%', gap: '10px'}} onClick={downloadFile}>
                            Fazer download
                        </div>
                    </div>
                }
            </div>
        </main>
    )
}