import "./style.css"
import React from 'react';

export default function Connection({ first, last }) {
    return (
        <div className="conection-group-item2">
            <div className={first && last ?  "connection-grupo-top-always" : "conection-group-top"}>
                {!first && <span className="divider" />}
            </div>
            <div className="conection-group-bottom">
                {!last && <span className="divider" /> }
            </div>
        </div>
    )
}