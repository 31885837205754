import './style.css';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { CloseOne } from '@icon-park/react';
import RelatorioEmbedded from 'app/components/embedded/ModalRelatorio';
import api from 'services/api/api';
import axios from 'axios';
import powerBILoader from "../../../../../src/assets/images/pbi_logo.png";
import progressBar from "../../../../../src/assets/images/progress.gif";
import { models } from 'powerbi-client';
import { storage } from 'utils';

const settings = {
    layoutType: models.LayoutType.Custom,
    customLayout: {
      displayOption: models.DisplayOption.FitToWidth,
      marginTop: 0,
    },
    permissions: models.Permissions.All,
    panes: {
      filters: {
        expanded: false,
        visible: false
      }
    },
    commands: {
        parameterPanel: {
            enabled: true,
            expanded: true
        }
    }
  }

export default function RenderReport({embeddedData, close, rls_function, userEmail}) {

    const [pending, setPending] = useState(true)

    const [accessToken,setAccessToken] = useState("")
    const [embedUrl,setEmbedUrl] = useState("")
    const [token, setToken] = useState(undefined)
    const [rlsBlock, setRlsBlock] = useState(false)
    const [sampleReportConfig, setReportConfig] = useState({
      type: 'report',
      id:embeddedData.embedded_relatorio,
      embedUrl,
      tokenType: embeddedData.paginado == 1 ? models.TokenType.Aad : models.TokenType.Embed,
      accessToken:undefined,
      permissions: models.Permissions.All,
      settings: {
          ...settings,
          visualSettings: {
            visualHeaders: [
                {
                    settings: {
                        visible: true
                    }
                }
            ]
        }
      },
    });

    async function handleRequestReport(objBody) {
        await api.post('/reportsView', objBody).then(res => {
          const { tokenPowerBiApi, token, rls_block } = res.data;
          setToken(token)
          createHistoryItemAndCache(objBody.report, token, tokenPowerBiApi, objBody)
          if(embeddedData.paginado == 1){
            tryRefreshUserPermissions(tokenPowerBiApi)
          }else{
            setReportConfig({
              ...sampleReportConfig,
                accessToken: token
            });
    
            setEmbedUrl('https://app.powerbi.com/reportEmbed')
          }
          
        })
        .catch(err => console.error(err.message))
    } 
  
    function createHistoryItemAndCache(embedded_relatorio, token, tokenPowerBiApi, objBody) {
      let history = storage.get('@user_history') || [];
      
      const existingIndex = history.findIndex((historyItem) => historyItem.embedded_relatorio === embedded_relatorio);
      
      if (existingIndex !== -1) {
          const item = history[existingIndex];
  
          // if(token) {
          //   item.date_token = new Date();
          //   item.acess_token = token;
          //   item.tokenPowerBiApi = tokenPowerBiApi;
          // } else {
          //   item.history = new Date();
          // }
  
          item.history = new Date();
          history[existingIndex] = item;
      
          storage.set('@user_history', history);    
        } else {
          const newHistoryItem = {
              embedded_relatorio: embedded_relatorio,
              embedded_workspace: objBody.workspace,
              paginado: objBody.paginado,
              history: new Date()
          };
      
          history.push(newHistoryItem);
          storage.set('@user_history', history);
      }
    }
  
    async function tryRefreshUserPermissions(accessToken) {
      await axios.post(`https://api.powerbi.com/v1.0/myorg/RefreshUserPermissions`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }}
      )
      .then(() => {
        console.log("User permissions refreshed successfully.");
      })
      .catch(err => {
        if (err.status === 429) {
          console.error("Permissions refresh will be available in up to an hour.");
        }else {
            console.error(err.message);
        }    
      })
      .finally(() => getembedUrl(accessToken))
    }
  
    async function getembedUrl(accessToken) {
  
      await axios.get(`https://api.powerbi.com/v1.0/myorg/groups/${embeddedData.embedded_workspace}/reports/${embeddedData.embedded_relatorio}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }}
      )
      .then(({data:{ embedUrl }}) => {
        setAccessToken(accessToken)
        if(embedUrl){
          setEmbedUrl("https://app.powerbi.com/reportEmbed?reportId=2e64c188-9915-4834-9d85-ec9eee397627&groupId=4c0206cd-4258-4410-b21b-b511341cab43&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLUJSQVpJTC1TT1VUSC1CLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19")
        }
        setReportConfig({
          ...sampleReportConfig,
          accessToken,
          embedUrl
        })
      })
      .catch(err => {
        console.log("Error " + err.message + ":  An error has occurred");
      })
    }
  
    function resetStates(){
      setAccessToken("")
      setEmbedUrl("")
      setReportConfig({
        type: 'report',
        id:embeddedData.embedded_relatorio,
        embedUrl,
        tokenType: embeddedData.paginado == 1 ? models.TokenType.Aad : models.TokenType.Embed,
        accessToken,
        settings,
      })
    };

    useEffect(
        () => {handleRequestReport({
            report: embeddedData.embedded_relatorio,
            workspace: embeddedData.embedded_workspace,
            rls_mail: "rls@squadbi.app",
            rls_function: rls_function
        })
    }, [])

    if(!embedUrl) {
        return (            
            <motion.div
                className='content-render-testing-rls'
            >   
                <div className='loader-container-testing'>  
                    <img
                        className='shimmer'
                        style={{ width: "80px", display:'flex'}}
                        src={powerBILoader}
                        alt="logo"
                    />
                </div>
                <section className='options-back-or-exit-white' onClick={close}>
                {/* <ArrowCircleLeft theme="outline" size="20" fill="#333"/> */}
                    <CloseOne theme="outline" size="20" fill="#333"/>
                </section>
            </motion.div>
        )
    }

    return (
        <motion.div 
            className='content-render-testing-rls'
        >
            {/* <RelatorioEmbedded
                key={accessToken} 
                relatorio={embeddedData.embedded_relatorio}
                workspace={embeddedData.embedded_workspace}
                embedUrl={embedUrl}
                tipo={1}
                token={accessToken}
                setPending={(state) => setPending(state)}
                error={(err) => console.error(`Não foi possível abrir o embedded\n${err}`)}
            /> */}
            <RelatorioEmbedded
                key={token} 
                relatorio={embeddedData.embedded_relatorio}
                workspace={embeddedData.embedded_workspace}
                tipo={1}
                paginado={embeddedData.paginado}
                token={token}
                setPending={(state) => console.log(state)}
                error={(err) => console.error(`Não foi possível abrir o embedded\n${err}`)}
                rlsBlock={rlsBlock}
            />		
            <section className='options-back-or-exit' onClick={close}>
                {/* <ArrowCircleLeft theme="outline" size="20" fill="#333"/> */}
                <CloseOne theme="outline" size="20" fill="#333"/>
            </section>
        </motion.div>
    )
}