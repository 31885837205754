import { Newlybuild } from '@icon-park/react'
import '../style.scss'
import React from 'react'

export default function NotFound({createNew, isLoading, t}) {
    return (
        <div className="signature-modal-not-found">
            <Newlybuild theme="filled" size="62" fill="#777"/>
            <p className='title'>{t('signature.notFound.title')}</p>
            <p className='subtitle'>{t('signature.notFound.description')}</p>
            <div className={!isLoading ? 'btn-signature-not-found' : 'btn-signature-not-found-loading'} onClick={!isLoading ? createNew : () => null}>
                {t('signature.notFound.new')}
            </div>
        </div>
    )
}