import './styles.scss';
import React from 'react';
import loader from '../../../assets/images/newloader.gif'
import { CheckOne, Error } from '@icon-park/react';
import DataError from '../../../assets/images/dataerror.png'

export default function ShippingUpdateCard({status, dualUpdateError}) {
    // status: 1. "indle" -- 2. "awaiting server" -- 3. "success" / "error"
    return (
        <main className={`await-report-container ${(status === "waiting for user") ? "await-user" : ""}`}>
            <div className='await-report-content'>
                <div className='loader-section'>
                    {status === 'awaiting server' ? <img src={loader} /> : status === 'success' ? <CheckOne theme="two-tone" size="24" fill={['#7ed321' ,'#ffffff']}/> : dualUpdateError ? <img src={DataError} /> : <Error theme="two-tone" size="24" fill={['#d0021b' ,'#ffffff']}/>}
                </div>
                <div className='informations-refresh'>
                    <p className='title'>
                        {status === 'awaiting server' ? "Seu relatório está sendo atualizado" : status === 'success' ? "Seu relatório foi atualizado!" : dualUpdateError ? "O relatório já está sendo atualizado por outro usuário, dentro de alguns instantes atualize a página." : 'Erro ao atualizar relatório'}
                    </p>
                    <p className='warn'>
                        {status === 'awaiting server' ? "isso pode levar alguns minutos..." : status === 'success' ? "Atualizado com êxito, os dados serão recalculados." : dualUpdateError ? "" : 'Tente novamente em alguns minutos ou entre em contato com um administrador.'
                        }
                    </p>
                </div>
            </div>
        </main>
    )
}