import React, { useContext, useState } from "react";
import "./style.css";
import { styles ,NoData } from "./config";
import {Link, useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { FormControl, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { useEffect } from "react";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"
import { DownloadTwo } from "@icon-park/react";
import Menu from "@mui/material/Menu";
import xlsxImage from '../../../assets/images/xlsx-image.png';
import LogsImage from '../../../assets/images/logs.png';
import { ReportsContext } from "contexts/reportsContext";

const LoaderProgress = ({loaderTitle}) => {
return(

  <div className="containerLoaderDataTable">
    <span class="spinner"/>
    <span>{loaderTitle || 'Buscando informaçoes...'}</span>
  </div>

)
}

export const stylePaperMenu = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0
    }
  }
}

const ProgressBarContract = ({ totalContracts = 0, signedContracts = 0 }) => {
  const progress = (signedContracts / totalContracts) * 100;
  let color;

  if (progress >= 100) {
    color = 'red';
  } else if (progress >= 50) {
    color = 'orange';
  } else {
    color = 'green';
  }
  const progressBarStyles = {
    width: `${progress}%`,
    backgroundColor: color,
    height: '20px'
  };

  return (
    <div>
      <div style={{ backgroundColor: 'gray', height: '20px' }}>
        <div style={progressBarStyles}></div>
      </div>
      <p>{`${signedContracts} / ${totalContracts} licenças utilizadas`}</p>
    </div>
  );
};

function BaseTable(
  { 
    title = '',
    data = [],
    columns = [],
    dense = false,
    imprimir = false,
    exportar = false,
    titleButtonAction = '',
    newReport = false,
    colorBtnAction = '',
    colorTextAction = '',
    route = '',
    placeholderInput= '',
    loaderActive = false,
    loaderTitle= '',
    onActionButton = () => {},
    conditionalRowStyles = [],
    filterActives = false,
    seeContractProgress = false,
    contract,
    tableOnly = false,
    headerColor,
    toLogs = false,
    paginationPerPage,
    newUser
  }) {

  const [dataFiltered,setDataFiltered] = useState(data)
  const [valueFilter,setValueFilter] = useState(1)
  const [isActiveExport, setIsActiveExport] = useState(null)
  const { statusSubmit, setStatusSubmit, setSyncPowerBI } = useContext(ReportsContext)
  let history = useHistory();

  useEffect(() => {
    if(!data.length) return
    filterActivesAction(1)
  },[data])

  function filterActivesAction(action){
    setValueFilter(action)
    if(action == 2){
      setDataFiltered(data)
      return
    }
    setDataFiltered(data.filter(item => item.status == action))
  }

  const tableData = {
    columns: columns || [],
    noHeader: true,
    pagination: true,
    customStyles: {
      ...styles,
      ...(headerColor ? { headCells: { style: { background: headerColor, ...styles.headCells.style } } } : {}),
    },
    noDataComponent:<NoData/>,
    progressComponent:  <LoaderProgress loaderTitle={loaderTitle}/>,
    progressPending: loaderActive,
    highlightOnHover: true,
    paginationComponentOptions: {
      rowsPerPageText: "Itens por pagina",
      rangeSeparatorText: "de",
    },
    dense: dense,
    sortIcon: <ArrowDownwardIcon />,
    conditionalRowStyles:conditionalRowStyles,
    paginationPerPage: paginationPerPage && paginationPerPage,
    defaultSortFieldId:"Nome"
  };

  const tableDataExtensions = {
    columns: columns || [],
    filterPlaceholder: placeholderInput || "Filtrar dados...",

    //Para execultar as funcoes abaixo as colunas nao podem ter elementos jsx somente dados.
    print: imprimir,
    export: exportar,
  };

  function verifyLimitContracts(){
    if(seeContractProgress){
      if(contract.licencasUltilizadas >= contract.totalLicencas){
        Swal.fire({
          title:'Limite de licencas atigidas!',
          icon:'info',
          toast:true,
          showConfirmButton:false,
          timer:2000,
          position:'top-end'
        })
      }
    }
  }

  function mapUsers(data) {
  
    const result = [];
  
    data.forEach(usuario => {
      const newUsers = {
        "Nome": usuario.nome || "Desconhecido",
        "Tipo de Usuário": {
          1: "Administrador",
          2: "Supervisor de grupo de negócio",
          0: "Usuário comum"
        }[usuario.adm] || "Desconhecido",
        "Cargo": usuario.cargo || "Desconhecido",
        "Grupo de usuário": usuario.gruposusuario || "Desconhecido",
        "E-mail": usuario.email || "Desconhecido",
        "Whatsapp": usuario.whats_app || "Desconhecido",
        "Último acesso": usuario.ultimoacesso || "Sem registro"
      };
  
      result.push(newUsers);
    });
  
    return result;
  }

  const open = Boolean(isActiveExport);
  const handleClick = (event) => {
    setIsActiveExport(event.currentTarget);
  };
  const handleClose = () => {
    setIsActiveExport(null);
  };

  function handleExportData() {
    // nome, tipo usuario, cargo, grupo de usuario, e-mail, whatsapp, último acesso.
    const dadosMapeados = mapUsers(dataFiltered)
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dadosMapeados)

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0];

    XLSX.utils.book_append_sheet(wb, ws, "SquadBISheet")
    XLSX.writeFile(wb, `Licencas-SquadBI_${formattedDate}.xlsx`)
  }

  if(tableOnly) {
    return (
      <>
        {!!contract &&
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: 15}}>
            <div style={{width: 200}}>
              <ProgressBarContract totalContracts={contract.totalLicencas} signedContracts={contract.licencasUltilizadas}/>
            </div>
          </div> 
        }
        <DataTable data={data} {...tableData}/>
      </>
    )
  }

  return (
    <div className="containerTableData">
      <div id="headerTableData">
        <div className="d-flex align-center" style={{gap: '20px'}}>
          {newReport &&
          <div style={{display: 'flex', gap: '5px'}}>
              <div className={`new-report-home ${statusSubmit !== 'indle' ? 'disabled' : ''}`} onClick={statusSubmit === 'indle' ? () => setStatusSubmit("file selection") : () => {}}>
                <i className='mdi mdi-plus' />
                <p>Publicar relatório</p>
              </div>
              {/* <Tooltip title="Painel de sicronização">
                <div className="sync-reports" onClick={() => setSyncPowerBI('Await user')}>
                  <i className="mdi mdi-cloud-sync" />
                </div>
              </Tooltip> */}
          </div>
              
          }
          {newUser && 
            <div className="new-report-home" style={{marginTop: 20}} onClick={() => history.push(route)}>
              <i className='mdi mdi-plus' />
              <p>Novo usuário</p>
            </div>
          }
          {!!titleButtonAction &&
              <Link
                to={ (seeContractProgress && contract.licencasUltilizadas >= contract.totalLicencas) ? "usuarios" : route} 
                onClick={verifyLimitContracts}
                style={{display: 'flex', alignItems: 'center', gap: '5px'}}
              >
                <AddIcon fontSize="large" />
                <span>{titleButtonAction}</span>
              </Link>              
          }
        </div>
      <div id="containerActions">
            {
              !!filterActives &&
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-simple-select-filled-label">Filtrar Listagem</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={valueFilter}
                  sx={{padding:0,height:40}}
                  onChange={value => filterActivesAction(value.target.value)}
                >
                  <MenuItem value={1}>Ativos</MenuItem>
                  <MenuItem value={0}>Destivados</MenuItem>
                  <MenuItem value={2}>Todos</MenuItem>
                </Select>
                </FormControl>
            }

            {
              seeContractProgress && (
                <div className="contract-list">

                  {toLogs && 
                    <Tooltip title="Relatório de logs">
                      <div className="btn-export-xlsx" onClick={() => history.push("/formulario/logsAuditoria")}>
                        <img width={28} src={LogsImage} />
                      </div>
                    </Tooltip>
                  }

                  <Tooltip title="Exportar tabela">
                    <div className="btn-export-xlsx" onClick={handleClick}>
                      <DownloadTwo 
                        theme="outline" 
                        size="20" 
                        fill="#333" 
                        aria-controls={isActiveExport ? "export-control" : undefined}
                        aria-haspopup="true"
                        aria-expanded={isActiveExport ? "true" : undefined}
                      />
                    </div>
                  </Tooltip> 
                  <Menu
                    anchorEl={isActiveExport}
                    id="export-control"
                    open={open}
                    onClose={handleClose}
                    PaperProps={stylePaperMenu}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={handleExportData}>
                      <Tooltip title="xlsx">
                        <img width={30} src={xlsxImage} />
                      </Tooltip>
                    </MenuItem>
                  </Menu>

                  <ProgressBarContract totalContracts={contract.totalLicencas} signedContracts={contract.licencasUltilizadas}/>

                </div>
              )
            }

      </div>

      </div>
      <DataTableExtensions data={dataFiltered} {...tableDataExtensions}>
        <DataTable data={dataFiltered} {...tableData}/>
      </DataTableExtensions>
    </div>
  );
}

export default BaseTable;
