import React, { useEffect, useContext } from 'react';
import './styles.scss';
import IaImage from '../../../../assets/images/squadia.png';
import { storage } from 'utils';
import { AuthContext } from 'contexts/appProvider';

export default function FirstAcessAi({ onRender, onClose }) {
  useEffect(() => {
    if (onRender) {
      onRender();
    }
  }, [onRender]);

  const { setActiveIA } = useContext(AuthContext);

  const submitButton = () => {
    onClose()
    storage.set('@exibirAssistente', false)
    setActiveIA(true)

  }

  return (
    <div className="aimodal-modal">
      <div className="aimodal-modal-content">
        <div className='header'>
          <div className='close'>
            <button className="close-button" onClick={() => onClose()} aria-label="Fechar">
              &times;
            </button>
          </div>
          <span className='title-header'><b>Novidade: SquadAI, a IA Generativa do SquadBI!</b></span>
        </div>
        <div className='image-div'>
          <img src={IaImage} alt="Tour" className="aimodal-image" />
        </div>
        <div className='text'>
          <div className='sub-title'> 
            <span className='welcome'> <b>Descubra o Futuro da Análise de Dados com IA Generativa: SquadAI</b> </span>
          </div>
          <p>
            Estamos empolgados em anunciar um novo recurso revolucionário na nossa ferramenta: IA Generativa! Agora, você pode elevar suas análises a outro nível, explorando insights com a agilidade e inteligência de uma IA avançada. 🚀
          </p>
        </div>
        <div className='button-container'>
          <button className="start-aimodal" onClick={submitButton}>
            Explorar
          </button>
        </div>
      </div>
    </div>
  );
}
