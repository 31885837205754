import './style.css'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NotFound() {

    const { t } = useTranslation();

    return (
        <div className='search-bar-not-found-items'>
            {t('search.notFound')}
        </div>
    )
}