import React from 'react';
import './index.css'

const Loader = () => {

  return (
    <div class="loader-container">
        <div class="loader"></div>
    </div>
  );
};

export default Loader;
