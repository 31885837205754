import './styles.scss';
import React from 'react';
import loader from '../../../assets/images/newloader.gif'
import { CheckOne } from '@icon-park/react';

export default function ShippingAssistantCard({status, onClose}) {
    return (
        <main className={`await-report-container`}>
            <i 
                onClick={onClose}
                className='mdi mdi-window-close close-btn'
            />
            <div className='await-report-content'>
                <div className='loader-section'>
                    {status === 0 ? <img src={loader} /> : <CheckOne theme="two-tone" size="24" fill={['#7ed321' ,'#ffffff']}/>}
                </div>
                <div className='informations'>
                    <p className='title'>
                        {status == 0 ? 'Seu assistente está sendo preparado' : 'Assistente pronto para uso'}
                    </p>
                    <p className='warn'>
                        {status == 0 ? 'o Squad IA está processando as informações do conjuto de dados' : 'Esse assistente está disponivel para ser usado a qualquer momento'}
                    </p>
                </div>
            </div>
            <div className='action-report-content'>
            </div>
        </main>
    )
}