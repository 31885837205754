import "./style.scss";
import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { Option } from "../../../../../../components/optionModern";

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';

import ptBR from "date-fns/locale/pt-BR";

export default function AreaInput({
  label,
  type,
  inputProps,
  disabled,
  isClearable,
  sync,
  onSync,
  transparent,
  modernSelect,
  margin
}) {
  if (type === "switch") {
    return (
      <main className="area-input-container switch-form" style={margin && {marginBottom: margin}}>
        <label>{label}</label>
        <Form.Group>
          <label class="switch">
            <input type="checkbox" checked={true} {...inputProps} />
            <span class="slider round" />
          </label>
        </Form.Group>
      </main>
    );
  }


  if (type === "select") {
    return (
      <main className="area-input-container-select" style={margin && {marginBottom: margin}}>
        <label>
          {label}
          {sync && (
            <Tooltip
              arrow
              placement="top"
              title="Sicronizar relatório ao workspace (Power BI Service)"
            >
              <i
                className="mdi mdi-sync"
                onClick={onSync}
                style={{ marginLeft: 5, cursor: "pointer", fontSize: 14 }}
              />
            </Tooltip>
          )}
        </label>
        <Select
          isClearable={isClearable}
          {...inputProps}
          components={modernSelect && {Option}}
          styles={modernSelect ? 
            {
              option: (provided, { isFocused }) => ({
                ...provided,
                backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                color: "#333",
                display: "flex",
                alignItems: "center",
              }),
              control: (provided) => ({
                ...provided,
                border: `1px solid  ${inputProps.error === true ? 'red' : '#888'}`
              })
            } 
            :
            {
              control: (provided) => ({
                ...provided,
                border: `1px solid  ${inputProps.error === true ? 'red' : '#888'}`
              })
            }
        }
        />
      </main>
    );
  }

  if (type === "color") {
    return (
      <main className="area-input-container" style={margin && {marginBottom: margin}}>
        <label>{label}</label>
        <input
          type="color"
          {...inputProps}
          disabled={disabled}
        />
      </main>
    );
  }

  if (type === "date") {
    return (
      <main className="area-input-container" style={margin && {marginBottom: margin}}>
        <label>{label}</label>
        <DatePicker
          selected={inputProps.value}
          onChange={(date) => inputProps.onChange(date)}
          dateFormat="dd/MM/yyyy"
          disabled={disabled}
          focusSelectedMonth
          className="form-control"
          locale={ptBR}
          {...inputProps}
        />
        {inputProps.error && <div className="error">{inputProps.error}</div>}
      </main>
    );
  }


  if (type === "selectReact") {
    return (
      <main className="area-input-container-select" style={margin && {marginBottom: margin}}>
        <label>
          {label}
          {sync && (
            <Tooltip
              arrow
              placement="top"
              title="Sicronizar relatório ao workspace (Power BI Service)"
            >
              <i
                className="mdi mdi-sync"
                onClick={onSync}
                style={{ marginLeft: 5, cursor: "pointer", fontSize: 14 }}
              />
            </Tooltip>
          )}
        </label>
        <Select
          components={{ Option }}
          isMulti
          allowSelectAll={true}
          hideSelectedOptions={false}
          styles={{
            option: (provided, { isFocused }) => ({
              ...provided,
              backgroundColor: isFocused ? "#DEEBFF" : "transparent",
              color: "#333",
              display: "flex",
              alignItems: "center",
              
            }),
          }}
          {...inputProps}
        />
      </main>
    );
  }

  return (
    <main className="area-input-container" style={margin && {marginBottom: margin}}>
      <label>{label}</label>
    <input
        {...inputProps}
        disabled={disabled}
        className={`${disabled ? "isDisabled" : ""} ${inputProps.error ? "errorBorder" : ""}`}
    />
    {inputProps.error && <div className="error">{inputProps.error}</div>}

      {inputProps.type === "password" ? (
        <div className="input-password">
          <i
            onClick={() => {
              inputProps.setMostrarSenha(!inputProps.mostrarSenha);

              let input = document.querySelector("#exampleInputPassword");
              if (input.getAttribute("type") === "password") {
                input.setAttribute("type", "text");
              } else {
                input.setAttribute("type", "password");
              }
            }}
            className={
              inputProps.mostrarSenha === true
                ? "mdi mdi-eye"
                : "mdi mdi-eye-off"
            }
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer",
              position: "absolute",
              fontSize: "20px",
            }}
          ></i>
        </div>
      ) : (
        ""
      )}
    </main>
  );
}
