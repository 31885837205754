export const Repeat = [
    {value: 'Atualizar',label: 'Depois da atualização de dados'},
    {value: 'Hora a hora', label: 'Hora a hora'},
    {value: 'Diariamente', label: 'Diariamente'},
    {value: 'Semanalmente', label: 'Semanalmente'},
    {value: 'Mensalmente', label: 'Mensalmente'}
]
export const Anexo = [
    {value: 'PDF', label: 'PDF (.pdf)'},
    {value: 'PPTX', label: 'Microsoft Power Point (.pptx)'}
]
export const rlsOptions = [
    {value: 0, label: 'Anexo sem RLS'},
    {value: 1, label: 'RLS atribuido nas permissões'},
]
export const Hour = Array.from({ length: 12 }, (_, index) => ({
    value: index + 1,
    label: index + 1,
}));
export const Minute = Array.from({ length: 6 }, (_, index) => ({
    value: index * 10,
    label: index * 10,
}));
export const DayOrNight = [
    {value: 'AM', label: 'AM'},
    {value: 'PM', label: 'PM'}
]
export const DaysInWeek = [
    {value: 1, label: 'Dom.'},
    {value: 2, label: 'Seg.'},
    {value: 3, label: 'Ter.'},
    {value: 4, label: 'Qua.'},
    {value: 5, label: 'Qui.'},
    {value: 6, label: 'Sex.'},
    {value: 7, label: 'Sáb.'}
]