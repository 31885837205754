import { useTranslation } from 'react-i18next';

const useAIData = () => {
  const { t } = useTranslation();

  const data = [
    [
      `1-IA Generativa`,
      "Lightning"
    ]
  ];

  return data;
};

export default useAIData;