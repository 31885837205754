import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./shared/Navbar";
// import Sidebar from "./shared/Sidebar";
import { Sidebar } from "./components";
import SettingsPanel from "./shared/SettingsPanel";
// import Footer from "./shared/Footer";
import AppProvider from "../contexts/appProvider";
import ReportsContextProvider from "../contexts/reportsContext"
import { withTranslation } from "react-i18next";
import { Backdrop } from "./components";
import { estaAutenticado } from "services/api/autenticacao";
import { storage } from "utils";
import { version } from '../../package.json'
import CacheBuster from 'react-cache-buster';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import '../utils/i18n'

class App extends Component {
  state = {};
  componentDidMount() {
    this.onRouteChanged();
  }
  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let sidebarComponent = storage.get('@tijuca-usuario') && !this.state.isFullPageLayout ? <Sidebar /> : "";
    let SettingsPanelComponent = !this.state.isFullPageLayout ? (
      <SettingsPanel />
    ) : (
      ""
    );
    let logged = estaAutenticado() && this.props.location.pathname.split("/")[1] !== "login"
    // let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    return (
      <CacheBuster
        currentVersion={version}
        isEnabled={true}
        loadingComponent={<></>}
      >
        <FluentProvider theme={teamsLightTheme}>
          <AppProvider>
            <ReportsContextProvider>
              {/* <AssistantContextProvider> */}
                <Backdrop />
                <div className="container-scroller">
                  {navbarComponent}
                  <div className={`container-fluid page-body-wrapper ${this.state.isFullPageLayout ? 'full-page-wrapper' : ''}`}>

                    {sidebarComponent}
                    <div className="main-panel">
                      <div className={logged ? "content-wrapper" : "content-route"}>
                        <div className="content-wrapper-item">
                          <AppRoutes />
                          {SettingsPanelComponent}
                        </div>
                      </div>
                      {/* {footerComponent} */}
                    </div>
                  </div>
                </div>
              {/* </AssistantContextProvider> */}
            </ReportsContextProvider>
          </AppProvider>
        </FluentProvider>
      </CacheBuster>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const body = document.querySelector("body");
    if (this.props.location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
      // i18n.changeLanguage('ar');
    } else {
      body.classList.remove("rtl");
      // i18n.changeLanguage("en");
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/login",
      "/login/webviewer",
      "/reportWebViewer",
      "/login/retrieveuser",
      "/login/recoverpassword",
      // "/dashboard/",
      "/appearance/",
      "/login/primeiroAcesso",
      "/user-pages/login-2",
      "/user-pages/register-1",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (
        this.props.location.pathname === fullPageLayoutRoutes[i] ||
        this.props.location.pathname.includes(fullPageLayoutRoutes[i])
      ) {
        this.setState({
          isFullPageLayout: true,
        });
        // document
        //   .querySelector(".page-body-wrapper")
        //   .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        // document
        //   .querySelector(".page-body-wrapper")
        //   .classList.remove("full-page-wrapper");
      }
    }
  }
}

export default withTranslation()(withRouter(App));
