import './style.scss'
import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import { Tooltip } from '@mui/material';
import api from 'services/api/api';
import powerPointImg from '../../../assets/images/powerpoint.png'

function CopyArea({value}) {

    const [isCopied, setIsCopied] = useState(false)

    function copyToClipboard() {
        try {
            navigator.clipboard.writeText(value)
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 2000)
        } catch (error) {
            console.error(error.message)
        }
    }

    return (
        <Tooltip title="Clique para copiar" arrow placement='left' onClick={copyToClipboard}>
            <div className='copy-area-powerpoint'>
                <p>{isCopied ? "Copiado para área de transferência" : value}</p>
                <i className={isCopied ? 'mdi mdi-check-circle' : 'mdi mdi-link-variant'}/>
            </div>
        </Tooltip>
    )
}

export default function ModalExportPowerPoint({onClose, id_relatorio, id_workspace}) {

    const getURL = window.location.host
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    async function handleRequestValues(){
        try {
            const response = await api.post("/tokenGenerate", {
                "id_relatorio": id_relatorio
            })    
            setIsLoading(false)
            setData(response.data)
        } catch (error) {
            console.error(error.message)
            setIsLoading(false)
        }
    }

    function formmatedDate(data) {
        const dataObj = new Date(data);
        const day = String(dataObj.getDate()).padStart(2, '0');
        const month = String(dataObj.getMonth() + 1).padStart(2, '0');
        const year = dataObj.getFullYear();
        const hora = String(dataObj.getHours()).padStart(2, '0');
        const minutos = String(dataObj.getMinutes()).padStart(2, '0');

        const value = `${day}/${month}/${year} - ${hora}:${minutos}`;
        return value
    }

    useEffect(() => {handleRequestValues()},[])

    return (
        <AnimatePresence>
            <motion.main 
                className="modal-export-powerpoint-container"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: .5, ease: 'easeOut' } }}
                exit={{ opacity: 0 }}    
            >
                <div className='modal-export-powerpoint-content'>
                    <i className='modal-export-powerpoint-close mdi mdi-close' onClick={onClose} />
                    <header className='modal-export-powerpoint-header'>
                        <div className='modal-export-powerpoint-header-titlearea'>
                            <img src={powerPointImg} style={{height: 40}} />
                            <p className='title'>Power Point</p>
                        </div>
                        <p className='subtitle'>Exportar relatório para Power Point</p>
                    </header>
                    <section className='modal-export-powerpoint-codes'>
                        <label>Link do Web Viewer</label>
                        <CopyArea value={`${getURL}/#/login/webviewer/${id_relatorio}`} />
                        <label>{`Código do relatório (Valido até: ${data && data.expired_at && formmatedDate(data.expired_at)})`}</label>
                        <CopyArea value={data.token} />  
                    </section>
                </div>
            </motion.main>
        </AnimatePresence>
    )
}