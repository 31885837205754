import './styles.scss'
import React, { useState } from 'react'
import {QRCodeSVG} from 'qrcode.react';
import SquadBI from '../../../assets/images/logo 1.svg'
import { useTranslation } from 'react-i18next';

export default function ModalDownloadApp({onClose}) {

    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false)
    const SquadBIApkLink = 'https://files.squadbi.app/files/apks/v1.0.5.apk'

    function copyToClipboard() {
        try {
            navigator.clipboard.writeText(SquadBIApkLink)
            setIsCopied(true)
            setTimeout(() => setIsCopied(false), 2000)
        } catch (error) {
            console.error(error.message)
        }
    }

    return (
        <main className='download-app-container'>
            <div className='download-app-content'>
                <i className='mdi mdi-close' style={{position: 'absolute', top: '10px', right: '10px', color: '#222', cursor: 'pointer', fontSize: 18}} onClick={() => onClose()} />
                <header>
                    <p className='title'>SquadBI Mobile</p>
                    <p className='description'>{t('downloadApp.description')}</p>
                </header>
                <div className='qr-code'>
                    <QRCodeSVG 
                        value={SquadBIApkLink}
                        imageRendering={SquadBI}
                        style={{width: '100%', height: '100%'}}
                        imageSettings={{
                            src: SquadBI,
                            height: 20,
                            width: 15,
                            excavate: true
                        }}
                    />
                </div>
                <div className='copy-code'>
                    <div className='head-copy-code'>
                        <i className='line-code' />
                            <p>{t('downloadApp.manual')}</p>
                        <i className='line-code' />
                    </div>
                    <div className='copy-code-content'>
                        <div className='click-to-copy' onClick={copyToClipboard}>
                            <p>{!isCopied ? t('downloadApp.click') : t('downloadApp.copied')}</p>
                        </div>
                        <div className='click-to-copy-icon' onClick={copyToClipboard}>
                            <i className='mdi mdi-content-copy' />
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}