import axios from 'axios'
import { pegarToken } from './autenticacao'

function renderBaseURL() {
  const getURL = window.location.host

  if (getURL.includes('acocearense')) {
    return 'https://api.squadbi.app/v1'
  } else if (getURL.includes('normatel')) {
    return 'https://api.squadbi.app/v1'
  } else if (getURL.includes('golden')) {
    return 'https://api-golden.squadbi.app/v1'
  } else if (getURL.includes('idt')) {
    return 'https://api-golden.squadbi.app/v1'
  } else if (getURL.includes('appc')) {
    return 'https://api.squadbi.app/v1'
  } else if (getURL.includes('gsd')) {
    return 'https://api-gsd.squadbi.app/v1'
  } else {
    return 'https://api.squadbi.app/v1'
  }
}

const api = axios.create({
  baseURL: renderBaseURL()
})

// interceptor de autenticação
api.interceptors.request.use(async config => {
  const token = pegarToken()

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

export default api