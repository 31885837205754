import './styles.scss'
import React from 'react'

export function AssistantBallon({filtredAssistants, onSelect, selected}) {
    return (
        <main className='ballon-select-assistant-msg'>
            <p>O Squad AI pode ajudar você a responder perguntas, concluir tarefas e maximizar a produtividade. No entanto, o Squad AI tem algumas limitações enquanto estiver em modo Preview.</p>
            <div className='assistants-list'>
                {
                    filtredAssistants && filtredAssistants.length > 0 ?
                        <>
                            <p>Selecione um dos assistentes abaixo para começar.</p>
                                {filtredAssistants.map((item) => {
                                    return (
                                        <span 
                                            key={item.id_ia} 
                                            onClick={() => onSelect(item.id_chatgpt)}
                                            className={`button-assistant ${selected === item.id_chatgpt && 'selected'}`} 
                                        >
                                            <i className='mdi mdi-assistant' />
                                            <p>{item.nome}</p>
                                        </span>
                                    )
                                })}
                        </>
                :
                    <p>Você não tem acesso a nenhum assistente.</p>
                }
            </div>
        </main>
    )
}