import './styles.scss';
import React, { useContext } from 'react';
import loader from '../../../assets/images/newloader.gif'
import { CheckOne, Error } from '@icon-park/react';
import { useHistory } from 'react-router-dom'
import { ReportsContext } from 'contexts/reportsContext';

export default function ShippingCard({status, idReport, EmbeddedReport, EmbeddedWorkspace, paginado, conflictError}) {

    const history = useHistory();
    const { clearStates } = useContext(ReportsContext)

    function handleNavigateToEdit() {
        if(idReport) {
            history.push(`/formulario/editarRelatorio/${idReport}`)
            clearStates()
        }
    }

    function handleCloseError() {
        clearStates()
    }

    function handleNavigateToViewReport() {
        history.push("/viewReport/"+ EmbeddedReport +"/"+ EmbeddedWorkspace +"/1/"+paginado);
        clearStates()
    }

    return (
        <main className={`await-report-container ${(status === ("waiting for user" || conflictError) && idReport && EmbeddedReport && EmbeddedWorkspace) ? "await-user" : ""}`}>
            {
                (status === "waiting for user" || conflictError) 
                && <i 
                        onClick={handleCloseError}
                        className='mdi mdi-window-close close-btn'
                    />
            }
            <div className='await-report-content'>
                <div className='loader-section'>
                    {!conflictError 
                        ? status === 'sending' ? <img src={loader} /> : <CheckOne theme="two-tone" size="24" fill={['#7ed321' ,'#ffffff']}/> 
                        : <Error theme="two-tone" size="24" fill={['#d0021b' ,'#ffffff']}/>
                    }
                </div>
                <div className='informations'>
                    <p className='title'>{!conflictError ? "Publicando seu relatório Power BI." : "Houve um error!"}</p>
                    <p className='warn'>
                        {!conflictError ? status === 'sending' 
                            ? "O relatório está sendo publicado no Power BI Service, o que pode levar alguns minutos..."
                            : "Seu relatório foi exportando com sucesso, continue com a parametrização."
                            : "Não foi possível publicar esse relatório, verifique contém duplicidade no Power BI Service."
                        }
                    </p>
                </div>
            </div>
            <div className='action-report-content'>
                {!conflictError
                    ? status === 'sending'
                        ?
                            <div className='cancel-btn'>
                                Cancelar
                            </div>
                        :
                            idReport && EmbeddedReport && EmbeddedWorkspace ?
                                <div className='success-all-btns'>
                                    <div className='success-btn gray' onClick={handleNavigateToViewReport}>
                                        <i className='mdi mdi-chart-bar' />
                                            Visualizar
                                    </div>
                                    <div className='success-btn' onClick={handleNavigateToEdit}>
                                        <i className='mdi mdi-pencil' />
                                        Parametrizar
                                    </div>
                                </div>
                            :
                            <></>
                    :
                        <div className='success-all-btns'>
                            <div className='success-btn error' onClick={handleCloseError}>
                                Voltar
                            </div>
                        </div>
                }
            </div>
        </main>
    )
}