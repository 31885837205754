import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import App from "./app/App";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003c7c',
    },
    secondary: {
      main: '#f44336',
    },
    statusSuccess: {
      main: '#4cd137',
    },
    statusError: {
      main: '#e84118'
    }
  }
});

ReactDOM.render(
  <HashRouter basename="/">
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
  </HashRouter>,
  document.getElementById("root")
);
