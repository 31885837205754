import { storage } from "utils";
import Connection from "../connection"
import "./style.css"
import React, { useContext } from "react"
import { useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from "contexts/appProvider";

export default function Item({ item, last, isSub, group }) {

    let history = useHistory();
    const user = storage.get('@tijuca-usuario');
    const path = storage.get('@pathAtual')

    let location = useLocation()
    let isActive = (location.pathname === `/viewReport/${item.embedded_relatorio}/${item.embedded_workspace}/${item.tipo}/${item.paginado}` || location.pathname === item.rota)

    async function activeItemReport() {
            if(item.rota) {
                handleSetHistory(item);
                history.push(`${item.rota}`)
                storage.set('@pathAtual', item.id)                
            } else {
                if(!item.paginado) item.paginado = "0";
                handleSetHistory(item);
                history.push(`/viewReport/${item.embedded_relatorio}/${item.embedded_workspace}/${item.tipo}/${item.paginado}`)
                storage.set('@pathAtual', item.id)
            }
    }

    function padItemWithNulls(item) {
        const keysToEnsure = ["id", "nome", "embedded_relatorio", "embedded_workspace", "paginado", "tipo", "rota", "icon", "grupo_negocio", "history"];
      
        for (const key of keysToEnsure) {
          if (!item.hasOwnProperty(key)) {
            item[key] = null;
          }
        }
      
        return item;
    }      

    function handleSetHistory(item) {
        let history = storage.get('@user_history') || [];
    
        const existingIndex = history.findIndex((historyItem) => historyItem.embedded_relatorio === item.embedded_relatorio);
    
        if (existingIndex !== -1) {
            history[existingIndex].history = new Date();
            history[existingIndex].grupo_negocio = group;
        } else {
            item.history = new Date();
            item.grupo_negocio = group;
            history.unshift(item);
        }
    
        storage.set('@user_history', history);
    }

    const getURL = window.location.host
    const redeSim = ['jucisrs', 'jucea', 'jucerr', 'jucap', 'juceac', 'jucisdf', 'jucems', 'jucec']

    return (
        <div className={user && (redeSim.includes(storage.get('@codEmpresa')) || getURL.includes("bi-redesim")) ? isSub ? "group-report-item-sim" : "report-item" : isSub ? "group-report-item" : "report-item"}>
            {user && (redeSim.includes(storage.get('@codEmpresa')) || getURL.includes("bi-redesim")) ? <span className={isSub ? "space-sub-group-sim" : "space-sim"} /> : <span className={isSub ? "space-sub-group" : "space"} />}
            <Connection last={last} />
            <div className="report-item-info"  onClick={activeItemReport}>
                {user && (redeSim.includes(storage.get('@codEmpresa')) || getURL.includes("bi-redesim")) 
                    ?
                        <></>
                    :
                        <div className="report-item-info-icon">
                            <i className={`mdi mdi-file-chart ${path === item.id ? "group-icon-ative" : "group-icon"}  `} />
                        </div>
                }
                <div className="report-item-info-name">
                    <p 
                        className={user && (redeSim.includes(storage.get('@codEmpresa')) || getURL.includes("bi-redesim")) ? 'name-report-sim-min' : path === item.id ? "name-report-active" : "name-report"}
                        title={item.nome_exibicao && (item.nome_exibicao != 'null') ? item.nome_exibicao : item.nome}
                    >
                        {item.nome_exibicao && (item.nome_exibicao != 'null') ? item.nome_exibicao : item.nome}
                    </p>
                </div>
            </div>
        </div>
    )
}