import { useTranslation } from 'react-i18next'

const useAssinaturasData = () => {
  const { t } = useTranslation()

  const data = [[`1-${t('sidebar.assinaturas')}`, 'FileEditingOne']]

  return data
};

export default useAssinaturasData
