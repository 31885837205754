import '../style.scss'
import Swal from "sweetalert2"
import Select from 'react-select'
import { Option } from '../../optionModern'
import { ToggleSwitch } from 'app/components';
import { Anexo, DaysInWeek, Minute, Repeat, rlsOptions } from './data';
import React, { useEffect, useState } from 'react'
import pdf from '../../../../assets/images/pdf-image.png'
import { Delete, Down, Return, Up } from '@icon-park/react'
import powerpoint from '../../../../assets/images/powerpoint.png'
import { Tooltip } from '@mui/material';

function SignatureForm({item, allUsers, expandend, setExpandend, handleRemoveSignature, handleUpdateSignature, handleSendInstantSignature, isLockOtherEmails, reportHasRLS, rlsRules}) {
    const [defaultHour, setDefaultHour] = useState(10);
    const [defaultMinute, setDefaultMinute] = useState(0);

    const [removeSignature, setRemoveSignature] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])

    const [allPages, setAllPages] = useState([])

    const [dayMonthInputError, setDayMonthInputError] = useState(true)

    const [daysWeek, setDaysWeek] = useState();
    const [daysMonth, setDaysMonth] = useState();

    const [isShowOthersEmails, setIsShowOthersEmails] = useState(true);
    const [otherEmailExpand, setOtherEmailExpand] = useState(null);
    const [othersEmails, setOthersEmails] = useState([]); 
    const [othersEmailsInput, setOthersEmailsInput] = useState('')

    function handleChangeUsers(values) {

        const selectedValues = values.map(item => {
          if (typeof item === 'object' && 'value' in item) {
            return item.value;
          }
          return item;
        });
      
        const valuesSelected = allUsers
            .filter(user => selectedValues.includes(user.value))
            .map(user => ({
                value: user.value,
                label: user.label
        }));
      
        setSelectedUsers(valuesSelected);
        handleUpdateSignature('usuarios', selectedValues);
    }

    async function handleGetAllReportPages() {
        try {
            let pagesArray = new Array;
            const pages = await window.report.getPages();
            pages.forEach(function (page) {
                if(page.visibility === 0) {
                    pagesArray.push({name: page.name, displayName: page.displayName})
                }
            });
            setAllPages(pagesArray)
        }
        catch (error) {
            console.log(error);
            await new Promise(resolve => setTimeout(resolve, 5000));
            handleGetAllReportPages()
        }
    }

    function handleSetDefaultsUsers() {
        if(item.usuarios !== null) {
            const Users = allUsers
            .filter(user => item.usuarios.includes(user.value))
            .map(user => ({
                value: user.value,
                label: user.label
            }));
            setSelectedUsers(Users)
        }
    }

    function padZero(number) {
        return number.toString().padStart(2, '0');
    }

    function handleChangeTime(value, section) {
        const [currentHour, currentMinute] = item.hora_agendada.split(':').map(Number);
        let updatedHoraAgendada = '';
      
        switch (section) {
          case 'hour':
            updatedHoraAgendada = `${padZero(value)}:${padZero(currentMinute)}:00`;
            setDefaultHour(value);
            break;
          case 'minute':
            updatedHoraAgendada = `${padZero(currentHour)}:${padZero(value)}:00`;
            setDefaultMinute(value);
            break;
          default:
            return;
        }
      
        handleUpdateSignature('hora_agendada', updatedHoraAgendada);
    }

    function handleSetDefaultHours() {
        const [currentHour, currentMinute] = item.hora_agendada.split(':').map(Number);
        setDefaultHour(currentHour);
        setDefaultMinute(currentMinute);
    }

    function handleSetDefaultEmails() {
        const emails = item.othersEmails;
        setOthersEmails(emails)
    }

    function changePage(item) {
        handleUpdateSignature('nome_pagina', item);
    }

    function changePreview() {
        if(item.reportPreview == 0) {
            handleUpdateSignature('nome_preview', allPages.length > 0 ? allPages[0].name : '');
            handleUpdateSignature('reportPreview', 1)
        } else {
            handleUpdateSignature('nome_preview', '');
            handleUpdateSignature('reportPreview', 0)
        }
    }

    async function changeApplyUsersFilters() {
        if(item.usersFilters == 0) {
            const capturedBookmark = await window.report.bookmarksManager.capture({allPages: true, personalizeVisuals: true});
            handleUpdateSignature('codeBookmarkFilters', capturedBookmark.state);
            handleUpdateSignature('usersFilters', 1)
        } else {
            handleUpdateSignature('codeBookmarkFilters', '');
            handleUpdateSignature('usersFilters', 0)
        }
    }

    function changeRepeatValue(value) {
        if(value === 'Diariamente' || value === 'Atualizar') {
            handleUpdateSignature('repetir', value)
            handleUpdateSignature('dia_semana', '')
            handleUpdateSignature('dia_mes', '')
        } else if (value === 'Hora a hora' || value === 'Semanalmente') {
            handleUpdateSignature('repetir', value)
            handleUpdateSignature('dia_semana', [1,2,3,4,5,6,7])
            handleUpdateSignature('dia_mes', '')
        } else if (value === 'Mensalmente') {
            handleUpdateSignature('repetir', value)
            handleUpdateSignature('dia_semana', '')
            handleUpdateSignature('dia_mes', [])
        }
    }

    function changeDayInWeek(value) {
        const updatedDays = item.dia_semana.includes(value)
            ? item.dia_semana.filter(day => day !== value)
            : [...item.dia_semana, value];
    
        const sortedDays = updatedDays.sort((a, b) => a - b);
    
        handleUpdateSignature('dia_semana', sortedDays);
    }
    
    function changeDayInMonth(value) {
        if (value === '' || /^[0-9,-]+$/.test(value)) {
            const diasArray = parseDias(value);
            handleUpdateSignature('dia_mes', diasArray);
            setDaysMonth(value)
            setDayMonthInputError(false)
        } else {
            setDayMonthInputError(true);
        }
    }    

    function parseDias(input) {
        const dias = [];
        const partes = input.split(',');
    
        partes.forEach((parte) => {
            if (parte.includes('-')) {
                const intervalo = parte.split('-');
                const inicio = parseInt(intervalo[0], 10);
                const fim = parseInt(intervalo[1], 10);
    
                for (let i = inicio; i <= fim; i++) {
                    dias.push(i);
                }
            } else {
                dias.push(parseInt(parte, 10));
            }
        });
    
        return dias.sort((a, b) => a - b);
    }

    function isValidEmailAddress(address) {
        return !! address.match(/.+@.+/);
    }

    function changeOthersEmails(value) {
        const isValid = isValidEmailAddress(value);
    
        if (isValid) {
            const index = othersEmails.findIndex(obj => obj.email === value);
    
            if (index !== -1) {
                const updatedUsersGroupsActive = othersEmails.filter(obj => obj.email !== value);
                setOthersEmails(updatedUsersGroupsActive);
                handleUpdateSignature('othersEmails', updatedUsersGroupsActive || []);
            } else {
                const updatedUsersGroupsActive = othersEmails.concat({ email: value, rls: [] });
                setOthersEmails(updatedUsersGroupsActive);
                handleUpdateSignature('othersEmails', updatedUsersGroupsActive || []);
            }
    
            setOthersEmailsInput('');
    
        } else {
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "warning",
                title: "Esse e-mail não é válido!",
                showConfirmButton: false,
                timer: 5000
            });
        }
    }  
    
    function changeOthersEmailsRLS(email, value) {
        const currentOthersEmails = item.othersEmails;
    
        const normalizedEmails = currentOthersEmails.map(item => {
            if (typeof item === 'string') {
                return { email: item, rls: [] };
            }
            return item;
        });
    
        const emailIndex = normalizedEmails.findIndex(obj => obj.email === email);
    
        if (emailIndex !== -1) {
            const emailObject = normalizedEmails[emailIndex];
            const rlsIndex = emailObject.rls.indexOf(value);
    
            if (rlsIndex === -1) {
                emailObject.rls.push(value);
            } else {
                emailObject.rls.splice(rlsIndex, 1);
            }
    
            normalizedEmails[emailIndex] = emailObject;
        } else {
            normalizedEmails.push({ email: email, rls: [value] });
        }
    
        handleUpdateSignature('othersEmails', normalizedEmails);
    }

    function resetRLS() {
        const updatedEmails = othersEmails.map(obj => ({
            ...obj,
            rls: []
        }));
    
        setOthersEmails(updatedEmails);    
        handleUpdateSignature('othersEmails', updatedEmails || []);
    }

    useEffect(() => { 
        if(typeof(item.dia_mes) !== "string") {
            setDaysMonth(item.dia_mes.toString()) 
            setDayMonthInputError(false)
        }

        if(typeof(item.dia_semana) !== "string" && typeof(item.dia_semana[0]) === "string") {
            var numberArray = item.dia_semana.map(function(str) {
                return parseInt(str, 10);
            });
            handleUpdateSignature('dia_semana', numberArray)
        }

        handleGetAllReportPages()
        handleSetDefaultsUsers() 
        handleSetDefaultHours()
        handleSetDefaultEmails()
    },[])

    return (
        <div className='signature-form'>
            <header className='signature-form-header'>
                <div className='down-bar'>
                    {expandend 
                        ? <Up theme="filled" size="18" fill="#777" style={{cursor: "pointer"}} onClick={() => setExpandend(item)} />
                        : <Down theme="filled" size="18" fill="#777" style={{cursor: "pointer"}} onClick={() => setExpandend(item)} />
                    }
                    <p>{item.descricao || 'Assinatura sem nome'}</p>
                </div>
                <div className='items'>
                    {!removeSignature ? 
                        <Delete theme="outline" size="18" fill="#777" style={{cursor: "pointer"}} onClick={() => setRemoveSignature(true)} />
                        :
                        <>
                            <div className='btn-signature-confirm-delete' onClick={handleRemoveSignature}>Deseja excluir essa assinatura?</div>
                            <Return theme="outline" size="18" fill="#777" style={{cursor: "pointer"}} onClick={() => setRemoveSignature(false)}/>
                        </>
                    }
                </div>
            </header>
            {expandend &&
                <div className='signature-form-content'>
                    <section className='section-signature'>
                        <label className='section-label'>Informações gerais</label>
                        <i className='line-section' />
                    </section>
                    <div className='signature-simple-field'>
                        <label>Nome da assinatura</label>
                        <input defaultValue={item.descricao} onBlur={(e) => handleUpdateSignature('descricao', e.target.value)} />
                    </div>
                    <div className='signature-simple-field'>
                        <label>Assunto do e-mail (opcional)</label>
                        <input placeholder='assunto' defaultValue={item.assunto_email} onBlur={(e) => handleUpdateSignature('assunto_email', e.target.value)} />
                    </div>
                    <div className='signature-simple-field'>
                        <label>Mensagem do e-mail (opcional)</label>
                        <input placeholder="Inclua uma mensagem opcional" defaultValue={(item && item.mensagem_email) ? item.mensagem_email : ''} onBlur={(e) => handleUpdateSignature('mensagem_email', e.target.value)} />
                    </div>
                    <section className='section-signature'>
                        <label className='section-label'>{reportHasRLS ? 'Destinatários' : 'Definições dos destinatários'}</label>
                        <i className='line-section' />
                    </section>
                    
                    <div className='signature-simple-field'>
                        <label>Usuários destinatários *</label>
                        <Select
                            isMulti
                            placeholder={
                                selectedUsers.length === 1 
                                ? `${selectedUsers.length} Usuário selecionado` 
                                : selectedUsers.length > 1 ? `${selectedUsers.length} Usuários selecionados`
                                : `Selecionar usuários...`
                            }
                            components={{ Option }}
                            isOptionDisabled={(option) => option.disabled}
                            controlShouldRenderValue={false}
                            closeMenuOnSelect={false}
                            noOptionsMessage={() => "Nenhum usuário encontrado"}
                            options={
                                reportHasRLS 
                                ?
                                [...new Set(rlsRules.reduce((acc, curr) => {
                                        const filteredUsers = allUsers.filter(user => curr.usuarios.includes(user.value) || curr.grupos.includes(user.grupo_usuario));
                                        const disabledUsers = allUsers.map(user => filteredUsers.some(filteredUser => filteredUser.value === user.value) ? user : {...user, disabled: true});
                                    return acc.concat(filteredUsers);
                                }, []))]
                                : 
                                    allUsers
                            }
                            value={selectedUsers}
                            allowSelectAll={true}
                            hideSelectedOptions={false}
                            maxHeight={38}
                            onChange={values => handleChangeUsers(values)}
                            styles={{
                                option: (provided, { isFocused }) => ({
                                    ...provided,
                                    backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                                    color: "#333",
                                    display: "flex",
                                    alignItems: "center",
                                }),
                            }}
                        />
                    </div>
                    {!isLockOtherEmails &&
                        <>
                            <div className='signature-simple-field'>
                                <label>Destinatários fora da organização</label>
                                <div className='signature-field-flex'>
                                    {
                                        othersEmails && othersEmails.length > 0 &&
                                            <div className='ocult-btn' onClick={() => setIsShowOthersEmails(!isShowOthersEmails)}>
                                                <p 
                                                    className='ocult-btn-word'
                                                >
                                                    {isShowOthersEmails ? `Ocultar e-mails externos (${othersEmails.length})` : `Mostrar e-mails externos (${othersEmails.length})`}
                                                </p>
                                                <i className={isShowOthersEmails ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'} />
                                            </div>
                                    }
                                    {
                                        othersEmails && othersEmails.length > 0 && isShowOthersEmails &&
                                            <div className='signature-other-emails-list'>
                                                {othersEmails.map(othersEmailItem => (
                                                    <div className='other-email-item'>
                                                        <div className='other-email-item-upper'>
                                                            <div className='other-email-item-left'>
                                                                <Tooltip title='Remover' placement='left' arrow>
                                                                    <i className='mdi mdi-close' onClick={() => changeOthersEmails(othersEmailItem.email)} />
                                                                </Tooltip>
                                                                <div className='other-email-item-left-words'>
                                                                    <p key={othersEmailItem.email}>{othersEmailItem.email}</p>
                                                                    {reportHasRLS ? 
                                                                        <p className='left-words-rls'>
                                                                            {(() => {
                                                                                if (othersEmailItem.rls && othersEmailItem.rls.length > 0) {
                                                                                const selectedIds = othersEmailItem.rls;
                                                                                const selectedItems = rlsRules.filter(rule => selectedIds.includes(rule.id));
                                                                                const names = selectedItems.map(rule => rule.nome);

                                                                                if (names.length === 1) {
                                                                                    return `Regra: ${names[0]}`;
                                                                                } else if (names.length === 2) {
                                                                                    return `Regras: ${names.join(' e ')}`;
                                                                                } else {
                                                                                    const lastItem = names.pop();
                                                                                    return `Regras: ${names.join(', ')} e ${lastItem}`;
                                                                                }
                                                                                } else {
                                                                                return "Nenhuma regra selecionada";
                                                                                }
                                                                            })()}
                                                                        </p>
                                                                    :
                                                                     <></>
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                reportHasRLS ?
                                                                    <Tooltip title='Editar RLS'>
                                                                        <i 
                                                                            onClick={
                                                                                () => otherEmailExpand === othersEmailItem.email ? setOtherEmailExpand(null) : setOtherEmailExpand(othersEmailItem.email)
                                                                            } 
                                                                            className={
                                                                                otherEmailExpand === othersEmailItem.email ? 'mdi mdi-menu-up' : 'mdi mdi-menu-down'
                                                                            }
                                                                        />
                                                                    </Tooltip>
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        {
                                                           reportHasRLS && otherEmailExpand === othersEmailItem.email ? 
                                                                <div className='other-email-item-down'>
                                                                    {rlsRules.map((rls) => {
                                                                        return (
                                                                            <div key={rls.id} className='other-email-rls-item' onClick={() => changeOthersEmailsRLS(othersEmailItem.email, rls.id)}>
                                                                                <i 
                                                                                    className={othersEmailItem.rls.includes(rls.id) ? 'mdi mdi-checkbox-marked' : 'mdi mdi-checkbox-blank-outline'}
                                                                                />
                                                                                <p>{rls.nome}</p>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                ))}
                                            </div>
                                    }
                                    <div className='signature-field-flex-input-area'>    
                                        <input 
                                            type='text'
                                            placeholder="Email do destinatário"
                                            // defaultValue={(item && item.mensagem_email) ? item.mensagem_email : ''}
                                            // onBlur={(e) => handleUpdateSignature('mensagem_email', e.target.value)}
                                            value={othersEmailsInput}
                                            onChange={(e) => setOthersEmailsInput(e.target.value.trim())}
                                        />
                                        <div className='btn-new-email' onClick={() => changeOthersEmails(othersEmailsInput)}>
                                            <i className='mdi mdi-plus' />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='warn-signature-item'>
                                    <i className='mdi mdi-information' />
                                    <p>É necessário escolher as regras RLS dos e-mails externos.</p>
                                </div> */}
                            </div>
                        </>
                    }
                    <section className='section-signature'>
                        <label className='section-label'>Data e hora agendadas *</label>
                        <i className='line-section' />
                    </section>
                    <div className='signature-double-field'>
                        <div className='signature-double-child-field'>
                            <label>Data de inicio *</label>
                            <input 
                                type='date' 
                                defaultValue={item.data_inicio} 
                                onBlur={(e) => handleUpdateSignature('data_inicio', e.target.value)} 
                            />
                        </div>
                        <div className='signature-double-child-field'>
                            <label>Data de fim *</label>
                            <input 
                                type='date' 
                                defaultValue={item.data_final} 
                                onBlur={(e) => handleUpdateSignature('data_final', e.target.value)} 
                            />
                        </div>
                    </div>
                    
                    <div className='signature-simple-field'>
                        <label>Repetir *</label>
                        <select
                            onChange={e => changeRepeatValue(e.target.value)}
                            value={!item.repetir ? 'Diariamente' : item.repetir}
                        >
                            {Repeat.map((item, key) => {
                                return <option 
                                            key={key} 
                                            value={item.value}
                                        >
                                                {item.label}
                                        </option>
                            })}
                        </select>
                    </div>
                    {
                        (item.repetir == 'Hora a hora' || item.repetir == 'Semanalmente') && 
                        <div className='signature-simple-field'>
                            <label>Ativa em: *</label>
                            <div className='signature-active-days-content'>
                                {DaysInWeek.map((i) => {
                                    return (
                                        <div 
                                            className={(item.dia_semana && item.dia_semana.includes(i.value)) ? 'item-day' : 'item-day-off'}
                                            key={i.value}
                                            onClick={() => changeDayInWeek(i.value)}
                                        >
                                            {i.label}
                                        </div>   
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {
                        item.repetir == 'Mensalmente' &&
                        <div className='signature-simple-field'>
                            <label>Todos os meses em dia(s) *</label>
                            <input 
                                placeholder='exemplo 1,27-30' 
                                onChange={e => changeDayInMonth(e.target.value)} 
                                value={daysMonth && daysMonth} 
                            />
                            {dayMonthInputError && <label className='second'>Introduza os dias ou intervalo de dias válidos (ex.: 1,27–30).</label>}
                        </div>
                    }
                    {item.repetir != 'Atualizar' &&
                        <div className='signature-triple-field'>
                            <label>Hora agendada *</label>
                            <div className='signature-triple-child-field'>
                                <select onChange={e => handleChangeTime(e.target.value, 'hour')} value={defaultHour}>
                                {Array.from({ length: 24 }, (_, i) => (
                                    <option key={i} value={i}>
                                    {i}
                                    </option>
                                ))}
                                </select>
                                <select onChange={e => handleChangeTime(e.target.value, 'minute')} value={defaultMinute}>
                                    {Minute.map((item, key) => {
                                        return <option key={key} value={item.value}>{item.label}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                    }
                    {/* <div className='signature-btn-locale'>
                        <div className='signature-triple-child-field'>
                            {item.id_agendamento &&
                                <div className='signature-field-btn-sucess' onClick={() => handleSendInstantSignature(item.id_agendamento)}>
                                    Enviar agora
                                </div>
                            }
                        </div>
                    </div> */}
                </div>
            }    
        </div>
    )
}

export default React.memo(SignatureForm)