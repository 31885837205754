import "./style.scss";
import Select from "react-select";
import { Option } from "../optionModern";
import React, { Component } from "react";
import RlsItemUser from "./rlsItemUser";

class RlsUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUsers: [],
      usuariosAgrupados: [],
      selectedGroups: [],
    };
  }

  componentDidMount() {
    this.FiltarUsuarios();
  }

  FiltarUsuarios = () => {
    const { item, allUsers, allInfoUsers, groupUsers } = this.props;

    if (item) {
      const response = item.usuarios
        .filter((usuarioId) =>
          allUsers.some((user) => user.value === usuarioId)
        )
        .map((usuarioId) => allUsers.find((user) => user.value === usuarioId));

      this.setState({ selectedUsers: response });

      if (item.grupos && item.grupos.length > 0) {
        const groups = item.grupos
          .filter((groupId) =>
            groupUsers.some((group) => group.value === groupId)
          )
          .map((groupId) => {
            const group = groupUsers.find((group) => group.value === groupId);
            return { ...group, group: true };
          });

        this.setState({ selectedGroups: groups });
      }

      const responsePegarGrupos = this.pegarGrupoUsuario(
        response,
        allInfoUsers
      );
      this.setState({ usuariosAgrupados: responsePegarGrupos });
    }
  };

  atualizarItem = (usuarios) => {
    const { index, atualizarItemRlsAtual } = this.props;
    atualizarItemRlsAtual(index, usuarios, null, null);
  };

  atualizarGrupos = (grupos) => {
    const { index, atualizarItemGruposRlsAtual } = this.props;
    atualizarItemGruposRlsAtual(index, grupos, null, null);
  };

  pegarGrupoUsuario = (selectedUsers, allInfoUsers) => {
    const selectedUserDetails = selectedUsers.map((selectedUser) => {
      if (Array.isArray(allInfoUsers)) {
        const matchingUser = allInfoUsers.find(
          (userInfo) => userInfo.id_usuario === selectedUser.value
        );

        if (matchingUser) {
          return {
            label: selectedUser.label,
            value: selectedUser.value,
            gruposusuario: matchingUser.gruposusuario,
          };
        }
      }

      return null;
    });

    return selectedUserDetails;
  };

  UsuariosFiltrados = () => {
    const { allUsers, otherItems } = this.props;
    return allUsers.filter(
      (user) =>
        !otherItems.some((rlsItem) => rlsItem.usuarios.includes(user.value))
    );
  };

  GruposFiltrados = () => {
    const { groupUsers, otherItems } = this.props;
    return groupUsers.filter(
      (group) =>
        !otherItems.some((rlsItem) => rlsItem.grupos.includes(group.value))
    );
  };

  removerUsuarioInputCheck = (id) => {
    const selectedUsersCopia = [...this.state.selectedUsers];
    const index = selectedUsersCopia.findIndex((user) => user.value === id);

    if (index !== -1) {
      selectedUsersCopia.splice(index, 1);

      this.setState({ selectedUsers: selectedUsersCopia }, () => {
        const updatedUsers = this.pegarGrupoUsuario(
          selectedUsersCopia,
          this.props.allInfoUsers
        );

        const selectedValues = selectedUsersCopia.map((user) => user.value);

        this.setState({ usuariosAgrupados: updatedUsers }, () => {
          this.atualizarItem(selectedValues);
        });
      });
    }
  };

  removerGrupoInputCheck = (id) => {
    const selectedGroupsCopia = [...this.state.selectedGroups];
    const index = selectedGroupsCopia.findIndex((group) => group.value === id);

    if (index !== -1) {
      selectedGroupsCopia.splice(index, 1);

      this.setState({ selectedGroups: selectedGroupsCopia });
      const selectedGroupsValues = selectedGroupsCopia.map(
        (user) => user.value
      );
      this.atualizarGrupos(selectedGroupsValues);
    }
  };

  render() {
    const { selectedUsers, usuariosAgrupados, selectedGroups } = this.state;
    const { item, allInfoUsers, groupUsers } = this.props;

    // const UsuariosFiltrados = this.UsuariosFiltrados();
    const GruposFiltrados = this.GruposFiltrados();

    const gruposDeUsuarios = {};

    if (usuariosAgrupados) {
      usuariosAgrupados.forEach((usuario) => {
        const grupo = usuario.gruposusuario;
        if (!gruposDeUsuarios[grupo]) {
          gruposDeUsuarios[grupo] = [];
        }
        gruposDeUsuarios[grupo].push(usuario);
      });
    }

    return (
      <div className="rls-users-container">
        <header className="rls-users-container-header">
          <p>{`${this.props.t('reportsPage.edit.access.values.index')} (${selectedUsers && selectedUsers.length})`}</p>
          <Select
            multi
            placeholder={this.props.t('reportsPage.edit.access.values.placeholder')}
            closeMenuOnSelect={false}
            noOptionsMessage={() => this.props.t('form.notFound')}
            components={{ Option }}
            options={[
              ...this.props.allUsers,
              ...groupUsers.map((item) => ({
                label: item.label,
                value: item.value,
                group: true,
                status: item.status,
              })),
            ]}
            value={[...selectedUsers, ...selectedGroups]}
            isMulti
            controlShouldRenderValue={false}
            allowSelectAll={true}
            hideSelectedOptions={false}
            onChange={(selectedOptions) => {
              if (selectedOptions) {
                // filtrar usuário e/ou grupos de acordo com o "group: true"
                const selectedGroups = selectedOptions
                  .filter((item) => item.group)
                  .map((option) => option.value);
                const selectedUsers = selectedOptions
                  .filter((item) => !item.group)
                  .map((option) => option.value);

                // groups
                this.setState({
                  selectedGroups: selectedOptions.filter((item) => item.group),
                });
                this.atualizarGrupos(selectedGroups);

                // users
                this.setState({
                  selectedUsers: selectedOptions.filter((item) => !item.group),
                });
                const updatedUsers = this.pegarGrupoUsuario(
                  selectedOptions.filter((item) => !item.group),
                  allInfoUsers
                );
                this.setState({ usuariosAgrupados: updatedUsers });
                this.atualizarItem(selectedUsers);
              } else {
                this.setState({ selectedUsers: [] });
                this.setState({ usuariosAgrupados: [] });
                this.setState({ selectedGroups: [] });
                this.atualizarItem([]);
              }
            }}
            styles={{
              option: (provided, { isFocused }) => ({
                ...provided,
                backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                color: "#333",
                display: "flex",
                alignItem: "center",
              }),
            }}
          />
          {/* <Select
            multi
            placeholder="Adicionar grupos"
            closeMenuOnSelect={false}
            noOptionsMessage={() => "Nenhum grupo encontrado"}
            components={{ Option }}
            options={groupUsers}
            value={selectedGroups}
            isMulti
            controlShouldRenderValue={false}
            allowSelectAll={true}
            hideSelectedOptions={false}
            onChange={(selectedItem) => {
              if(selectedItem) {
                const selectedValues = selectedItem.map((option) => option.value);
                this.setState({selectedGroups: selectedItem})
                this.atualizarGrupos(selectedValues)
              } else {
                this.setState({selectedGroups: []})
              }
            }}
            styles={{
              option: (provided, { isFocused }) => ({
                ...provided,
                backgroundColor: isFocused ? "#DEEBFF" : "transparent",
                color: "#333",
                display: "flex",
                alignItem: "center",
              }),
            }}
          /> */}
        </header>
        <section className="rls-users-container-content">
          {selectedUsers.length > 0 && <p className="label-info">{this.props.t('users')}</p>}
          {selectedUsers
            .slice()
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((item) => {
              return (
                <RlsItemUser
                  usuario={item}
                  key={item.value}
                  removerItem={(id) => this.removerUsuarioInputCheck(id)}
                />
              );
            })}
          {/* {Object.entries(gruposDeUsuarios)
            .sort(([grupoA], [grupoB]) => grupoA.localeCompare(grupoB))
            .map(([grupo, usuarios]) => (
              <RlsItemGrups grupo={grupo} usuarios={usuarios} key={grupo} removerItem={(id) => this.removerUsuarioInputCheck(id)} />
            ))
          } */}
          {this.state.usuariosAgrupados.length === 0 &&
            selectedGroups.length === 0 &&
            this.props.t('reportsPage.edit.access.values.empty')}
          {selectedGroups.length > 0 && (
            <p
              className={
                this.state.usuariosAgrupados.length === 0
                  ? "label-info"
                  : "label-info-group"
              }
            >
              {this.props.t('groups')}
            </p>
          )}
          {selectedGroups.map((item) => {
            return (
              <RlsItemUser
                usuario={item}
                key={item.value}
                removerItem={(id) => this.removerGrupoInputCheck(id)}
              />
            );
          })}
        </section>
      </div>
    );
  }
}

export default RlsUsers;
