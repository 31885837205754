import React, { Component } from 'react';
import { Tooltip } from '@mui/material';

class rlsItemUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: true,
    };
  }

  render() {
    const { isActive } = this.state;
    const { usuario, removerItem } = this.props;
    
    return (
      <main className="user-container-rls-item" key={usuario.value}>
          <p>{usuario.label}</p>
          <i className='mdi mdi-close' onClick={() => removerItem(usuario.value)}/>
      </main>
    );
  }
}

export default rlsItemUser;