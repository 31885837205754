import './styles.scss'
import React from 'react'

export function ModalDatasetError({ message, onClose }) {

    let parsedMessage;
    
    try {
        parsedMessage = JSON.parse(message);
        
        if (typeof parsedMessage === 'string') {
            parsedMessage = JSON.parse(parsedMessage);
        }
    } catch (error) {
        console.error('Erro ao fazer o JSON.parse:', error);
        parsedMessage = message;
    }

    return (
        <main className='modal-dataset-error-container'>
            <div className='modal-dataset-error-content'>
                <header className='dataset-error-header'>
                    <h1 className='title'>Algo não deu certo</h1>
                    <p className='description'>Erro durante o processamento de dados no conjunto de dados.</p>
                </header>
                <section className='dataset-error-view'>
                    <pre>
                        {parsedMessage && JSON.stringify(parsedMessage, null, 2)}
                    </pre>
                </section>  
                <footer className='dataset-error-action'>
                    <div className='dataset-error-btn' onClick={onClose}>
                       <p>Fechar</p> 
                    </div>
                </footer>
            </div>
        </main>
    )
}
