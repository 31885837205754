import './style.css'
import React from 'react'
import { Tooltip } from '@mui/material'
import * as Icon from '@icon-park/react';
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

export default function History({historyItems, reload}) {

    const history = useHistory();
    const { t } = useTranslation();

    return (
        <div className='history-container-search-bar'>
            <div className='history-title-search-bar'>
                {React.createElement(Icon.History, { theme: "outline", size: 16, fill: "#222" })}
                <p>{t('home.user.recents')}</p>
            </div>
            {
                historyItems.map((value, key) => {
                    const handleClick = () => {
                        if (value.rota !== null && value.rota !== '') {
                            history.push(value.rota);
                            reload()
                        } else {
                            history.push(`/viewReport/${value.embedded_relatorio}/${value.embedded_workspace}/${value.tipo}/${value.paginado}`);
                            reload()
                        }
                    }

                    const icone = verificarIcone()

                    function verificarIcone() {
                        if(value.icon && value.icon.includes('mdi mdi')) {
                            return 'AllApplication'
                        } else {
                            return value.icon
                        }
                    };

                    return (
                        <Tooltip title={value.grupo_negocio.split("-")[1]} placement="right" arrow key={key}>
                            <div className='search-bar-result-item' onClick={handleClick}>
                                <div className='search-bar-result-item-icon'>
                                    {React.createElement(Icon[icone], { theme: "outline", size: 16, fill: "#222" })}
                                </div>
                                <div className='search-bar-result-item-name'>
                                    <p>{value.nome}</p>
                                    <p className='search-bar-result-item-name-group'>{value.grupo_negocio && value.grupo_negocio.split("-")[1]}</p>
                                </div>
                            </div>
                        </Tooltip>
                    )
                })
            }
        </div>
    )
}