import React from 'react';
import Swal from 'sweetalert2';
import api from 'services/api/api';
import storage from './storage';

const verificarContratoInadimplente = async () => {
  try {
    const user = storage.get('@tijuca-usuario');

    if (!user || !user.id_empresa) {
      throw new Error('Usuário ou ID da empresa não encontrado');
    }

    const isAdmin = user.adm === 1;
    const idEmpresa = user.id_empresa;

    console.log('isAdmin', isAdmin)

    const formData = new FormData();
    formData.append('id_empresa', idEmpresa);

    const response = await api.post('/contract', formData);


    const { contractActive, contract } = response.data;

    if (!contractActive || !contractActive[0]) {
      console.log('Nenhum contrato ativo encontrado');
    }


    const empresa = contractActive[0] ;
    const contractEnterprise = contract.find(
      (item) => item.id_contrato == empresa.id_contrato
    );

    if (contractEnterprise.inadimplente == 1 && isAdmin) {
      mostrarAlertaContratoAtrasado(true, contractEnterprise);
    }

  } catch (error) {
    console.error('Erro ao verificar contrato:', error.message);
  }
};

const mostrarAlertaContratoAtrasado = (isAdmin, contractEnterprise) => {
  if (isAdmin) {
    Swal.fire({
      title: 'Aviso Importante',
      text: 'Prezado Administrador, nos próximos dias nossa plataforma será bloqueada em vossa empresa. Pedimos contactar o departamento financeiro de sua empresa para evitar maiores transtornos.',
      icon: 'info',
      confirmButtonText: 'Entendi',
    });
  }
};

export default verificarContratoInadimplente;
