import { storage } from 'utils';
import Connection from '../connection';
import './style.css';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export default function ItemMinimize({item, last, group}) {

    let history = useHistory()
    let location = useLocation()
    let isActive = (location.pathname === `/viewReport/${item.embedded_relatorio}/${item.embedded_workspace}/${item.tipo}/${item.paginado}` || location.pathname === item.rota)

    async function activeItemReport() {
            if(item.rota) {
                handleSetHistory(item);
                history.push(`${item.rota}`)
                storage.set('@pathAtual', item.id)                
            } else {
                handleSetHistory(item);
                history.push(`/viewReport/${item.embedded_relatorio}/${item.embedded_workspace}/${item.tipo}/${item.paginado}`)
                storage.set('@pathAtual', item.id)
            }
    }

    function handleSetHistory(item) {
        let history = storage.get('@user_history') || [];
    
        const existingIndex = history.findIndex((historyItem) => historyItem.embedded_relatorio === item.embedded_relatorio);
    
        if (existingIndex !== -1) {
            history[existingIndex].history = new Date();
            history[existingIndex].grupo_negocio = group;
        } else {
            item.history = new Date();
            item.grupo_negocio = group;
            history.unshift(item);
        }
    
        storage.set('@user_history', history);
    }

    return (
        <div 
            className="item-minimize-container"
            onClick={activeItemReport}
        >
            <Connection last={last} />
            <div className='item-minimize-name'>
                <p className={isActive ? 'minimize-name-active' : 'minimize-name'}>{item.nome_exibicao && (item.nome_exibicao != 'null') ? item.nome_exibicao : item.nome}</p>
            </div>
        </div>
    )
}