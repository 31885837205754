import "./style.css"
import Item from "../item";
import { storage } from "utils";
import Connection from "../connection"
import React, { useContext, useState } from "react"
import { AuthContext } from "contexts/appProvider";

export default function SubGroupItem({ item, last, group }) {

    const path = storage.get('@pathAtual')
    const user = storage.get('@tijuca-usuario');
    const [isOpen, setIsOpen] = useState(false);

    const getURL = window.location.host
    const redeSim = ['jucisrs', 'jucea', 'jucerr', 'jucap', 'juceac', 'jucisdf', 'jucems', 'jucec']

    return (
        <div className="sub-group-container">
            <div className="sub-group-item-part" onClick={() => setIsOpen(!isOpen)}>
                {user && (redeSim.includes(storage.get('@codEmpresa')) || getURL.includes("bi-redesim")) ? <span className="space-sim" /> : <span className="space" />}
                <Connection last={last} />
                <div className="report-item-info">
                    <div className="report-item-info-icon">
                        <i className={`mdi mdi-folder-open ${path === item.id ? "group-icon-ative" : "group-icon"}  `} />
                    </div>
                    <div className="report-item-info-name">
                        <p className={path === item.id ? "name-report-active" : "name-report"} title={item[0].nome_sub_grupo}>{item[0].nome_sub_grupo}</p>
                    </div>
                </div>
            </div>
            {isOpen && item.length > 0 &&
                <div className={user && (redeSim.includes(storage.get('@codEmpresa')) || getURL.includes("bi-redesim")) ? !last ? "sub-group-item-items-sim" : "sub-group-item-items-last" : !last ? "sub-group-item-items" : "sub-group-item-items-last"}>
                    {item.length > 0 &&
                            item.map((prop, key) => {
                                if(key === item.length -1) {
                                    return <Item item={prop} key={key} last={true} isSub={true} group={group} />   
                                } else {
                                    return <Item item={prop} key={key} last={false} isSub={true} group={group} />
                                }
                            })
                    }
                </div>
            }
        </div>
    )
}